import * as React from "react";

import Styles from "./HeroSection.module.css";

import heroVideo from "../../assets/videos/hero-video.mp4";
import heroVideoMobile from "../../assets/videos/hero-video-mobile.mp4";

function HeroSection() {
  return (
    <section className={Styles.heroSection}>
      {window.matchMedia("(min-width: 768px)").matches ? (
        <video
          className={Styles.video}
          src={heroVideo}
          autoPlay
          loop
          muted
          playsInline
        />
      ) : (
        <video
          className={Styles.video}
          src={heroVideoMobile}
          autoPlay
          loop
          muted
          playsInline
        />
      )}
    </section>
  );
}

export default HeroSection;
