import { fetchData } from "../utils/API";

const paths = {
  currencies: "/currency-type",
};

export async function getCurrenciesAPI() {
  const response = await fetchData(paths.currencies);

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from:" + paths.currencies);
  }

  return response;
}
