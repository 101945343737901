import ProductBlock from "../../components/ProductBlock";
import Styles from "./ProductsSection.module.css";
import useElementOnScreen from "../../hooks/UseElementOnScreen";
import { useEffect, useState } from "react";
import { Product } from "../../models/product/Product.model";
import { getAllProducts } from "../../services/ProductAPI";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import {
  getProductPreviousPrice,
  getProductPrice,
} from "../../utils/ProductHelpers";

const thumbnailAPI: string = `${process.env.REACT_APP_TRAINPORT_BACKEND_API_URL}/thumbnail/`;

const productOrderByProductType = [
  "INERCISE",
  "COURSE",
  "HANDLE",
  "INERCISE_ACCESSORY",
];

function ProductsSection() {
  const [containerRef, isVisible] = useElementOnScreen();
  const [products, setProducts] = useState<Product[]>([]);
  const { t } = useTranslation();

  const userCurrency = useSelector(
    (state: RootState) => state.currencyState.userCurrency
  );

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.secondElement}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeOpacityAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeft}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeftDelay}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimationDelay}`);
        });
    }
  }, [isVisible, containerRef]);

  useEffect(() => {
    getAllProducts().then((item: Product[]) => {
      const productsSortedByProductType = [...item].sort((a, b) => {
        var A = a.productType.name,
          B = b.productType.name;

        if (
          productOrderByProductType.indexOf(A) >
          productOrderByProductType.indexOf(B)
        ) {
          return 1;
        } else {
          return -1;
        }
      });
      setProducts(productsSortedByProductType);
    });
  }, []);

  return (
    <section className={Styles.container}>
      <hr className={Styles.sectionDivider} />
      <div className={Styles.overlay} />
      <div className={Styles.content} ref={containerRef}>
        <div className={Styles.firstElement}>
          <div className={Styles.titleContainer}>
            <div className={Styles.fadeInLeft}>
              <h2 className={Styles.h2}>
                {t(`homePage.productsSection.TITLE`)}:
              </h2>
            </div>
            <div className={Styles.fadeInLeftDelay}>
              <hr className={Styles.titleDivider} />
            </div>
          </div>
          <div className={Styles.secondElement}>
            {products.slice(0, 3).map((item) => {
              return (
                <ProductBlock
                  key={item.id}
                  id={item.id.toString()}
                  productName={item.name}
                  currentPrice={getProductPrice(item, userCurrency.name)}
                  previousPrice={getProductPreviousPrice(
                    item,
                    userCurrency.name
                  )}
                  thumbnail={thumbnailAPI + item.thumbnail?.name}
                  buttonText={`${t(`productPage.SEE_PRODUCT`)}  ${t(
                    `products.${item.name}.NAME`
                  )}`}
                  currency={userCurrency.name}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
export default ProductsSection;
