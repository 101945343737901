import Styles from "./Home.module.css";

import BlogSection from "./BlogSection";
import BenefitsSection from "./BenefitsSection";
import InerciseSection from "./InerciseSection";
import FeatureSection from "./FeatureSection";
import ProductsSection from "./ProductsSection";
import HeroSection from "./HeroSection";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

function Home() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send(location.pathname + location.search);
  }, [location]);

  return (
    <div className={Styles.container}>
      <HeroSection />
      <FeatureSection />
      <BenefitsSection />
      <InerciseSection />
      <ProductsSection />
      <BlogSection />
    </div>
  );
}

export default Home;
