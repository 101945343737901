import { ChangeEvent } from "react";
import { IShipmentAddress } from "../../models/address/ShipmentAddress.model";
import { useTranslation } from "react-i18next";
import { IPartner } from "../../models/partner/IPartner.model";

import InputForm from "../../components/InputForm";

import Styles from "./ShippingAddressForm.module.css";
import Select from "react-select";
import { ICountry } from "../../models/country/ICountry.model";
import i18n from "../../i18n";
import { countries } from "../../constans/Countries";
import { getCountry, validatePhoneNumber } from "../../utils/AddressHelpers";

interface IShippingAddressFormProps {
  defaultInput: IShipmentAddress | undefined;
  onInputChnage: (arg: { key: string; value: string }) => void;
  errorMessage: String | null | undefined;
  partners: IPartner[];
  onSelectPartner: (arg: IPartner) => void;
  onSelectCountry: (arg: ICountry | undefined) => void;
  onChangeAreaCode: (arg: string | undefined) => void;
}

interface IDropdown {
  label: string;
  value: string;
}

interface IDropdownCountry {
  label: string;
  value: ICountry;
}

interface IDropdownPartner {
  label: string;
  value: IPartner;
}

const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    border: "solid var(--main-color) 2px",
    borderRadius: "2rem",
    fontSize: "1.2rem",
    color: "black",
    fontFamily: "Montserrat",
    cursor: "pointer",
  }),
  menu: (styles: any) => ({
    ...styles,
    border: "solid var(--main-color) 2px",
    borderRadius: "2rem",
    width: "max-content",
    minWidth: "100%",
  }),
  menuList: (styles: any) => ({
    ...styles,
    "::-webkit-scrollbar": {
      width: "1rem",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
      marginTop: "1.2rem",
      marginBottom: "1.2rem",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "none",
    color: "black",
    fontSize: "1.2rem",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "black",
    fontSize: "1.2rem",
  }),
  input: (styles: any) => ({
    ...styles,
    color: "black",
    fontSize: "1.5rem",
  }),
};

function ShippingAddressForm(props: IShippingAddressFormProps) {
  const { t } = useTranslation();

  const partnersNames = () => {
    if (props.partners === undefined) return;

    let partnersNames: IDropdownPartner[] = props.partners?.map((partner) => {
      const newPartner: IDropdownPartner = {
        label: partner.name?.toString(),
        value: partner,
      };
      return newPartner;
    });
    return partnersNames;
  };

  const countiresNames = () => {
    let countiresNames: IDropdownCountry[] = countries.map((country) => {
      if (i18n.language === "pl") {
        return {
          label: country.polishName.toString(),
          value: country,
        };
      } else {
        return {
          label: country.countryName.toString(),
          value: country,
        };
      }
    });

    return countiresNames;
  };

  const setDefaultCountry = () => {
    if (
      props.defaultInput?.country === undefined ||
      props.defaultInput?.country === ""
    )
      return;

    const userCountryLanguagePL: ICountry[] = countries.filter(
      (country) =>
        country.polishName.toUpperCase() ===
        props.defaultInput?.country.toUpperCase()
    );

    const userCountryLanguageEUR: ICountry[] = countries.filter(
      (country) =>
        country.countryName.toUpperCase() ===
        props.defaultInput?.country.toUpperCase()
    );

    let defaultCountry: ICountry;

    if (userCountryLanguageEUR.length > 0) {
      defaultCountry = userCountryLanguageEUR[0];
    } else {
      defaultCountry = userCountryLanguagePL[0];
    }

    let defaultDropdown: IDropdownCountry;
    if (i18n.language === "pl") {
      defaultDropdown = {
        label: defaultCountry?.polishName.toString(),
        value: defaultCountry,
      };
    } else {
      defaultDropdown = {
        label: defaultCountry?.countryName.toString(),
        value: defaultCountry,
      };
    }
    return defaultDropdown;
  };

  const diallingNumbers = () => {
    if (props.defaultInput?.areaCode === undefined) return;

    let diallingNumbers: IDropdown[] = countries.map((country) => {
      return {
        label:
          country.diallingCode.toString() +
          " " +
          country.abberivation.toString(),
        value: country.diallingCode.toString(),
      };
    });

    return diallingNumbers;
  };

  const setDefaultDiallingNumber = (): IDropdown | undefined => {
    if (
      props.defaultInput?.areaCode === undefined ||
      props.defaultInput.country === ""
    )
      return;

    const country: ICountry | null = getCountry(props.defaultInput?.country);

    return {
      label:
        props?.defaultInput.areaCode.toString() +
        " " +
        country?.abberivation.toString(),
      value: props.defaultInput.areaCode.toString(),
    };
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    return props.onInputChnage({
      key: e.currentTarget.name,
      value: e.currentTarget.value,
    });
  };

  const handleOnChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if ((e.currentTarget.id = "phoneNumber")) {
      const isPhoneNumberValid: boolean = validatePhoneNumber(
        String(e.currentTarget.value)
      );
      if (!isPhoneNumberValid) {
        e.currentTarget.value = e.currentTarget.defaultValue;
        e.preventDefault();
      } else {
        props.onInputChnage({
          key: e.currentTarget.name,
          value: e.currentTarget.value,
        });
      }
    }
  };

  return (
    <form className={Styles.form}>
      <h3 className={Styles.subtitle}>Adres dostawy</h3>
      <div className={Styles.groupInputs}>
        <InputForm
          id="name"
          type="text"
          value={props.defaultInput?.name?.toString()}
          name="name"
          label={t(`deliveryAndPaymentAndPage.NAME`)}
          onChange={handleOnChange}
        />
        <InputForm
          id="surname"
          type="text"
          value={props.defaultInput?.surname?.toString()}
          name="surname"
          label={t(`deliveryAndPaymentAndPage.SURNAME`)}
          onChange={handleOnChange}
        />
      </div>
      <div className={Styles.groupInputs}>
        <div className={Styles.countryInputContainer}>
          <label className={Styles.label} htmlFor={"countries"}>
            {t(`deliveryAndPaymentAndPage.COUNTRY`)}
          </label>
          <Select
            styles={selectStyles}
            placeholder={t(`deliveryAndPaymentAndPage.SELECT_OPTION`)}
            options={countiresNames()}
            value={setDefaultCountry()}
            onChange={(choice) => {
              props.onSelectCountry(choice?.value);
            }}
          />
        </div>
        <InputForm
          id="state"
          type="text"
          value={props.defaultInput?.state?.toString()}
          name="state"
          label={t(`deliveryAndPaymentAndPage.VOIVODEOSHIP`)}
          onChange={handleOnChange}
        />
      </div>
      <div className={Styles.groupInputs}>
        <InputForm
          id="city"
          type="text"
          value={props.defaultInput?.city?.toString()}
          name="city"
          label={t(`deliveryAndPaymentAndPage.CITY`)}
          onChange={handleOnChange}
        />
        <InputForm
          id="postcode"
          className={Styles.postalCode}
          type="text"
          value={props.defaultInput?.postcode?.toString()}
          name="postcode"
          label={t(`deliveryAndPaymentAndPage.POST_CODE`)}
          onChange={handleOnChange}
        />
        <div className={Styles.phoneNumberContainer}>
          <label className={Styles.label}>
            {t(`deliveryAndPaymentAndPage.PHONE_NUMBER`)}
          </label>
          <div className={Styles.phoneNumber}>
            <Select
              styles={selectStyles}
              placeholder={""}
              value={setDefaultDiallingNumber()}
              options={diallingNumbers()}
              onChange={(choice) => {
                props.onChangeAreaCode(choice?.value);
              }}
            />
            <InputForm
              id="phoneNumber"
              type="text"
              value={props.defaultInput?.phoneNumber?.toString()}
              name="phoneNumber"
              onChange={handleOnChangePhoneNumber}
            />
          </div>
        </div>
      </div>
      <InputForm
        id="addressLine_1"
        type="text"
        value={props.defaultInput?.addressLine_1?.toString()}
        name="addressLine_1"
        label={t(`deliveryAndPaymentAndPage.ADDRESS_1`)}
        onChange={handleOnChange}
      />
      <InputForm
        id="addressLine_2"
        type="text"
        value={props.defaultInput?.addressLine_2?.toString()}
        name="addressLine_2"
        label={t(`deliveryAndPaymentAndPage.ADDRESS_2`)}
        onChange={handleOnChange}
      />
      <label className={Styles.label} htmlFor={"partners"}>
        {t(`deliveryAndPaymentAndPage.FEEDBACK`)}
      </label>
      <Select
        styles={selectStyles}
        options={partnersNames()}
        placeholder={t(`deliveryAndPaymentAndPage.SELECT_OPTION`)}
        onChange={(choice) => {
          props.onSelectPartner({
            id: Number(choice?.value),
            name: String(choice?.label),
          });
        }}
      />

      {props.errorMessage && (
        <label className={Styles.error}>{props.errorMessage}</label>
      )}
    </form>
  );
}

export default ShippingAddressForm;
