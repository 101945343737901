import Styles from "./Shop.module.css";

import CrossFadingSection from "../../components/CrossFadingSection";
import ProductsSection from "./ProductsSection";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

function Shop() {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      <motion.div
        className={Styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
        exit={{ opacity: 0 }}
      >
        <CrossFadingSection
          title={t(`shopPage.SHOP`)}
          subtitle="Let's TEAM together!"
        />
        <ProductsSection />
      </motion.div>
    </AnimatePresence>
  );
}

export default Shop;
