import FeatureBlock from "./FeatureBlock";

import featureBlocksItem from "../../constans/FeatureBlocksItems";

import Styles from "./FeatureSection.module.css";

import { useEffect } from "react";
import useElementOnScreen from "../../hooks/UseElementOnScreen";
import { useTranslation } from "react-i18next";

function FeatureSection() {
  const [containerRef, isVisible] = useElementOnScreen();
  const { t } = useTranslation();

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeLeft}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeLeftAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInRight}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInRightAnimation}`);
        });
    }
  }, [isVisible, containerRef]);

  return (
    <section className={Styles.container}>
      <hr className={Styles.sectionDivider} />
      <div className={Styles.overlay} />
      <div className={Styles.content} ref={containerRef}>
        <div className={Styles.firstElement} />
        <div className={`${Styles.secondElement} ${Styles.fadeLeft}`}>
          <h3 className={Styles.companyName}>Trainport</h3>
          <h2 className={Styles.companyMotto}>Let’s team together!</h2>
          <hr className={Styles.titlesDivider} />
          <div className={Styles.fadeInRight}>
            <p className={Styles.desc}>{t(`homePage.featureSection.DESC`)}</p>
          </div>
          <div className={Styles.fadeLeft}>
            <hr className={Styles.endParagprahDivider} />
          </div>
          <div className={Styles.featureBlocksContainer}>
            {featureBlocksItem.map((item) => {
              return (
                <FeatureBlock
                  image={item.image}
                  title={t(
                    `homePage.featureSection.featureBlock.${item.titleIdentifier}`
                  )}
                  text={t(
                    `homePage.featureSection.featureBlock.${item.textIdentifier}`
                  )}
                  backgroundImage={item.imageOnHover}
                  key={item.id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
export default FeatureSection;
