import { useState } from "react";
import Styles from "./Button.module.css";
import React from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
  text: string;
  className?: String;
  linkUrl: string;
  isCheckable?: boolean;
  onClick?: (arg: any) => void;
  disabled?: boolean;
}

function Button(props: ButtonProps) {
  const [isChecked, setIsChecked] = useState(false);

  const handleOnClick = () => {
    setIsChecked(!isChecked);
  };

  const button = (
    <Link
      to={props.linkUrl}
      className={`${props.disabled ? Styles.buttonDisabled : Styles.button} ${
        props.className
      }`}
      onClick={props.onClick}
      state={{ prevUrl: window.location.pathname }}
    >
      {props.text}
    </Link>
  );

  const buttonCheckable = (
    <div
      className={`${Styles.button} ${props.className} ${
        isChecked ? Styles.buttonChecked : ""
      }`}
      onClick={handleOnClick}
    >
      {props.text}
    </div>
  );

  return (
    <React.Fragment>
      {props.isCheckable ? buttonCheckable : button}
    </React.Fragment>
  );
}

export default Button;
