import RegisterUserForm from "../../features/user/components/RegisterUserForm";
import { useState } from "react";
import UserInformationSection from "../../components/UserInformationSection";
import Styles from "./RegisterUser.module.css";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

function RegisterUser() {
  const { t } = useTranslation();
  const [isUserRegisterSuccessfully, setIsUserRegisterSuccessfully] =
    useState(false);

  const updateIsUserRegister = (dataFromChild: boolean) => {
    setIsUserRegisterSuccessfully(dataFromChild);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      {isUserRegisterSuccessfully ? (
        <div>
          <UserInformationSection
            title={t(`registerUserPage.SUCCESSFUL_REGISTRATION_MESSAGE`)}
            info={t(`registerUserPage.ACTIVATION_LINK_SEND_TO_MAIL`)}
            link="/"
          />
        </div>
      ) : (
        <div className={Styles.container}>
          <RegisterUserForm updateIsUserRegister={updateIsUserRegister} />
        </div>
      )}
    </motion.div>
  );
}

export default RegisterUser;
