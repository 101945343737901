export const socialMediaItems = [
  {
    id: 1,
    title: "Instagram",
    icon: "instagram-circle-black.svg",
    url: "https://www.instagram.com/trainport_team/",
  },
  {
    id: 2,
    title: "Facebook",
    icon: "facebook-circle-black.svg",
    url: "https://www.facebook.com/trainport.team",
  },
];

export default socialMediaItems;
