import InerciseFeatureSection from "./InerciseFeatureSection";
import InerciseAdvantagesSection from "./InerciseAdvantagesSection";
import { motion } from "framer-motion";

function Inercise() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <InerciseFeatureSection />
      <InerciseAdvantagesSection />
    </motion.div>
  );
}

export default Inercise;
