import { useEffect } from "react";

import Button from "../../components/Button";

import Handler from "../../assets/images/home_inercise_section_handler.png";
import InerciseSet from "../../assets/images/home_inercise_section_inercise_set.png";
import App from "../../assets/images/home_inercise_section_app.png";
import Inercise from "../../assets/images/home_inercise_section_inercise.png";

import Styles from "./InerciseSection.module.css";

import useElementOnScreen from "../../hooks/UseElementOnScreen";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import { Currency } from "../../models/cart/Currency.model";

function InerciseSection() {
  const [containerRef, isVisible] = useElementOnScreen();
  const { t } = useTranslation();

  const userCurrency = useSelector(
    (state: RootState) => state.currencyState.userCurrency
  );

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.secondElement}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeUpAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeCenterLeft}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeFromCenterLeftAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`.${Styles.fadeCenterRight}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeCenterRightAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeFromCenterLeftDelay}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeFromCenterLeftDelayAnimation}`);
        });
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeUpTitle}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeUpAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeOpacity}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeOpacityAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeft}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimation}`);
        });
    }
  }, [isVisible, containerRef]);

  return (
    <section className={Styles.container}>
      <hr className={Styles.sectionDivider} />
      <div className={Styles.overlay} />
      <div className={Styles.content} ref={containerRef}>
        <div className={Styles.firstElement}>
          <div className={Styles.titleContainer}>
            <div className={Styles.fadeOpacity}>
              <h3 className={Styles.subtitle}>
                {t(`homePage.inerciseSection.TITLE`)}
              </h3>
            </div>
            <div className={Styles.fadeUpTitle}>
              <h2 className={Styles.title}>Inercise</h2>
            </div>
            <div className={Styles.fadeInLeft}>
              <hr className={Styles.titleDivider} />
            </div>
          </div>
          <div className={Styles.fadeCenterLefta}>
            <Link to={`/shop`}>
              <img className={Styles.handler} src={Handler} alt="Handler" />
            </Link>
          </div>
          <div className={Styles.fadeFromCenterLeftDelay}>
            <Link to={`/product?id=1`}>
              <img
                className={Styles.inerciseSet}
                src={InerciseSet}
                alt="Inercise Set"
              />
            </Link>
          </div>
        </div>
        <div className={`${Styles.secondElement} ${Styles.fadeUpAnimation}`}>
          <img className={Styles.inercise} src={Inercise} alt="Inercise" />
        </div>
        <div className={Styles.thirdElement}>
          <div className={Styles.fadeCenterRight}>
            <Link to={`/application`}>
              <img className={Styles.app} src={App} alt="App" />
            </Link>
          </div>
          <div className={Styles.orderContainer}>
            <div className={Styles.fadeUpTitle}>
              <h2 className={Styles.newPrice}>
                {userCurrency?.name === Currency.PLN ? "8499" : "1999"}{" "}
                {userCurrency?.name}
              </h2>
            </div>
            <div className={Styles.fadeOpacity}>
              <Button
                text={t(`homePage.inerciseSection.BUTTON_TEXT`)}
                linkUrl="/product?id=1"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default InerciseSection;
