import FeatureSection from "./FeatureSection";
import ApplicationSection from "./InstructionSection";
import Styles from "./Application.module.css";
import { motion } from "framer-motion";

function Application() {
  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <FeatureSection />
      <ApplicationSection />
    </motion.div>
  );
}

export default Application;
