import { useEffect, useState } from "react";
import LoginForm from "../../features/user/components/LoginForm";
import ResetPassword from "../../features/user/components/ResetPassword";
import { getUserCartAPI, mergeCartAPI } from "../../services/CartAPI";
import { useDispatch, useSelector } from "react-redux";
import { setUserCart } from "../../features/cart/reducers/CartSlice";
import { RootState } from "../../app/Store";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./Login.module.css";
import { motion } from "framer-motion";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isResetPasswordRequested, setIsRestPasswordRequested] =
    useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const location = useLocation();
  const isResetPasswordRequestedCallback = (dataFromChild: boolean) => {
    setIsRestPasswordRequested(dataFromChild);
  };
  const isUserAuthenticatedCallback = (dataFromChild: boolean) => {
    setIsUserAuthenticated(dataFromChild);
  };

  const userToken = useSelector(
    (state: RootState) => state.userState.accessToken
  );

  const userCart = useSelector((state: RootState) => state.cartState.userCart);

  useEffect(() => {
    if (isUserAuthenticated && userCart.id === null) {
      if (userCart.cartItems.length <= 0) {
        getUserCartAPI(userToken).then((cart) => {
          dispatch(setUserCart(cart));
          const prevURL = String(location.state?.prevUrl);
          if (prevURL.indexOf("/activate-account") > -1) {
            navigate("/");
          } else {
            navigate(-1);
          }
        });
      } else {
        mergeCartAPI(userToken, userCart.cartItems).then((mergedCart) => {
          dispatch(setUserCart(mergedCart));
          const prevURL = String(location.state?.prevUrl);
          if (prevURL.indexOf("/activate-account") > -1) {
            navigate("/");
          } else {
            navigate(-1);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [isUserAuthenticated]);

  return (
    <div>
      {isResetPasswordRequested ? (
        <ResetPassword />
      ) : (
        <motion.div
          className={Styles.loginContainer}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.25 }}
        >
          <LoginForm
            isResetPasswordRequested={isResetPasswordRequestedCallback}
            isUserAuthenticated={isUserAuthenticatedCallback}
          />
        </motion.div>
      )}
    </div>
  );
}

export default Login;
