import { Device } from "../models/device/Device.model";
import { DeviceManual } from "../models/device/DeviceManual.model";
import { DeviceType } from "../models/device/DeviceType.model";
import { fetchData, fetchPrivateData } from "../utils/API";

const paths = {
  device: "/device",
  register: "/device/register",
  unregister: "/device/unregister",
  deviceManual: "/device-manual",
  deviceManualById: "/device-manual/device-type-id",
  deviceManualBySerialNumber: "/device/manual",
};

export async function getDevices(token: string) {
  const response = await fetchPrivateData(paths.device, token);

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from: " + paths.device);
  }

  const data: Device[] = await response?.json();
  return data;
}

export async function registerDevice(token: string, serialNumber: string) {
  const response = await fetchPrivateData(
    paths.register + "/" + serialNumber,
    token,
    { method: "PATCH" }
  );

  if (response === undefined || !response.ok) {
    throw new Error(
      "Couldn't fetch data from: " + paths.register + "/" + serialNumber
    );
  }

  const data: Device = await response?.json();
  return data;
}

export async function unregisterDevice(token: string, id: string) {
  const response = await fetchPrivateData(paths.unregister + "/" + id, token, {
    method: "PATCH",
  });

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from: " + paths.unregister + "/" + id);
  }

  return;
}

export async function getDeviceManualFilename(deviceType: DeviceType) {
  const response = await fetchData(
    paths.deviceManualById + "/" + deviceType.id,
    {
      method: "GET",
    }
  );

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from: " + paths.deviceManual);
  }

  const data: DeviceManual = await response?.json();

  return data?.filename;
}

export async function getDeviceManualBySerialNumber(serialNumber: String) {
  const response = await fetchData(
    paths.deviceManualBySerialNumber + "/" + serialNumber,
    {
      method: "GET",
    }
  );

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from: " + paths.deviceManual);
  }

  const data = await response?.blob();

  return data;
}
