import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/Store";
import { User, defaultUser } from "../../../models/user/User.model";

interface AuthState {
  user: User;
  email: string | null;
  accessToken: string | null;
}

const authInitialState: AuthState = {
  user: defaultUser,
  email: null,
  accessToken: null,
};

export const authStateSlice = createSlice({
  name: "authState",
  initialState: authInitialState,
  reducers: {
    login(state: AuthState, action) {
      const { email, accessToken } = action.payload;
      state.accessToken = accessToken;
      state.email = email;
    },
    logout(state) {
      state.email = null;
      state.accessToken = null;
    },
    setUser(state: AuthState, action) {
      const user: User = action.payload;
      state.user = user;
    },
  },
});

export const { login, logout, setUser } = authStateSlice.actions;
export const selectUser = (state: RootState) => [
  state.userState.email,
  state.userState.accessToken,
  state.userState.user,
];
export default authStateSlice.reducer;
