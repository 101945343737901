import { fetchPrivateData } from "../utils/API";

const paths = {
  getPartners: "/partner/list",
};

export async function getPartnersAPI(userAccessToken: string | null) {
  const response = await fetchPrivateData(paths.getPartners, userAccessToken, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userAccessToken,
    },
  });
  return response;
}
