import { useTranslation } from "react-i18next";
import { ICart } from "../../models/cart/Cart.model";
import { isMobile } from "../../utils/ScreenHelper";

import Button from "../../components/Button";

import Styles from "./OrderValueSummary.module.css";
import { Currency, ICurrency } from "../../models/cart/Currency.model";
import React, { ReactElement } from "react";
import { IShipmentAddress } from "../../models/address/ShipmentAddress.model";
import { RegionEnum } from "../../models/address/Region.model";

interface OrderValueSummaryProps {
  userCart: ICart;
  errorShippingAddressMessage: String | null | undefined;
  errorPaymentMessage: String | null | undefined;
  errorInvoiceAddressMessage: String | null | undefined;
  handleGoToPayment: () => void;
  userCurrency: ICurrency;
  shippmentAddress: IShipmentAddress | undefined;
}

function OrderValueSummary(props: OrderValueSummaryProps) {
  const { t } = useTranslation();

  const renderCartValue = (): ReactElement => {
    let cartPrice: Number | null;
    if (props.userCurrency.name === Currency.PLN)
      if (props.userCart.discountedPrice !== null) {
        cartPrice =
          Number(props.userCart.discountedPrice) +
          Number(props.userCart.shipmentPrice);
      } else {
        cartPrice =
          Number(props.userCart.originalPrice) +
          Number(props.userCart.shipmentPrice);
      }
    else {
      if (props.userCart.discountedPriceEur !== null) {
        cartPrice =
          Number(props.userCart.discountedPriceEur) +
          Number(props.userCart.shipmentPriceEur);
      } else {
        cartPrice =
          Number(props.userCart.originalPriceEur) +
          Number(props.userCart.shipmentPriceEur);
      }
    }

    return (
      <React.Fragment>
        <h3 className={Styles.cartTag}>
          {t(`deliveryAndPaymentAndPage.PAYMENT_TOTAL`)}
        </h3>
        <h2 className={Styles.cartValue}>
          {String(cartPrice)} {props.userCurrency.name}
        </h2>
      </React.Fragment>
    );
  };

  const renderAcceptTerms = () => {
    return (
      <p className={Styles.infoText}>
        {t(`deliveryAndPaymentAndPage.ACCEPT_TERMS_INFO`)}{" "}
        <a href="/terms-of-sale">
          {t(`deliveryAndPaymentAndPage.TERMS_OF_SALE`)}
        </a>{" "}
        {t(`deliveryAndPaymentAndPage.OR`)}
        <a href="/privacy-policy">
          {" "}
          {t(`deliveryAndPaymentAndPage.PRIVACY_POLICY`)}
        </a>
      </p>
    );
  };
  const renderCartValueBeforeDiscount = (): ReactElement => {
    return (
      <React.Fragment>
        <p className={Styles.deliveryValue}>
          {t(`deliveryAndPaymentAndPage.CART_VALUE`)}
        </p>
        <p className={Styles.deliveryValue}>
          {props.userCurrency.name === Currency.PLN
            ? props.userCart.originalPrice
            : props.userCart.originalPriceEur}{" "}
          {props.userCurrency.name}
        </p>
      </React.Fragment>
    );
  };

  const renderGoToPayment = () => {
    return (
      <React.Fragment>
        <Button
          text={t(`deliveryAndPaymentAndPage.BUTTON_PAY`)}
          onClick={props.handleGoToPayment}
          linkUrl=""
          className={Styles.button}
        />
        {props.errorPaymentMessage && (
          <label className={Styles.error}>{props.errorPaymentMessage}</label>
        )}
        {isMobile() && props.errorShippingAddressMessage && (
          <label className={Styles.error}>
            {props.errorShippingAddressMessage}
          </label>
        )}
        {isMobile() && props.errorInvoiceAddressMessage && (
          <label className={Styles.error}>
            {props.errorInvoiceAddressMessage}
          </label>
        )}
        {renderAcceptTerms()}
      </React.Fragment>
    );
  };

  const renderSendInquiry = () => {
    return (
      <React.Fragment>
        <p className={`${Styles.infoText} ${Styles.infoAboutPaymentInEuro}`}>
          {t(`deliveryAndPaymentAndPage.INFO_ABOUT_PAYMENT_IN_EURO`)}
        </p>
        <Button
          text={t(`deliveryAndPaymentAndPage.BUTTON_PLACE_ORDER`)}
          onClick={props.handleGoToPayment}
          linkUrl=""
          className={Styles.buttonEur}
        />
        {renderAcceptTerms()}
      </React.Fragment>
    );
  };

  const renderDeliveryValue = () => {
    let deliveryCost: String | undefined;
    if (
      props.shippmentAddress === undefined ||
      props.shippmentAddress?.country === ""
    ) {
      deliveryCost = t(`deliveryAndPaymentAndPage.SELECT_COUTRY`);
    } else if (
      props.userCurrency.name === Currency.PLN &&
      props.shippmentAddress?.region !== RegionEnum.OTHER
    ) {
      deliveryCost =
        props.userCart.shipmentPrice?.toString() + " " + Currency.PLN;
    } else if (
      props.userCurrency.name === Currency.EUR &&
      props.shippmentAddress?.region !== RegionEnum.OTHER
    ) {
      deliveryCost =
        props.userCart.shipmentPriceEur?.toString() + " " + Currency.EUR;
    } else {
      deliveryCost = t(`deliveryAndPaymentAndPage.SHIPMENT_NOT_AVAIABLE`);
    }

    return <p className={Styles.deliveryValue}> {deliveryCost}</p>;
  };

  return (
    <div className={Styles.paymentContainer}>
      <div className={Styles.valueContainer}>{renderCartValue()}</div>
      <div className={Styles.valueContainer}>
        {renderCartValueBeforeDiscount()}
      </div>
      <div className={Styles.valueContainer}>
        <h3 className={Styles.deliveryTag}>
          {t(`deliveryAndPaymentAndPage.DELIVERY_PRICE`)}
        </h3>
        {renderDeliveryValue()}
      </div>
      <hr />
      {props.shippmentAddress?.region !== "" &&
      props.shippmentAddress?.region !== RegionEnum.OTHER &&
      props.userCurrency.name === Currency.PLN
        ? renderGoToPayment()
        : renderSendInquiry()}
    </div>
  );
}

export default OrderValueSummary;
