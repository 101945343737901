import Styles from "./ExcerciseAtlas.module.css";

import CrossFadingSection from "./CrossFadingSection";
import ExcerciseSection from "./ExcerciseSection";

import excercises from "../../constans/Excercises";
import ApplicationSection from "./ApplicationSection";
import { motion } from "framer-motion";

function ExcerciseAtlas() {
  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <CrossFadingSection
        title="Atlas ćwiczeń"
        subtitle="Let's TEAM together!"
      />
      {excercises.map((item, index) => {
        return (
          <ExcerciseSection
            key={index}
            id={item.id}
            title={item.name}
            musclesDesc={item.muscle}
            steps={item.steps}
            videoName={item.videoName}
          ></ExcerciseSection>
        );
      })}
      <ApplicationSection />
    </motion.div>
  );
}

export default ExcerciseAtlas;
