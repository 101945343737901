export const featureBlocksItem = [
  {
    id: 1,
    titleIdentifier: "TITLE_INERTIAL_TRAINING",
    textIdentifier: "TEXT_INERTIAL_TRAINING",
    image: "home_intro_section_disk.png",
    imageOnHover: "home_intro_section_disk_hover.jpg",
  },
  {
    id: 2,
    titleIdentifier: "TITLE_STRENGHT_POWER_SPEED",
    textIdentifier: "TEXT_STRENGHT_POWER_SPEED",
    image: "home_intro_section_biceps.png",
    imageOnHover: "home_intro_section_biceps_hover.jpg",
  },
  {
    id: 3,
    titleIdentifier: "TITLE_SPECTRUM_OF_POSSIBLITIES",
    textIdentifier: "TEXT_SPECTRUM_OF_POSSIBLITIES",
    image: "home_intro_section_training.png",
    imageOnHover: "marcin_packing_inercise_blue.jpg",
  },
  {
    id: 4,
    titleIdentifier: "TITLE_TRAINPORT_DOCK",
    textIdentifier: "TEXT_TRAINPORT_DOCK",
    image: "home_intro_section_mobile.png",
    imageOnHover: "app_blue.jpg",
  },
];

export default featureBlocksItem;
