import i18next from "../i18n";
import i18n from "../i18n";
import { Article } from "../models/blog/Article.model";
import { TextTranslation } from "../models/blog/TextTranslation.model";

export function addArticalesTranslations(articles: Article[]) {
  // eslint-disable-next-line
  articles.map((article) => {
    if (!i18n.exists(`title.blog_${article.id}`)) {
      i18next.addResourceBundle("en", `blog_${article.id}`, {
        title: `${article.titleDict.en}`,
      });
      i18next.addResourceBundle("pl", `blog_${article.id}`, {
        title: `${article.titleDict.en}`,
      });
    }

    // if (!i18n.exists(`body.blog_${article.id}`)) {
    //   i18next.addResourceBundle("en", `blog_${article.id}`, {
    //     body: `${article.bodyDict?.en}`,
    //   });
    //   i18next.addResourceBundle("pl", `blog_${article.id}`, {
    //     body: `${article.bodyDict?.get("pl")}`,
    //   });
    // }
  });
}

export function getTextInCurrentLanguage(text: TextTranslation): String {
  console.log(text);
  const language = i18n.language;
  console.log(language);
  if (language === "pl") {
    return text.pl;
  } else {
    return text.en;
  }
}
