import { ArticleCategory } from "../models/blog/ArticleCategory.model";
import { Article } from "../models/blog/Article.model";
import { fetchData } from "../utils/API";

const paths = {
  publishedArticles: "/articles/published",
  articleCategoryTypes: "/article-category",
  addToFavourite: "/add-to-favourite",
  removeFromFavourite: "/remove-from-favourite",
  articleById: "/articles/",
  addComment: "/add-comment",
};

const filters = {
  sortByDate: "?sortBy=datePublished",
  sortDir: "&sortDir=",
  ascending: "ASC",
  descending: "DESC",
};

export async function getAllPublishedArticles() {
  const response = await fetchData(paths.publishedArticles);
  const json = await response?.json();
  const data: Article[] = await json.content;

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from:" + paths.publishedArticles);
  }

  return data;
}

export async function getAllPublishedArticlesByDate(dateDecending: boolean) {
  const response = await fetchData(
    paths.publishedArticles +
      filters.sortByDate +
      filters.sortDir +
      (dateDecending ? filters.descending : filters.ascending)
  );
  const json = await response?.json();
  const data: Article[] = await json.content;

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from:" + paths.publishedArticles);
  }

  return data;
}

export async function getArticleCategoryTypes() {
  const response = await fetchData(paths.articleCategoryTypes);

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from: " + paths.articleCategoryTypes);
  }

  const data: ArticleCategory[] = await response.json();
  return data;
}

export async function getArticleById(articleId: string | null) {
  const response = await fetchData(paths.articleById + articleId);
  const data = await response?.json();

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from:" + paths.articleById);
  }

  return data;
}

export async function addCommentToArticle(
  articleId: string | null,
  token: string | null,
  comment: string
) {
  const response = await fetchData(
    paths.articleById + articleId + paths.addComment,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ comment: comment }),
    }
  );
  const data = await response?.json();

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't add a comment:" + paths.articleById);
  }

  return data;
}

export async function addAritcleToFavourite(
  articleId: string | null,
  token: string | null
) {
  const response = await fetchData(
    paths.articleById + articleId + paths.addToFavourite,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't add to favourite:" + paths.articleById);
  }
}

export async function removeAritcleFromFavourite(
  articleId: string | null,
  token: string | null
) {
  const response = await fetchData(
    paths.articleById + articleId + paths.removeFromFavourite,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't remove from favourite:" + paths.articleById);
  }
}
