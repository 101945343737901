import { ICartItem } from "../models/cart/CartItem.model";
import { useTranslation } from "react-i18next";
import { Key, ReactElement } from "react";

import Styles from "./CartItemProperties.module.css";
import { Currency, ICurrency } from "../models/cart/Currency.model";

const thumbnailAPI: string = `${process.env.REACT_APP_TRAINPORT_BACKEND_API_URL}/thumbnail/`;

interface CartItemPropertiesProps {
  cartItem: ICartItem;
  unitPriceStyle?: String;
  productNameStyle?: String;
  propertyStyle?: String;
  userCurrency: ICurrency;
}

function CartItemProperties(props: CartItemPropertiesProps) {
  const { t } = useTranslation();

  const renderProperty = (
    key: String,
    value: String,
    index: Key | null | undefined
  ): ReactElement => {
    return (
      <p key={index} className={`${Styles.property} ${props.propertyStyle}`}>
        {t(`cartItem.${key}`)} {t(`cartItem.${value}`)}
      </p>
    );
  };

  return (
    <div className={Styles.container}>
      <img
        className={Styles.productThumbnail}
        src={thumbnailAPI + props.cartItem.product.thumbnail.name}
        alt={props.cartItem.product.name}
      />
      <div className={Styles.productDescContainer}>
        <div className={Styles.properties}>
          <h4 className={`${Styles.productName} ${props.productNameStyle}`}>
            {t(`products.${props.cartItem.product.name}.NAME`)}
          </h4>
          {props.cartItem.selectedProperties.map((item, index) => {
            return renderProperty(item.propertyType.name, item.value, index);
          })}
        </div>
        <div>
          <p className={`${Styles.unitPrice} ${props.unitPriceStyle}`}>
            {t(`cartInfo.UNIT_PRICE`)}{" "}
            {props.userCurrency.name === Currency.PLN
              ? props.cartItem.product.currentPrice
              : props.cartItem.product.currentPriceEur}{" "}
            {props.userCurrency.name}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CartItemProperties;
