import { useEffect } from "react";
import First from "../../assets/images/blog_crossfading_section_first.jpg";
import Second from "../../assets/images/marta_leg_push_in_bear_postion_color.jpg";

import Styles from "./CrossFadingSection.module.css";
import useElementOnScreen from "../../hooks/UseElementOnScreen";
import { useTranslation } from "react-i18next";

interface CrossFadingProps {
  subtitle: String;
  title: String;
}

function CrossFading({ subtitle, title }: CrossFadingProps) {
  const [containerRef, isVisible] = useElementOnScreen();
  const { t } = useTranslation();

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeft}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeftDelay}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimationDelay}`);
        });
    }
  }, [isVisible, containerRef]);

  return (
    <div className={Styles.container} ref={containerRef}>
      <img
        className={`${Styles.crossFadingImg} ${Styles.firstImageDelay}`}
        src={First}
        alt="Rowing"
      />
      <img
        className={`${Styles.crossFadingImg} ${Styles.secondImageDelay}`}
        src={Second}
        alt="Leg press"
      />
      <div className={Styles.overlay}> </div>
      <hr className={Styles.firstDivider} />
      <div className={`${Styles.headerContainer}`}>
        <div className={Styles.textContainer}>
          <h2 className={Styles.motto}>Let's TEAM Together!</h2>
          <h1 className={Styles.pageTitle}>
            {t(`excerciseAtlasPage.crossFadingSection.TITLE`)}
          </h1>
          <p className={Styles.desc}>
            {t(`excerciseAtlasPage.crossFadingSection.DESC`)}
          </p>
        </div>
        <div className={Styles.counterContainer}>
          <p className={Styles.counter}>40+</p>
          <p className={Styles.desc}>
            {t(`excerciseAtlasPage.crossFadingSection.INFO`)}
          </p>
        </div>
        <hr className={Styles.secondDivider} />
      </div>
      <hr className={Styles.thirdDivider} />
    </div>
  );
}

export default CrossFading;
