import Styles from "./AccountActivation.module.css";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { activateAccount } from "../../services/UserApi";
import Button from "../../components/Button";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

function AccountActivation() {
  const [searchparams] = useSearchParams();
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const activationAccountToken = searchparams.get("token");
    if (activationAccountToken !== null) {
      const responseStatus = activateAccount(activationAccountToken);
      responseStatus.then((status) => {
        if (status === undefined) {
          setMessage(t(`accountActivationPage.SOMETHING_WENT_WRONG`));
          return;
        } else if (status >= 200 && status <= 299) {
          setMessage(t(`accountActivationPage.ACCOUNT_ACTIVATION`));
          return;
        } else {
          setMessage(t(`accountActivationPage.SOMETHING_WENT_WRONG`));
          return;
        }
      });
    } else {
      setMessage(t(`accountActivationPage.SOMETHING_WENT_WRONG`));
      return;
    }
    // eslint-disable-next-line
  }, [searchparams]);

  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <h2 className={Styles.title}>{message}</h2>
      <Button
        linkUrl="/"
        text={t(`accountActivationPage.GO_TO_HOMEPAGE`)}
        className={Styles.button}
      />
      <Button linkUrl="/login" text={t(`accountActivationPage.LOGIN`)} />
    </motion.div>
  );
}

export default AccountActivation;
