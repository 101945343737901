import { fetchData } from "../utils/API";

const paths = {
  allProducts: "/products",
  productType: "/product-type",
  productById: "/products/",
};

export async function getAllProducts() {
  const response = await fetchData(paths.allProducts);
  const json = await response?.json();
  const data = await json.content;

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from: " + paths.allProducts);
  }

  return data;
}

export async function getProductType() {
  const response = await fetchData(paths.productType);
  const data = await response?.json();
  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from: " + paths.productType);
  }
  return data;
}

export async function getProductById(productId: string | null) {
  const response = await fetchData(paths.productById + productId);
  const data = await response?.json();

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from:" + paths.productById);
  }

  return data;
}
