import { useState } from "react";
import Styles from "./RegisterUserForm.module.css";
import { createUserAPI } from "../../../services/UserApi";
import { registerUserInNewsletterAPI } from "../../../services/NewsletterAPI";
import { useTranslation } from "react-i18next";

interface RegisterUserFormCallback {
  updateIsUserRegister: (arg: boolean) => void;
}

function RegisterUserForm(props: RegisterUserFormCallback) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [incorrectMailMessage, setIncorrectMailMessage] = useState("");
  const [passwordNotMatchMessage, setPasswordNotMatchMessage] = useState("");
  const [generalMessage, setGeneralMessage] = useState("");
  const [isSignedNewsletter, setisSignedNewsletter] = useState(true);
  const { t } = useTranslation();

  const checkIfAllFiledsFilledCorrect = () => {
    // eslint-disable-next-line
    var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    var passwordRegex =
      /^(?=.{8,255}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;

    var areAllFieldsFilledIn: boolean =
      email.length > 0 && password.length > 0 && confirmedPassword.length > 0;

    if (!areAllFieldsFilledIn) {
      setGeneralMessage(t("registerUserPage.FILL_OUT_FIELDS_REQUIRED"));
      return false;
    } else {
      setGeneralMessage("");
    }

    if (email.match(emailRegex) === null) {
      setIncorrectMailMessage(t("registerUserPage.EMAIL_IS_INCORRECT"));
      return false;
    } else {
      setIncorrectMailMessage("");
    }

    if (password !== confirmedPassword) {
      setPasswordNotMatchMessage(t("registerUserPage.PASSWORDS_ARE_NOT_SAME"));
      return false;
    } else {
      setPasswordNotMatchMessage("");
    }

    if (password.match(passwordRegex) === null) {
      setPasswordNotMatchMessage(t("registerUserPage.PASSWORD_REQUIREMENT"));
      return false;
    } else {
      setPasswordNotMatchMessage("");
    }

    return true;
  };

  const handleRegister = async () => {
    if (!checkIfAllFiledsFilledCorrect()) {
      return;
    }

    if (isSignedNewsletter) {
      const responses = await Promise.all([
        createUserAPI(email, password),
        registerUserInNewsletterAPI(email),
      ]);

      if (responses[0]?.ok) {
        props.updateIsUserRegister(true);
      } else if (responses[0]?.status === 400) {
        setGeneralMessage(t("registerUserPage.USER_EXISTS"));
      } else {
        setGeneralMessage(t("registerUserPage.REGISTRATION_FAILED"));
      }
    } else {
      createUserAPI(email, password).then((response) => {
        if (response?.ok) {
          props.updateIsUserRegister(true);
        } else if (response?.status === 400) {
          setGeneralMessage(t("registerUserPage.USER_EXISTS"));
        } else {
          setGeneralMessage(t("registerUserPage.REGISTRATION_FAILED"));
        }
      });
    }
  };

  return (
    <div className={Styles.container}>
      <h2 className={Styles.title}>{t(`registerUserPage.REGISTER_WELCOME`)}</h2>
      <h3 className={Styles.subtitle}>
        {t(`registerUserPage.REGISTER_SLOGAN`)}
      </h3>
      <form className={Styles.form}>
        <div className={Styles.emailInputContainer}>
          <label className={Styles.info} htmlFor="">
            {t(`registerUserPage.ENTER_EMAIL`)}{" "}
          </label>
          <input
            className={Styles.input}
            type="text"
            onChange={(e) => setEmail(e.target.value)}
          />
          {incorrectMailMessage && (
            <label className={Styles.error}> {incorrectMailMessage} </label>
          )}
          <label className={Styles.info} htmlFor="">
            {t(`registerUserPage.ENTER_PASSWORD`)}
          </label>
          <input
            className={Styles.input}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <label className={Styles.info} htmlFor="">
            {t(`registerUserPage.CONFIRM_PASSWORD`)}
          </label>
          <input
            className={Styles.input}
            type="password"
            onChange={(e) => setConfirmedPassword(e.target.value)}
          />
          {passwordNotMatchMessage && (
            <label className={Styles.error}> {passwordNotMatchMessage} </label>
          )}
        </div>
        <div className={Styles.newsletterContainer}>
          <input
            className={`${Styles.input} ${Styles.inputNewsletter}`}
            id="newsletter"
            type="checkbox"
            checked={isSignedNewsletter}
            onChange={() => setisSignedNewsletter(!isSignedNewsletter)}
          />
          <label className={Styles.labelNewsletter} htmlFor="newsletter">
            {t(`registerUserPage.NEWSLETTER_QUESTION`)}
          </label>
        </div>
        <input
          className={`${Styles.input} ${Styles.button}`}
          type="button"
          value={t(`registerUserPage.BUTTON_REGISTER`)}
          onClick={handleRegister}
        />
        {generalMessage && (
          <label className={Styles.error}> {generalMessage} </label>
        )}
      </form>
    </div>
  );
}

export default RegisterUserForm;
