import { ChangeEvent } from "react";
import Styles from "./InputForm.module.css";
import React from "react";

interface InputFormProps {
  id: string;
  className?: String;
  type: string;
  value: string | undefined;
  name: string;
  label?: string;
  pattern?: string;
  onChange: (arg: ChangeEvent<HTMLInputElement>) => any;
}
function InputForm(props: InputFormProps) {
  return (
    <div className={`${Styles.container} ${props.className}`}>
      {props.label !== undefined && (
        <label className={Styles.label} htmlFor={props.id}>
          {props.label}
        </label>
      )}
      <React.Fragment>
        <input
          className={Styles.input}
          type={props.type}
          pattern={`${props.pattern}`}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        />
      </React.Fragment>
    </div>
  );
}

export default InputForm;
