import { ICart } from "../../models/cart/Cart.model";
import { ReactElement } from "react";

import Styles from "./CartSummary.module.css";

import arrowRight from "../../assets/icons/right-arrow.svg";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import React from "react";
import { Currency, ICurrency } from "../../models/cart/Currency.model";

interface ICartSummaryProps {
  userCart: ICart;
  paymentErrorMessage: string;
  discountErrorMessage: string;
  userAccessToken?: string | null;
  handleSubmitDiscount: () => void;
  onInputDiscountCode: (arg: string) => void;
  userCurrency: ICurrency;
}

function CartSummary(props: ICartSummaryProps) {
  const { t } = useTranslation();

  const renderCartValue = (): ReactElement => {
    let cartPrice: String | null;
    if (props.userCurrency.name === Currency.PLN)
      if (props.userCart.discountedPrice !== null) {
        cartPrice = props.userCart.discountedPrice;
      } else {
        cartPrice = props.userCart.originalPrice;
      }
    else {
      if (props.userCart.discountedPriceEur !== null) {
        cartPrice = props.userCart.discountedPriceEur;
      } else {
        cartPrice = props.userCart.originalPriceEur;
      }
    }

    return (
      <React.Fragment>
        <h3 className={Styles.cartTag}>{t(`cartInfo.CART_VALUE`)}</h3>
        <h2 className={Styles.cartValue}>
          {cartPrice} {props.userCurrency.name}
        </h2>
      </React.Fragment>
    );
  };

  const renderCartValueBeforeDiscount = (): ReactElement => {
    return (
      <React.Fragment>
        <h4 className={Styles.cartPriceBeforeDiscount}>
          {t(`cartInfo.CART_VALUE_ORIGINAL`)}
        </h4>
        <h2 className={Styles.cartPriceBeforeDiscount}>
          {props.userCurrency.name === Currency.PLN
            ? props.userCart.originalPrice
            : props.userCart.originalPriceEur}{" "}
          {props.userCurrency.name}
        </h2>
      </React.Fragment>
    );
  };

  const renderDiscountInput = (): ReactElement => {
    return (
      <div className={Styles.discountCode}>
        <div>
          <input
            type="text"
            placeholder={t(`cartPage.DISCOUNT_INPUT`)}
            onChange={(e) => props.onInputDiscountCode(e.target.value)}
          />
          <button
            className={Styles.submitDiscount}
            onClick={props.handleSubmitDiscount}
          >
            <img src={arrowRight} alt={arrowRight} />
          </button>
        </div>
        {props.discountErrorMessage && (
          <label className={Styles.error}>{props.discountErrorMessage}</label>
        )}
        <div>
          <Button
            linkUrl={`/delivery-and-payment`}
            text={t(`cartPage.GO_TO_DELIVERY_AND_PAYMENT`)}
            className={`${Styles.button}`}
          />
          {props.paymentErrorMessage && (
            <label className={Styles.error}>{props.paymentErrorMessage}</label>
          )}
        </div>
      </div>
    );
  };

  const renderLogin = (): ReactElement => {
    return (
      <div>
        <p className={Styles.info}>{t(`cartPage.LOGIN_TO_CONTINUE`)}</p>
        <Button
          linkUrl={`/login`}
          text={t(`userInfo.BUTTON_LOGIN`)}
          className={`${Styles.button}`}
        />
        {props.paymentErrorMessage && (
          <label className={Styles.error}>{props.paymentErrorMessage}</label>
        )}
      </div>
    );
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.valueContainer}>{renderCartValue()}</div>
      <div className={Styles.valueContainer}>
        {props.userCart.discountedPrice !== null &&
          renderCartValueBeforeDiscount()}
      </div>
      <hr />
      {props.userAccessToken !== null && props.userAccessToken !== ""
        ? renderDiscountInput()
        : renderLogin()}
    </div>
  );
}

export default CartSummary;
