import { useTranslation } from "react-i18next";
import Styles from "./ExcerciseBlock.module.css";

interface ExcerciseBlockProps {
  title: string;
  musclesDesc: string;
  steps: string[];
}

function ExcerciseBlock(props: ExcerciseBlockProps) {
  const { t } = useTranslation();

  return (
    <div className={Styles.container}>
      <h2 className={Styles.title}>{props.title}</h2>
      <h1 className={Styles.musclesDesc}>
        {t(`excerciseAtlasPage.excercises.INERCISE_DEADLIFT_MUSCLE`)}
      </h1>
      <hr className={Styles.titleDivider} />
      <ol className={Styles.excerciseList}>
        {props.steps.map((item, index) => {
          return (
            <li key={index} className={Styles.step}>
              {t(`excerciseAtlasPage.excercises.${item}`)}
            </li>
          );
        })}
      </ol>
    </div>
  );
}

export default ExcerciseBlock;
