import { RegionEnum } from "../models/address/Region.model";
import { ICountry } from "../models/country/ICountry.model";

export const countries: ICountry[] = [
  {
    countryName: "Poland",
    polishName: "Polska",
    abberivation: "PL",
    diallingCode: "+48",
    euMember: true,
    region: RegionEnum.POLAND,
  },
  {
    countryName: "Switzerland",
    polishName: "Szwajcaria",
    abberivation: "CH",
    diallingCode: "+41",
    euMember: false,
    region: RegionEnum.SWITZERLAND,
  },
  {
    countryName: "Austria",
    polishName: "Austria",
    abberivation: "AT",
    diallingCode: "+43",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Belgium",
    polishName: "Belgia",
    abberivation: "BE",
    diallingCode: "+32",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Bulgaria",
    polishName: "Bułgaria",
    abberivation: "BG",
    diallingCode: "+359",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Croatia",
    polishName: "Chorwacja",
    abberivation: "HR",
    diallingCode: "+385",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Cyprus",
    polishName: "Cypr",
    abberivation: "CY",
    diallingCode: "+357",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Czechia",
    polishName: "Czechy",
    abberivation: "CZ",
    diallingCode: "+420",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Denmark",
    polishName: "Dania",
    abberivation: "DK",
    diallingCode: "+45",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Estonia",
    polishName: "Estonia",
    abberivation: "EE",
    diallingCode: "+372",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Finland",
    polishName: "Finlandia",
    abberivation: "FI",
    diallingCode: "+358",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "France",
    polishName: "Francja",
    abberivation: "FR",
    diallingCode: "+33",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Germany",
    polishName: "Niemcy",
    abberivation: "DE",
    diallingCode: "+49",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Greece",
    polishName: "Grecja",
    abberivation: "GR",
    diallingCode: "+30",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Hungary",
    polishName: "Węgry",
    abberivation: "HU",
    diallingCode: "+36",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Ireland",
    polishName: "Irlandia",
    abberivation: "IE",
    diallingCode: "+353",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Italy",
    polishName: "Włochy",
    abberivation: "IT",
    diallingCode: "+39",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Latvia",
    polishName: "Łotwa",
    abberivation: "LV",
    diallingCode: "+371",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Lithuania",
    polishName: "Litwa",
    abberivation: "LT",
    diallingCode: "+370",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Luxembourg",
    polishName: "Luksemburg",
    abberivation: "LU",
    diallingCode: "+352",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Malta",
    polishName: "Malta",
    abberivation: "MT",
    diallingCode: "+356",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Netherlands",
    polishName: "Holandia",
    abberivation: "NL",
    diallingCode: "+31",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Portugal",
    polishName: "Portugalia",
    abberivation: "PT",
    diallingCode: "+351",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Romania",
    polishName: "Rumunia",
    abberivation: "RO",
    diallingCode: "+40",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Slovakia",
    polishName: "Słowacja",
    abberivation: "SK",
    diallingCode: "+421",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Slovenia",
    polishName: "Słowenia",
    abberivation: "SI",
    diallingCode: "+386",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Spain",
    polishName: "Hiszpania",
    abberivation: "ES",
    diallingCode: "+34",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Sweden",
    polishName: "Szwecja",
    abberivation: "SE",
    diallingCode: "+46",
    euMember: true,
    region: RegionEnum.EUROPEAN_UNION,
  },
  {
    countryName: "Afghanistan",
    polishName: "Afganistan",
    abberivation: "AF",
    diallingCode: "+93",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Albania",
    polishName: "Albania",
    abberivation: "AL",
    diallingCode: "+355",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Algeria",
    polishName: "Algieria",
    abberivation: "DZ",
    diallingCode: "+213",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "American Samoa",
    polishName: "Samoa Amerykańskie",
    abberivation: "AS",
    diallingCode: "+1684",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Andorra",
    polishName: "Andora",
    abberivation: "AD",
    diallingCode: "+376",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Angola",
    polishName: "Angola",
    abberivation: "AO",
    diallingCode: "+244",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Anguilla",
    polishName: "Anguilla",
    abberivation: "AI",
    diallingCode: "+1264",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Antigua and Barbuda",
    polishName: "Antigua i Barbuda",
    abberivation: "AG",
    diallingCode: "+1268",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Argentina",
    polishName: "Argentyna",
    abberivation: "AR",
    diallingCode: "+54",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Armenia",
    polishName: "Armenia",
    abberivation: "AM",
    diallingCode: "+374",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Aruba",
    polishName: "Aruba",
    abberivation: "AW",
    diallingCode: "+297",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Australia",
    polishName: "Australia",
    abberivation: "AU",
    diallingCode: "+61",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Azerbaijan",
    polishName: "Azerbejdżan",
    abberivation: "AZ",
    diallingCode: "+994",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Bahamas",
    polishName: "Bahamy",
    abberivation: "BS",
    diallingCode: "+1242",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Bahrain",
    polishName: "Bahrajn",
    abberivation: "BH",
    diallingCode: "+973",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Bangladesh",
    polishName: "Bangladesz",
    abberivation: "BD",
    diallingCode: "+880",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Barbados",
    polishName: "Barbados",
    abberivation: "BB",
    diallingCode: "+1246",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Belarus",
    polishName: "Białoruś",
    abberivation: "BY",
    diallingCode: "+375",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Belize",
    polishName: "Belize",
    abberivation: "BZ",
    diallingCode: "+501",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Benin",
    polishName: "Benin",
    abberivation: "BJ",
    diallingCode: "+229",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Bermuda",
    polishName: "Bermudy",
    abberivation: "BM",
    diallingCode: "+1441",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Bhutan",
    polishName: "Bhutan",
    abberivation: "BT",
    diallingCode: "+975",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Bolivia",
    polishName: "Boliwia",
    abberivation: "BO",
    diallingCode: "+591",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Bosnia and Herzegovina",
    polishName: "Bośnia i Hercegowina",
    abberivation: "BA",
    diallingCode: "+387",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Botswana",
    polishName: "Botswana",
    abberivation: "BW",
    diallingCode: "+267",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Bouvet Island",
    polishName: "Wyspa Bouveta",
    abberivation: "BV",
    diallingCode: "+47",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Brazil",
    polishName: "Brazylia",
    abberivation: "BR",
    diallingCode: "+55",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "British Indian Ocean Territory",
    polishName: "Brytyjskie Terytorium Oceanu Indyjskiego",
    abberivation: "IO",
    diallingCode: "+246",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "British Virgin Islands",
    polishName: "Brytyjskie Wyspy Dziewicze",
    abberivation: "VG",
    diallingCode: "+1284",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Brunei",
    polishName: "Brunei",
    abberivation: "BN",
    diallingCode: "+673",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Burkina Faso",
    polishName: "Burkina Faso",
    abberivation: "BF",
    diallingCode: "+226",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Burundi",
    polishName: "Burundi",
    abberivation: "BI",
    diallingCode: "+257",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Cambodia",
    polishName: "Kambodża",
    abberivation: "KH",
    diallingCode: "+855",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Cameroon",
    polishName: "Kamerun",
    abberivation: "CM",
    diallingCode: "+237",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Canada",
    polishName: "Kanada",
    abberivation: "CA",
    diallingCode: "+1",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Cape Verde",
    polishName: "Republika Zielonego Przylądka",
    abberivation: "CV",
    diallingCode: "+238",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Caribbean Netherlands",
    polishName: "Antyle Holenderskie",
    abberivation: "BQ",
    diallingCode: "+599",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Cayman Islands",
    polishName: "Kajmany",
    abberivation: "KY",
    diallingCode: "+1345",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Central African Republic",
    polishName: "Republika Środkowoafrykańska",
    abberivation: "CF",
    diallingCode: "+236",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Chad",
    polishName: "Czad",
    abberivation: "TD",
    diallingCode: "+235",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Chile",
    polishName: "Chile",
    abberivation: "CL",
    diallingCode: "+56",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "China",
    polishName: "Chiny",
    abberivation: "CN",
    diallingCode: "+86",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Christmas Island",
    polishName: "Wyspa Bożego Narodzenia",
    abberivation: "CX",
    diallingCode: "+61",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Cocos (Keeling) Islands",
    polishName: "Wyspy Kokosowe",
    abberivation: "CC",
    diallingCode: "+61",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Colombia",
    polishName: "Kolumbia",
    abberivation: "CO",
    diallingCode: "+57",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Comoros",
    polishName: "Komory",
    abberivation: "KM",
    diallingCode: "+269",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Cook Islands",
    polishName: "Wyspy Cooka",
    abberivation: "CK",
    diallingCode: "+682",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Costa Rica",
    polishName: "Kostaryka",
    abberivation: "CR",
    diallingCode: "+506",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Cuba",
    polishName: "Kuba",
    abberivation: "CU",
    diallingCode: "+53",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Curaçao",
    polishName: "Curaçao",
    abberivation: "CW",
    diallingCode: "+599",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "DR Congo",
    polishName: "Demokratyczna Republika Konga",
    abberivation: "CD",
    diallingCode: "+243",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Djibouti",
    polishName: "Dżibuti",
    abberivation: "DJ",
    diallingCode: "+253",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Dominica",
    polishName: "Dominika",
    abberivation: "DM",
    diallingCode: "+1767",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Dominican Republic",
    polishName: "Dominikana",
    abberivation: "DO",
    diallingCode: "+1809",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Ecuador",
    polishName: "Ekwador",
    abberivation: "EC",
    diallingCode: "+593",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Egypt",
    polishName: "Egipt",
    abberivation: "EG",
    diallingCode: "+20",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "El Salvador",
    polishName: "Salwador",
    abberivation: "SV",
    diallingCode: "+503",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Equatorial Guinea",
    polishName: "Gwinea Równikowa",
    abberivation: "GQ",
    diallingCode: "+240",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Eritrea",
    polishName: "Erytrea",
    abberivation: "ER",
    diallingCode: "+291",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Eswatini",
    polishName: "Suazi",
    abberivation: "SZ",
    diallingCode: "+268",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Ethiopia",
    polishName: "Etiopia",
    abberivation: "ET",
    diallingCode: "+251",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Falkland Islands",
    polishName: "Falklandy",
    abberivation: "FK",
    diallingCode: "+500",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Faroe Islands",
    polishName: "Wyspy Owcze",
    abberivation: "FO",
    diallingCode: "+298",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Fiji",
    polishName: "Fidżi",
    abberivation: "FJ",
    diallingCode: "+679",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "French Guiana",
    polishName: "Gujana Francuska",
    abberivation: "GF",
    diallingCode: "+594",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "French Polynesia",
    polishName: "Polinezja Francuska",
    abberivation: "PF",
    diallingCode: "+689",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "French Southern and Antarctic Lands",
    polishName: "Francuskie Terytoria Południowe i Antarktyczne",
    abberivation: "TF",
    diallingCode: "+262",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Gabon",
    polishName: "Gabon",
    abberivation: "GA",
    diallingCode: "+241",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Gambia",
    polishName: "Gambia",
    abberivation: "GM",
    diallingCode: "+220",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Georgia",
    polishName: "Gruzja",
    abberivation: "GE",
    diallingCode: "+995",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Ghana",
    polishName: "Ghana",
    abberivation: "GH",
    diallingCode: "+233",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Gibraltar",
    polishName: "Gibraltar",
    abberivation: "GI",
    diallingCode: "+350",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Greenland",
    polishName: "Grenlandia",
    abberivation: "GL",
    diallingCode: "+299",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Grenada",
    polishName: "Grenada",
    abberivation: "GD",
    diallingCode: "+1473",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Guadeloupe",
    polishName: "Gwadelupa",
    abberivation: "GP",
    diallingCode: "+590",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Guam",
    polishName: "Guam",
    abberivation: "GU",
    diallingCode: "+1671",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Guatemala",
    polishName: "Gwatemala",
    abberivation: "GT",
    diallingCode: "+502",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Guernsey",
    polishName: "Guernsey",
    abberivation: "GG",
    diallingCode: "+44",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Guinea",
    polishName: "Gwinea",
    abberivation: "GN",
    diallingCode: "+224",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Guinea-Bissau",
    polishName: "Gwinea Bissau",
    abberivation: "GW",
    diallingCode: "+245",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Guyana",
    polishName: "Gujana",
    abberivation: "GY",
    diallingCode: "+592",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Haiti",
    polishName: "Haiti",
    abberivation: "HT",
    diallingCode: "+509",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Honduras",
    polishName: "Honduras",
    abberivation: "HN",
    diallingCode: "+504",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Hong Kong",
    polishName: "Hongkong",
    abberivation: "HK",
    diallingCode: "+852",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Iceland",
    polishName: "Islandia",
    abberivation: "IS",
    diallingCode: "+354",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "India",
    polishName: "Indie",
    abberivation: "IN",
    diallingCode: "+91",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Indonesia",
    polishName: "Indonezja",
    abberivation: "ID",
    diallingCode: "+62",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Iran",
    polishName: "Iran",
    abberivation: "IR",
    diallingCode: "+98",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Iraq",
    polishName: "Irak",
    abberivation: "IQ",
    diallingCode: "+964",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Isle of Man",
    polishName: "Wyspa Man",
    abberivation: "IM",
    diallingCode: "+44",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Israel",
    polishName: "Izrael",
    abberivation: "IL",
    diallingCode: "+972",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Ivory Coast",
    polishName: "WybrzeŻe Kości Słoniowej",
    abberivation: "CI",
    diallingCode: "+225",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Jamaica",
    polishName: "Jamajka",
    abberivation: "JM",
    diallingCode: "+1876",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Japan",
    polishName: "Japonia",
    abberivation: "JP",
    diallingCode: "+81",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Jersey",
    polishName: "Jersey",
    abberivation: "JE",
    diallingCode: "+44",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Jordan",
    polishName: "Jordania",
    abberivation: "JO",
    diallingCode: "+962",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Kazakhstan",
    polishName: "Kazachstan",
    abberivation: "KZ",
    diallingCode: "+76",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Kenya",
    polishName: "Kenia",
    abberivation: "KE",
    diallingCode: "+254",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Kiribati",
    polishName: "Kiribati",
    abberivation: "KI",
    diallingCode: "+686",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Kosovo",
    polishName: "Kosowo",
    abberivation: "XK",
    diallingCode: "+383",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Kuwait",
    polishName: "Kuwejt",
    abberivation: "KW",
    diallingCode: "+965",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Kyrgyzstan",
    polishName: "Kirgistan",
    abberivation: "KG",
    diallingCode: "+996",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Laos",
    polishName: "Laos",
    abberivation: "LA",
    diallingCode: "+856",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Lebanon",
    polishName: "Liban",
    abberivation: "LB",
    diallingCode: "+961",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Lesotho",
    polishName: "Lesotho",
    abberivation: "LS",
    diallingCode: "+266",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Liberia",
    polishName: "Liberia",
    abberivation: "LR",
    diallingCode: "+231",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Libya",
    polishName: "Libia",
    abberivation: "LY",
    diallingCode: "+218",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Liechtenstein",
    polishName: "Liechtenstein",
    abberivation: "LI",
    diallingCode: "+423",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Macau",
    polishName: "Makau",
    abberivation: "MO",
    diallingCode: "+853",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Madagascar",
    polishName: "Madagaskar",
    abberivation: "MG",
    diallingCode: "+261",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Malawi",
    polishName: "Malawi",
    abberivation: "MW",
    diallingCode: "+265",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Malaysia",
    polishName: "Malezja",
    abberivation: "MY",
    diallingCode: "+60",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Maldives",
    polishName: "Malediwy",
    abberivation: "MV",
    diallingCode: "+960",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Mali",
    polishName: "Mali",
    abberivation: "ML",
    diallingCode: "+223",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Marshall Islands",
    polishName: "Wyspy Marshalla",
    abberivation: "MH",
    diallingCode: "+692",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Martinique",
    polishName: "Martynika",
    abberivation: "MQ",
    diallingCode: "+596",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Mauritania",
    polishName: "Mauretania",
    abberivation: "MR",
    diallingCode: "+222",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Mauritius",
    polishName: "Mauritius",
    abberivation: "MU",
    diallingCode: "+230",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Mayotte",
    polishName: "Majotta",
    abberivation: "YT",
    diallingCode: "+262",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Mexico",
    polishName: "Meksyk",
    abberivation: "MX",
    diallingCode: "+52",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Micronesia",
    polishName: "Mikronezja",
    abberivation: "FM",
    diallingCode: "+691",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Moldova",
    polishName: "Mołdawia",
    abberivation: "MD",
    diallingCode: "+373",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Monaco",
    polishName: "Monako",
    abberivation: "MC",
    diallingCode: "+377",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Mongolia",
    polishName: "Mongolia",
    abberivation: "MN",
    diallingCode: "+976",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Montenegro",
    polishName: "Czarnogóra",
    abberivation: "ME",
    diallingCode: "+382",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Montserrat",
    polishName: "Montserrat",
    abberivation: "MS",
    diallingCode: "+1664",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Morocco",
    polishName: "Maroko",
    abberivation: "MA",
    diallingCode: "+212",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Mozambique",
    polishName: "Mozambik",
    abberivation: "MZ",
    diallingCode: "+258",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Myanmar",
    polishName: "Mjanma",
    abberivation: "MM",
    diallingCode: "+95",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Namibia",
    polishName: "Namibia",
    abberivation: "NA",
    diallingCode: "+264",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Nauru",
    polishName: "Nauru",
    abberivation: "NR",
    diallingCode: "+674",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Nepal",
    polishName: "Nepal",
    abberivation: "NP",
    diallingCode: "+977",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "New Caledonia",
    polishName: "Nowa Kaledonia",
    abberivation: "NC",
    diallingCode: "+687",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "New Zealand",
    polishName: "Nowa Zelandia",
    abberivation: "NZ",
    diallingCode: "+64",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Nicaragua",
    polishName: "Nikaragua",
    abberivation: "NI",
    diallingCode: "+505",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Niger",
    polishName: "Niger",
    abberivation: "NE",
    diallingCode: "+227",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Nigeria",
    polishName: "Nigeria",
    abberivation: "NG",
    diallingCode: "+234",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Niue",
    polishName: "Niue",
    abberivation: "NU",
    diallingCode: "+683",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Norfolk Island",
    polishName: "Wyspa Norfolk",
    abberivation: "NF",
    diallingCode: "+672",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "North Korea",
    polishName: "Korea Północna",
    abberivation: "KP",
    diallingCode: "+850",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "North Macedonia",
    polishName: "Macedonia Północna",
    abberivation: "MK",
    diallingCode: "+389",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Northern Mariana Islands",
    polishName: "Mariany Północne",
    abberivation: "MP",
    diallingCode: "+1670",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Norway",
    polishName: "Norwegia",
    abberivation: "NO",
    diallingCode: "+47",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Oman",
    polishName: "Oman",
    abberivation: "OM",
    diallingCode: "+968",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Pakistan",
    polishName: "Pakistan",
    abberivation: "PK",
    diallingCode: "+92",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Palau",
    polishName: "Palau",
    abberivation: "PW",
    diallingCode: "+680",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Palestine",
    polishName: "Palestyna",
    abberivation: "PS",
    diallingCode: "+970",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Panama",
    polishName: "Panama",
    abberivation: "PA",
    diallingCode: "+507",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Papua New Guinea",
    polishName: "Papua-Nowa Gwinea",
    abberivation: "PG",
    diallingCode: "+675",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Paraguay",
    polishName: "Paragwaj",
    abberivation: "PY",
    diallingCode: "+595",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Peru",
    polishName: "Peru",
    abberivation: "PE",
    diallingCode: "+51",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Philippines",
    polishName: "Filipiny",
    abberivation: "PH",
    diallingCode: "+63",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Pitcairn Islands",
    polishName: "Pitcairn",
    abberivation: "PN",
    diallingCode: "+64",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Puerto Rico",
    polishName: "Portoryko",
    abberivation: "PR",
    diallingCode: "+1787",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Qatar",
    polishName: "Katar",
    abberivation: "QA",
    diallingCode: "+974",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Republic of the Congo",
    polishName: "Kongo",
    abberivation: "CG",
    diallingCode: "+242",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Rwanda",
    polishName: "Rwanda",
    abberivation: "RW",
    diallingCode: "+250",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Réunion",
    polishName: "Reunion",
    abberivation: "RE",
    diallingCode: "+262",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Saint Barthélemy",
    polishName: "Saint-Barthélemy",
    abberivation: "BL",
    diallingCode: "+590",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Saint Helena, Ascension and Tristan da Cunha",
    polishName:
      "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha",
    abberivation: "SH",
    diallingCode: "+290",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Saint Kitts and Nevis",
    polishName: "Saint Kitts i Nevis",
    abberivation: "KN",
    diallingCode: "+1869",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Saint Lucia",
    polishName: "Saint Lucia",
    abberivation: "LC",
    diallingCode: "+1758",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Saint Martin",
    polishName: "Saint-Martin",
    abberivation: "MF",
    diallingCode: "+590",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Saint Pierre and Miquelon",
    polishName: "Saint-Pierre i Miquelon",
    abberivation: "PM",
    diallingCode: "+508",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Saint Vincent and the Grenadines",
    polishName: "Saint Vincent i Grenadyny",
    abberivation: "VC",
    diallingCode: "+1784",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Samoa",
    polishName: "Samoa",
    abberivation: "WS",
    diallingCode: "+685",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "San Marino",
    polishName: "San Marino",
    abberivation: "SM",
    diallingCode: "+378",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Saudi Arabia",
    polishName: "Arabia Saudyjska",
    abberivation: "SA",
    diallingCode: "+966",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Senegal",
    polishName: "Senegal",
    abberivation: "SN",
    diallingCode: "+221",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Serbia",
    polishName: "Serbia",
    abberivation: "RS",
    diallingCode: "+381",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Seychelles",
    polishName: "Seszele",
    abberivation: "SC",
    diallingCode: "+248",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Sierra Leone",
    polishName: "Sierra Leone",
    abberivation: "SL",
    diallingCode: "+232",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Singapore",
    polishName: "Singapur",
    abberivation: "SG",
    diallingCode: "+65",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Sint Maarten",
    polishName: "Sint Maarten",
    abberivation: "SX",
    diallingCode: "+1721",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Solomon Islands",
    polishName: "Wyspy Salomona",
    abberivation: "SB",
    diallingCode: "+677",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Somalia",
    polishName: "Somalia",
    abberivation: "SO",
    diallingCode: "+252",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "South Africa",
    polishName: "Południowa Afryka",
    abberivation: "ZA",
    diallingCode: "+27",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "South Georgia",
    polishName: "Georgia Południowa i Sandwich Południowy",
    abberivation: "GS",
    diallingCode: "+500",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "South Korea",
    polishName: "Korea Południowa",
    abberivation: "KR",
    diallingCode: "+82",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "South Sudan",
    polishName: "Sudan",
    abberivation: "SS",
    diallingCode: "+211",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Sri Lanka",
    polishName: "Sri Lanka",
    abberivation: "LK",
    diallingCode: "+94",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Sudan",
    polishName: "Sudan",
    abberivation: "SD",
    diallingCode: "+249",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Suriname",
    polishName: "Surinam",
    abberivation: "SR",
    diallingCode: "+597",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Svalbard and Jan Mayen",
    polishName: "Svalbard i Jan Mayen",
    abberivation: "SJ",
    diallingCode: "+4779",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Syria",
    polishName: "Syria",
    abberivation: "SY",
    diallingCode: "+963",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "São Tomé and Príncipe",
    polishName: "Wyspy Świętego Tomasza i Książęca",
    abberivation: "ST",
    diallingCode: "+239",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Taiwan",
    polishName: "Tajwan",
    abberivation: "TW",
    diallingCode: "+886",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Tajikistan",
    polishName: "Tadżykistan",
    abberivation: "TJ",
    diallingCode: "+992",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Tanzania",
    polishName: "Tanzania",
    abberivation: "TZ",
    diallingCode: "+255",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Thailand",
    polishName: "Tajlandia",
    abberivation: "TH",
    diallingCode: "+66",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Timor-Leste",
    polishName: "Timor Wschodni",
    abberivation: "TL",
    diallingCode: "+670",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Togo",
    polishName: "Togo",
    abberivation: "TG",
    diallingCode: "+228",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Tokelau",
    polishName: "Tokelau",
    abberivation: "TK",
    diallingCode: "+690",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Tonga",
    polishName: "Tonga",
    abberivation: "TO",
    diallingCode: "+676",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Trinidad and Tobago",
    polishName: "Trynidad i Tobago",
    abberivation: "TT",
    diallingCode: "+1868",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Tunisia",
    polishName: "Tunezja",
    abberivation: "TN",
    diallingCode: "+216",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Turkey",
    polishName: "Turcja",
    abberivation: "TR",
    diallingCode: "+90",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Turkmenistan",
    polishName: "Turkmenistan",
    abberivation: "TM",
    diallingCode: "+993",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Turks and Caicos Islands",
    polishName: "Turks i Caicos",
    abberivation: "TC",
    diallingCode: "+1649",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Tuvalu",
    polishName: "Tuvalu",
    abberivation: "TV",
    diallingCode: "+688",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Uganda",
    polishName: "Uganda",
    abberivation: "UG",
    diallingCode: "+256",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Ukraine",
    polishName: "Ukraina",
    abberivation: "UA",
    diallingCode: "+380",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "United Arab Emirates",
    polishName: "Emiraty Arabskie",
    abberivation: "AE",
    diallingCode: "+971",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "United Kingdom",
    polishName: "Zjednoczone Królestwo",
    abberivation: "GB",
    diallingCode: "+44",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "United States",
    polishName: "Stany Zjednoczone",
    abberivation: "US",
    diallingCode: "+1201",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "United States Minor Outlying Islands",
    polishName: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
    abberivation: "UM",
    diallingCode: "+268",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "United States Virgin Islands",
    polishName: "Wyspy Dziewicze Stanów Zjednoczonych",
    abberivation: "VI",
    diallingCode: "+1340",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Uruguay",
    polishName: "Urugwaj",
    abberivation: "UY",
    diallingCode: "+598",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Uzbekistan",
    polishName: "Uzbekistan",
    abberivation: "UZ",
    diallingCode: "+998",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Vanuatu",
    polishName: "Vanuatu",
    abberivation: "VU",
    diallingCode: "+678",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Vatican City",
    polishName: "Watykan",
    abberivation: "VA",
    diallingCode: "+3906698",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Venezuela",
    polishName: "Wenezuela",
    abberivation: "VE",
    diallingCode: "+58",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Vietnam",
    polishName: "Wietnam",
    abberivation: "VN",
    diallingCode: "+84",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Wallis and Futuna",
    polishName: "Wallis i Futuna",
    abberivation: "WF",
    diallingCode: "+681",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Western Sahara",
    polishName: "Sahara Zachodnia",
    abberivation: "EH",
    diallingCode: "+2125288",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Yemen",
    polishName: "Jemen",
    abberivation: "YE",
    diallingCode: "+967",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Zambia",
    polishName: "Zambia",
    abberivation: "ZM",
    diallingCode: "+260",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Zimbabwe",
    polishName: "Zimbabwe",
    abberivation: "ZW",
    diallingCode: "+263",
    euMember: false,
    region: RegionEnum.OTHER,
  },
  {
    countryName: "Åland Islands",
    polishName: "Wyspy Alandzkie",
    abberivation: "AX",
    diallingCode: "+35818",
    euMember: false,
    region: RegionEnum.OTHER,
  },
];
