import { Routes, Route, useLocation } from "react-router-dom";

// import About from "../pages/About/About";
import Article from "../pages/Article/Article";
import Shop from "../pages/Shop/Shop";
import Header from "../components/header/Header";
import Home from "../pages/Home/Home";
import Blog from "../pages/Blog/Blog";
import Contact from "../pages/Contact/Contact";
import Footer from "../components/footer/Footer";
import Login from "../pages/Login/Login";
import ProductCard from "../pages/Product/ProductCard";
import ExcerciseAtlas from "../pages/ExcerciseAtlas/ExcerciseAtlas";
import Styles from "./App.module.css";
import Inercise from "../pages/Inercise/Inercise";
import Application from "../pages/Application/Application";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import RegisterUser from "../pages/RegisterUser/RegisterUser";
import AccountActivation from "../pages/AccountActivation/AccountActivation";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import QrCode from "../pages/QrCode/QrCode";
import Cart from "../pages/UserCart/UserCart";
import DeliveryAndPayment from "../pages/DeliveryAndPayment/DeliveryAndPayment";
import ScrollToTop from "../components/ScrollToTop";
import Order from "../pages/Order/Order";
import { useEffect, useState } from "react";
import UserOrder from "../pages/UserOrder/UserOrder";
import TermsOfSale from "../pages/TermsOfSale/TermsOfSale";
import ReactGA from "react-ga4";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./Store";
import { getCurrentUser } from "../services/UserApi";
import { logout } from "../features/user/reducers/UserSlice";
import { clearCart } from "../features/cart/reducers/CartSlice";
import { getCurrenciesAPI } from "../services/CurrencyAPI";
import i18n from "../i18n";
import { Currency, ICurrency } from "../models/cart/Currency.model";
import { setUserCurrency } from "../features/cart/reducers/CurrencySlice";
import UserDeleteAccount from "../pages/UserDeleteAccount/UserDeleteAccount";
import Partners from "../pages/Partners/Partners";

ReactGA.initialize("G-9J71YX7PDG");

function App() {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const userAccessToken = useSelector(
    (state: RootState) => state.userState.accessToken
  );

  useEffect(() => {
    if (
      userAccessToken !== undefined &&
      userAccessToken !== null &&
      userAccessToken !== ""
    ) {
      getCurrentUser(userAccessToken).then((response) => {
        if (!response.ok) {
          dispatch(logout());
          dispatch(clearCart());
        }
      });
    }
  });

  useEffect(() => {
    getCurrenciesAPI().then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          const currencies: ICurrency[] = data;
          if (i18n.language === "pl") {
            const userCurrency: ICurrency[] = currencies.filter(
              (currency) => currency.name === Currency.PLN
            );
            dispatch(setUserCurrency(userCurrency[0]));
          } else {
            const userCurrency: ICurrency[] = currencies.filter(
              (currency) => currency.name === Currency.EUR
            );
            dispatch(setUserCurrency(userCurrency[0]));
          }
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    ReactGA.send(location.pathname + location.search);
  }, [location]);

  const handleMobileMenu = (arg: boolean) => {
    setIsMobileMenuVisible(arg);
  };

  return (
    <div
      className={
        isMobileMenuVisible ? Styles.containerMobile : Styles.container
      }
    >
      <React.Fragment>
        <ScrollToTop />
        <Header onInputChnage={handleMobileMenu} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/article" element={<Article />} />
          <Route path="/shop" element={<Shop />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/inercise" element={<Inercise />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register-user" element={<RegisterUser />} />
          <Route path="/product" element={<ProductCard />} />
          <Route path="/excercise-atlas" element={<ExcerciseAtlas />} />
          <Route path="/application" element={<Application />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/activate-account" element={<AccountActivation />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/qr" element={<QrCode />} />
          <Route path="/qr/:serialNumber" element={<QrCode />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/order" element={<Order />} />
          <Route path="/user-order" element={<UserOrder />} />
          <Route path="/terms-of-sale" element={<TermsOfSale />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/profile/edit" element={<UserDeleteAccount />}></Route>
          <Route
            path="/delivery-and-payment"
            element={<DeliveryAndPayment />}
          />
        </Routes>
        <Footer />
      </React.Fragment>
    </div>
  );
}

export default App;
