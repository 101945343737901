import { ICartItem } from "../../models/cart/CartItem.model";

import Styles from "./QuantityToggle.module.css";

import plusIcon from "../../assets/icons/plus-solid.svg";
import minusIcon from "../../assets/icons/minus-solid.svg";
import minusIconDisabled from "../../assets/icons/minus-solid-disabled.svg";

interface QuantityToggleProps {
  cartItem: ICartItem;
  onIncreaseProductQuantity: () => void;
  onDecreaseProductQuantity: () => void;
}
function QuantityToggle(props: QuantityToggleProps) {
  return (
    <div className={Styles.container}>
      <img
        className={Styles.counterIcon}
        src={plusIcon}
        alt="Decrease"
        onClick={props.onIncreaseProductQuantity}
      />
      <p className={Styles.counter}>{props.cartItem.quantity}</p>
      <img
        className={`${Styles.counterIcon}`}
        src={props.cartItem.quantity > 1 ? minusIcon : minusIconDisabled}
        alt="Increase"
        onClick={props.onDecreaseProductQuantity}
      />
    </div>
  );
}

export default QuantityToggle;
