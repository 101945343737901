import Styles from "./InerciseFeatureSection.module.css";

import inercise_1 from "../../assets/images/inercise_feature_section_1.png";
import inercise_2 from "../../assets/images/inercise_feature_section_2.png";
import inercise_3 from "../../assets/images/inercise_feature_section_3.png";
import inercise_4 from "../../assets/images/inercise_feature_section_4.png";
import inercise_5 from "../../assets/images/inercise_feature_section_5.png";
import inercise_6 from "../../assets/images/inercise_feature_section_6.png";
import inercise_7 from "../../assets/images/inercise_feature_section_7.png";
import inercise_8 from "../../assets/images/inercise_feature_section_8.png";
import inercise_9 from "../../assets/images/inercise_feature_section_9.png";
import inercise_10 from "../../assets/images/inercise_feature_section_10.png";
import inercise_11 from "../../assets/images/inercise_feature_section_11.png";
import inercise_12 from "../../assets/images/inercise_feature_section_12.png";
import inercise_13 from "../../assets/images/inercise_feature_section_13.png";
import inercise_14 from "../../assets/images/inercise_feature_section_14.png";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const images: string[] = [
  inercise_1,
  inercise_2,
  inercise_3,
  inercise_4,
  inercise_5,
  inercise_6,
  inercise_7,
  inercise_8,
  inercise_9,
  inercise_10,
  inercise_11,
  inercise_12,
  inercise_13,
  inercise_14,
];

function InerciseFeatureSection() {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentImage !== 13) {
        setCurrentImage(currentImage + 1);
      } else {
        setCurrentImage(0);
      }
    }, 750);

    return () => clearInterval(intervalId);
  }, [currentImage]);

  return (
    <section className={Styles.container}>
      <hr className={Styles.sectionDivider} />
      <div className={Styles.content}>
        <img
          className={Styles.inerciseImg}
          src={`${images[currentImage]}`}
          alt="Inercise positions"
        />
        <div className={Styles.textContainer}>
          <h3 className={Styles.subtitle}>
            {t(`discoverInercisePage.featureSection.TITLE`)}
          </h3>
          <h2 className={Styles.title}>Inercise</h2>
          <hr className={Styles.titleDivider} />
          <p className={Styles.text}>
            {t(`discoverInercisePage.featureSection.DESC_1`)}
          </p>
          <p className={Styles.text}>
            {t(`discoverInercisePage.featureSection.DESC_2`)}
          </p>
          <hr className={Styles.endDivider} />
        </div>
      </div>
    </section>
  );
}

export default InerciseFeatureSection;
