import InerciseBlock from "./InerciseBlock";

import inerciseFeatures from "../../constans/InerciseFeatures";

import Styles from "./InerciseAdvantagesSection.module.css";

function InerciseAdvantagesSection() {
  return (
    <div className={Styles.container}>
      {inerciseFeatures.map((item, index) => {
        if (index % 2 !== 0) {
          return (
            <InerciseBlock
              key={item.id}
              title={item.title}
              text={item.text}
              imageURL={item.image}
              textSide={"left"}
            />
          );
        } else {
          return (
            <InerciseBlock
              key={item.id}
              title={item.title}
              text={item.text}
              imageURL={item.image}
              textSide={"right"}
            />
          );
        }
      })}
    </div>
  );
}

export default InerciseAdvantagesSection;
