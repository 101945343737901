import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/Store";
import { ICart } from "../../../models/cart/Cart.model";
import { ICartItem } from "../../../models/cart/CartItem.model";
import { User } from "../../../models/user/User.model";
import { Product } from "../../../models/product/Product.model";
import { ProductProperty } from "../../../models/product/ProductProperty.model";
import {
  getProductsInCartPriceEuro,
  getProductsInCartPricePLN,
} from "../../../utils/CartHelpers";

const initialState = {
  userCart: {
    id: null,
    cartItems: new Array<ICartItem>(),
    user: {} as User,
    discount: null,
    originalPrice: null,
    discountedPrice: null,
    originalPriceEur: null,
    discountedPriceEur: null,
    shipmentPrice: null,
    shipmentPriceEur: null,
  } as ICart,
};

export const userCartSlice = createSlice({
  name: "userCart",
  initialState,
  reducers: {
    setUserCart: (state, action: PayloadAction<ICart>) => {
      state.userCart = action.payload;
    },
    setShipmentPriceToNull: (state) => {
      state.userCart.shipmentPrice = null;
      state.userCart.shipmentPriceEur = null;
    },
    addProductToCart(
      state,
      action: PayloadAction<{
        newProduct: Product;
        productSelectedProperties: ProductProperty[];
        quantity: number;
      }>
    ) {
      const { newProduct, productSelectedProperties, quantity } =
        action.payload;

      const newCartItem: ICartItem = {
        id:
          state.userCart.cartItems === null
            ? 1
            : state.userCart.cartItems.length + 1,
        product: newProduct,
        selectedProperties: productSelectedProperties,
        quantity: quantity,
      };

      if (state.userCart.cartItems !== null) {
        const duplicatedCartItem = state.userCart.cartItems.find((cartItem) => {
          if (cartItem.product.id === newProduct.id) {
            if (
              JSON.stringify(cartItem.selectedProperties) ===
              JSON.stringify(newCartItem.selectedProperties)
            ) {
              cartItem.quantity += 1;
              return cartItem;
            }
          }
          // eslint-disable-next-line
          return;
        });
        if (duplicatedCartItem === undefined) {
          state.userCart.cartItems.push(newCartItem);
        }
      } else {
        state.userCart.cartItems = new Array<ICartItem>(newCartItem);
      }
      state.userCart.originalPrice = String(
        getProductsInCartPricePLN(state.userCart.cartItems)
      );
      state.userCart.originalPriceEur = String(
        getProductsInCartPriceEuro(state.userCart.cartItems)
      );
    },
    removeProductfromCart(state, action: PayloadAction<number>) {
      const cartItemId = action.payload;
      const userCartItem = state.userCart.cartItems;
      const newUserCartItem = userCartItem.filter((cartItem: ICartItem) => {
        return cartItem.id !== cartItemId;
      });
      state.userCart.cartItems = newUserCartItem;
    },

    decreaseProductQuantityInCart(state, action: PayloadAction<number>) {
      const cartItemId = action.payload;
      const userCartItem = state.userCart.cartItems;
      // eslint-disable-next-line
      userCartItem.map((item) => {
        if (item.id === cartItemId) {
          item.quantity -= 1;
        }
      });

      state.userCart.cartItems = userCartItem;
      state.userCart.originalPrice = String(
        getProductsInCartPricePLN(state.userCart.cartItems)
      );
      state.userCart.originalPriceEur = String(
        getProductsInCartPriceEuro(state.userCart.cartItems)
      );
    },
    clearCart(state) {
      state.userCart = initialState.userCart;
    },
  },
});

export const {
  setUserCart,
  setShipmentPriceToNull,
  addProductToCart,
  removeProductfromCart,
  decreaseProductQuantityInCart,
  clearCart,
} = userCartSlice.actions;
export const selectUserCart = (state: RootState) => state.cartState.userCart;
export default userCartSlice.reducer;
