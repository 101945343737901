import Styles from "./SocialMediaLink.module.css";

interface SocialMedia {
  title: string;
  icon: string;
  url: string;
}

function SocialMediaLink(props: SocialMedia) {
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const iconUrl = require(`../../assets/icons/${props.icon}`);
  return (
    <li className={Styles.item}>
      <a className={Styles.link} href={props.url}>
        <img src={iconUrl} width="25rem" height="auto" alt={props.title} />
      </a>
    </li>
  );
}

export default SocialMediaLink;
