import CartItemProperties from "../../components/CartItemProperties";
import QuantityToggle from "./QuantityToggle";

import { ICartItem } from "../../models/cart/CartItem.model";

import closeIcon from "../../assets/icons/close.svg";

import Styles from "./CartItem.module.css";
import { Currency, ICurrency } from "../../models/cart/Currency.model";

interface ICartItemProps {
  cartItem: ICartItem;
  onRemoveProduct: () => void;
  onIncreaseProductQuantity: () => void;
  onDecreaseProductQuantity: () => void;
  userCurrency: ICurrency;
}

function CartItem(props: ICartItemProps) {
  const getTotalPrice = (): Number => {
    let priceInUserCurrency: Number = 0;

    props.userCurrency.name === Currency.PLN
      ? (priceInUserCurrency = Number(props.cartItem.product.currentPrice))
      : (priceInUserCurrency = Number(props.cartItem.product.currentPriceEur));

    return Number(priceInUserCurrency) * Number(props.cartItem.quantity);
  };

  return (
    <div className={Styles.container}>
      <CartItemProperties
        cartItem={props.cartItem}
        userCurrency={props.userCurrency}
        unitPriceStyle={Styles.unitPrice}
      />
      <QuantityToggle
        cartItem={props.cartItem}
        onDecreaseProductQuantity={props.onDecreaseProductQuantity}
        onIncreaseProductQuantity={props.onIncreaseProductQuantity}
      />
      <p className={`${Styles.priceInfo}`}>{`${getTotalPrice()} ${
        props.userCurrency.name
      }`}</p>
      <img
        className={Styles.closeIcon}
        src={closeIcon}
        alt="Close"
        onClick={props.onRemoveProduct}
      />
    </div>
  );
}

export default CartItem;
