import { ChangeEvent } from "react";
import InputForm from "../../components/InputForm";
import { IInvoiceAddress } from "../../models/address/InvoiceAddress.model";
import Styles from "./ShippingAddressForm.module.css";
import { useTranslation } from "react-i18next";
import { validatePhoneNumber } from "../../utils/AddressHelpers";

interface InvoiceAddressFormProps {
  defaultInput: IInvoiceAddress | null;
  onInputChnage: (arg: { key: string; value: string }) => void;
  errorMessage: String | null | undefined;
}

function InvoiceAddressForm(props: InvoiceAddressFormProps) {
  const { t } = useTranslation();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onInputChnage({
      key: e.currentTarget.name,
      value: e.currentTarget.value,
    });
  };

  const handleOnChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if ((e.currentTarget.id = "phoneNumber")) {
      const isPhoneNumberValid: boolean = validatePhoneNumber(
        String(e.currentTarget.value)
      );
      if (!isPhoneNumberValid) {
        e.currentTarget.value = e.currentTarget.defaultValue;
        e.preventDefault();
      } else {
        props.onInputChnage({
          key: e.currentTarget.name,
          value: e.currentTarget.value,
        });
      }
    }
  };

  return (
    <form className={Styles.form}>
      <h3 className={Styles.subtitle}>Dane do faktury</h3>
      <InputForm
        id="companyName"
        type="text"
        value={props.defaultInput?.companyName}
        name="companyName"
        label={t(`deliveryAndPaymentAndPage.COMPANY_NAME`)}
        onChange={handleOnChange}
      />
      <InputForm
        id="companyIdentificationNumber"
        type="text"
        value={props.defaultInput?.companyIdentificationNumber}
        name="companyIdentificationNumber"
        label={t(`deliveryAndPaymentAndPage.COMPANY_IDENTIFICATION_NUMBER`)}
        onChange={handleOnChange}
      />
      <InputForm
        id="country"
        type="text"
        value={props.defaultInput?.country}
        name="country"
        label={t(`deliveryAndPaymentAndPage.COUNTRY`)}
        onChange={handleOnChange}
      />
      <InputForm
        id="state"
        type="text"
        value={props.defaultInput?.state}
        name="state"
        label={t(`deliveryAndPaymentAndPage.VOIVODEOSHIP`)}
        onChange={handleOnChange}
      />
      <InputForm
        id="city"
        type="text"
        value={props.defaultInput?.city}
        name="city"
        label={t(`deliveryAndPaymentAndPage.CITY`)}
        onChange={handleOnChange}
      />
      <InputForm
        id="postcode"
        type="text"
        value={props.defaultInput?.postcode}
        name="postcode"
        label={t(`deliveryAndPaymentAndPage.POST_CODE`)}
        onChange={handleOnChange}
      />
      <InputForm
        id="addressLine_1"
        type="text"
        value={props.defaultInput?.addressLine_1}
        name="addressLine_1"
        label={t(`deliveryAndPaymentAndPage.ADDRESS_1`)}
        onChange={handleOnChange}
      />
      <InputForm
        id="addressLine_2"
        type="text"
        value={props.defaultInput?.addressLine_2}
        name="addressLine_2"
        label={t(`deliveryAndPaymentAndPage.ADDRESS_2`)}
        onChange={handleOnChange}
      />
      <InputForm
        id="phoneNumber"
        type="text"
        value={props.defaultInput?.phoneNumber}
        name="phoneNumber"
        label={t(`deliveryAndPaymentAndPage.PHONE_NUMBER`)}
        onChange={handleOnChangePhoneNumber}
      />
      {props.errorMessage && (
        <label className={Styles.error}>{props.errorMessage}</label>
      )}
    </form>
  );
}

export default InvoiceAddressForm;
