import { ICartItem } from "../../models/cart/CartItem.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import { ReactElement, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import {
  applyDiscountAPI,
  getUserCartAPI,
  patchCartAPI,
  removeProductFromCartAPI,
} from "../../services/CartAPI";
import { ICart } from "../../models/cart/Cart.model";
import {
  addProductToCart,
  decreaseProductQuantityInCart,
  removeProductfromCart,
  setUserCart,
} from "../../features/cart/reducers/CartSlice";

import CartItem from "./CartItem";
import CartSummary from "./CartSummary";

import Styles from "./UserCart.module.css";

function UserCart() {
  const [paymentErrorMessage] = useState("");
  const [discountErrorMessage, setDiscountErrorMessage] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const discountCodeCallback = (dataFromChild: string) => {
    setDiscountCode(dataFromChild);
  };

  const userCurrency = useSelector(
    (state: RootState) => state.currencyState.userCurrency
  );

  const userCart = useSelector(
    (state: RootState): ICart => state.cartState.userCart
  );

  const userAccessToken = useSelector(
    (state: RootState) => state.userState.accessToken
  );

  const handleIncreaseProductQuantity = (cartItem: ICartItem) => {
    if (userAccessToken === null || userAccessToken === "") {
      dispatch(
        addProductToCart({
          newProduct: cartItem.product,
          productSelectedProperties: cartItem.selectedProperties,
          quantity: 1,
        })
      );
      return;
    }

    const newCartItem = {
      ...cartItem,
      quantity: cartItem.quantity + 1,
      selectedProperties: cartItem.selectedProperties,
    };

    patchCartAPI(userAccessToken, newCartItem).then((response) => {
      if (response?.ok) {
        response.json().then((item) => {
          dispatch(setUserCart(item));
        });
      }
    });
  };

  const decreaseProductQuantity = (cartItem: ICartItem) => {
    if (cartItem.quantity <= 1) {
      return;
    }

    if (userAccessToken === null || userAccessToken === "") {
      dispatch(decreaseProductQuantityInCart(cartItem.id));
      return;
    }

    const newCartItem = {
      ...cartItem,
      quantity: cartItem.quantity - 1,
    };

    patchCartAPI(userAccessToken, newCartItem).then((response) => {
      if (response?.ok) {
        response.json().then((item) => {
          dispatch(setUserCart(item));
        });
      }
    });
  };

  const handleRemoveProductFromCart = (cartItem: ICartItem) => {
    if (userAccessToken === null || userAccessToken === "") {
      dispatch(removeProductfromCart(cartItem.id));
      return;
    }

    removeProductFromCartAPI(userAccessToken, Number(cartItem.id)).then(
      (response) => {
        if (response?.ok) {
          response.json().then((item) => {
            dispatch(setUserCart(item));
          });
        }
      }
    );
  };

  const handleSubmitDiscount = () => {
    applyDiscountAPI(userAccessToken, discountCode).then((response) => {
      if (response?.ok) {
        setDiscountErrorMessage("");
        getUserCartAPI(userAccessToken).then((userCart: ICart) => {
          dispatch(setUserCart(userCart));
        });
      } else if (response?.status === 400) {
        setDiscountErrorMessage(t(`cartPage.DISCOUNT_CODE_NOT_EXISTS`));
      } else if (response?.status === 403) {
        setDiscountErrorMessage(t(`cartPage.DISCOUNT_CODE_NOT_EXISTS`));
      } else {
        setDiscountErrorMessage("Błąd");
      }
    });
  };

  const renderCartItems = (): ReactElement => {
    return (
      <div className={Styles.cartProductsContainer}>
        {userCart.cartItems.map((item, index) => {
          return (
            <CartItem
              key={index}
              cartItem={item}
              onRemoveProduct={() => handleRemoveProductFromCart(item)}
              onIncreaseProductQuantity={() =>
                handleIncreaseProductQuantity(item)
              }
              onDecreaseProductQuantity={() => decreaseProductQuantity(item)}
              userCurrency={userCurrency}
            />
          );
        })}
      </div>
    );
  };

  const renderPageTitle = (): ReactElement => {
    return (
      <h2 className={Styles.title}>
        {userCart.cartItems === null || userCart.cartItems.length <= 0
          ? t(`cartPage.CART_EMPTY`)
          : t(`cartPage.YOUR_CART`)}
      </h2>
    );
  };

  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <hr className={Styles.divider} />
      {renderPageTitle()}
      {userCart.cartItems !== null && userCart.cartItems.length > 0 && (
        <div className={Styles.cartItemsContainer}>
          {renderCartItems()}
          <CartSummary
            userCart={userCart}
            handleSubmitDiscount={handleSubmitDiscount}
            userAccessToken={userAccessToken}
            discountErrorMessage={discountErrorMessage}
            paymentErrorMessage={paymentErrorMessage}
            onInputDiscountCode={discountCodeCallback}
            userCurrency={userCurrency}
          />
        </div>
      )}
    </motion.div>
  );
}

export default UserCart;
