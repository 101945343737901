export interface IShipmentAddress {
  id: undefined;
  name: String;
  surname: String;
  country: String;
  region: String;
  state: String;
  city: String;
  addressLine_1: String;
  addressLine_2: String | undefined;
  postcode: String;
  areaCode: String;
  phoneNumber: String;
}

export const initialShipmentAddress = {
  id: undefined,
  name: "",
  surname: "",
  country: "",
  region: "",
  state: "",
  city: "",
  addressLine_1: "",
  addressLine_2: "",
  postcode: "",
  areaCode: "",
  phoneNumber: "",
};
