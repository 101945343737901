import { t } from "i18next";
import { EuCountires } from "../constans/EuCountries";
import { IInvoiceAddress } from "../models/address/InvoiceAddress.model";
import { RegionEnum } from "../models/address/Region.model";
import { IShipmentAddress } from "../models/address/ShipmentAddress.model";
import { ICountry } from "../models/country/ICountry.model";
import { countries } from "../constans/Countries";

export function isCoutryRegionPoland(countryName: String): boolean {
  if (countryName?.toUpperCase() === "POLAND") {
    return true;
  } else {
    return false;
  }
}

export function isCoutryRegionSwitzerland(countryName: String): boolean {
  if (countryName?.toUpperCase() === "SWITZERLAND") {
    return true;
  } else {
    return false;
  }
}

export function isCoutryRegionEuropeanUnion(countryName: String): boolean {
  const countryEu = EuCountires?.filter(
    (euCountry) => euCountry?.name?.toUpperCase() === countryName?.toUpperCase()
  );

  if (countryEu.length > 0) {
    return true;
  } else {
    return false;
  }
}

export function isRegionValid(country: ICountry): Boolean {
  return country.region === RegionEnum.POLAND ? true : false;
}

export function translateCoutryNameToEnglish(
  countryName: String
): String | undefined {
  if (countryName === undefined) return;

  const newCountryPL = countries.filter((country) => {
    return country?.polishName === countryName;
  });

  const newCountryEN = countries.filter((country) => {
    return country?.countryName === countryName;
  });

  if (newCountryPL.length > 0) {
    return newCountryPL[0].countryName;
  } else {
    return newCountryEN[0].countryName;
  }
}

export function isRegionValidByName(countryName: String): Boolean {
  const translatedCountryName = translateCoutryNameToEnglish(countryName);

  const newCountry = countries.filter((country) => {
    return country.countryName === translatedCountryName;
  });

  return newCountry[0]?.region === RegionEnum.POLAND ? true : false;
}

export function isShipmentAddressValid(
  shipmentAddress: IShipmentAddress | undefined
): String | null {
  if (shipmentAddress === undefined)
    return t(`deliveryAndPaymentAndPage.FILL_OUT_ALL_FIELDS`);

  if (!validatePhoneNumber(shipmentAddress.phoneNumber)) {
    return t(`deliveryAndPaymentAndPage.REGEX_PHONE_INFO`);
  }

  for (const [k, v] of Object.entries(shipmentAddress)) {
    if (v !== null && k !== "addressLine_2" && k !== "id") {
      if (k === "areaCode" && v.length > 1) {
        continue;
      }
      if (v.length < 3) {
        return t(`deliveryAndPaymentAndPage.FILL_OUT_ALL_FIELDS`);
      }
    }
  }

  return null;
}

export function isInvoiceAddressValid(
  invoiceAddress: IInvoiceAddress | null
): String | null {
  if (invoiceAddress === null)
    return t(`deliveryAndPaymentAndPage.FILL_OUT_ALL_FIELDS`);

  if (!validatePhoneNumber(invoiceAddress.phoneNumber)) {
    return t(`deliveryAndPaymentAndPage.REGEX_PHONE_INFO`);
  }

  for (const [k, v] of Object.entries(invoiceAddress)) {
    if (v !== null && k !== "addressLine_2" && k !== "id") {
      if (v.length < 3) {
        return t(`deliveryAndPaymentAndPage.FILL_OUT_ALL_FIELDS`);
      }
    }
  }

  return null;
}

export function getCountry(countryName: String | undefined): ICountry | null {
  if (countryName === undefined) return null;

  const countryNameEng = translateCoutryNameToEnglish(countryName);

  const country = countries.filter((country) => {
    return country?.countryName === countryNameEng;
  });

  return country[0];
}

export function validatePhoneNumber(phoneNumber: String) {
  if (phoneNumber === undefined) return false;
  const validRegex = /^[0-9]*$/;
  const result = phoneNumber.toString().match(validRegex);

  if (result === null) {
    return false;
  } else {
    return true;
  }
}
