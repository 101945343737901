import { useTranslation } from "react-i18next";
import Styles from "./InerciseBlocks.module.css";

interface InerciseBlockProps {
  title: string;
  text: string;
  imageURL: string;
  textSide: "left" | "right";
}

function InerciseBlock(inerciseBlockProps: InerciseBlockProps) {
  const { t } = useTranslation();

  if (
    inerciseBlockProps.textSide === "left" ||
    window.matchMedia("(max-width: 767px)").matches
  ) {
    return (
      <div className={Styles.container}>
        <div className={Styles.imageContainerStart}>
          <img
            className={Styles.image}
            src={require(`../../assets/images/${inerciseBlockProps.imageURL}`)}
            alt="Inercise Advantages"
          />
        </div>

        <div>
          <hr className={Styles.divider} />
          <h2 className={Styles.title}>
            {t(
              `discoverInercisePage.advantageBlock.${inerciseBlockProps.title}`
            )}
          </h2>
          <p className={Styles.description}>
            {" "}
            {t(
              `discoverInercisePage.advantageBlock.${inerciseBlockProps.text}`
            )}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={Styles.container}>
        <div className={Styles.descriptionContainer}>
          <hr className={Styles.divider} />
          <h2 className={Styles.title}>
            {" "}
            {t(
              `discoverInercisePage.advantageBlock.${inerciseBlockProps.title}`
            )}
          </h2>
          <p className={Styles.description}>
            {" "}
            {t(
              `discoverInercisePage.advantageBlock.${inerciseBlockProps.text}`
            )}
          </p>
        </div>
        <div className={Styles.imageContainerEnd}>
          <img
            className={Styles.image}
            src={require(`../../assets/images/${inerciseBlockProps.imageURL}`)}
            alt="Inercise Advantages"
          />
        </div>
      </div>
    );
  }
}

export default InerciseBlock;
