import { motion } from "framer-motion";
import Styles from "./PrivacyPolicy.module.css";

function PrivacyPolicy() {
  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <hr className={Styles.sectionDivider} />
      <h2 className={Styles.title}>Polityka Prywatności</h2>
      <hr className={Styles.titleDivider} />
      <p className={Styles.desc}>
        Niniejszy dokument określa zasady Polityki prywatności w serwisie
        internetowym www.trainport.team (dalej: Serwis internetowy).
        Administratorem Serwisu internetowego jest TRAINPORT Spółka z
        ograniczoną odpowiedzialnością z siedzibą w Łodzi (kod pocztowy: 90-765)
        przy ul. Zielonej 77/15, wpisana do Rejestru Przedsiębiorców Krajowego
        Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Łodzi-Śródmieścia
        w Łodzi XX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem:
        0000965737, NIP: 7272857698, o kapitale zakładowym w wysokości 10.000 zł
        (dalej: Administrator).
      </p>
      <br />
      <p className={Styles.desc}>
        Wyrazy użyte z dużej litery mają znaczenie nadane im w regulaminie
        Serwisu internetowego. Dane osobowe są przetwarzane przez Administratora
        zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679
        z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
        przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
        danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
        ochronie danych) (dalej: Rozporządzenie).
      </p>
      <br />
      <p className={Styles.desc}>
        Traktując z wszelką starannością powierzone dane osobowe, Administrator
        – na podstawie art. 13 ust. 1 i 2 Rozporządzenia informuje, że:
      </p>
      <br />
      <ol className={Styles.desc}>
        <li>
          Administratorem danych osobowych pozyskanych w związku z działaniem
          Serwisu internetowego, w tym na skutek zarejestrowania się w Serwisie
          lub w ramach zawarcia umowy cywilnoprawnej jest TRAINPORT Spółka z
          ograniczoną odpowiedzialnością z siedzibą w Łodzi (kod pocztowy:
          90-765) przy ul. Zielonej 77/15, wpisana do Rejestru Przedsiębiorców
          Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla
          Łodzi-Śródmieścia w Łodzi XX Wydział Gospodarczy Krajowego Rejestru
          Sądowego pod numerem: 0000965737, NIP: 7272857698, o kapitale
          zakładowym w wysokości 10.000 zł, e-mail: kontakt@trainport.team.
        </li>
        <li>
          Administrator jako administrator danych osobowych przetwarza dane
          osobowe na podstawie zawartej umowy lub na podstawie zgody wyrażonej
          wraz z dokonaniem przez Klienta rejestracji w Serwisie internetowym –
          w celu wypełnienia obowiązków prawnych ciążących na Administratorze
          oraz w celu realizacji: czynności administracyjnych związanych z
          przyjęciem i realizacją zamówienia, postanowień zawartej umowy,
          ewidencji danych osobowych, ich archiwizacji, rozliczenia finansowego,
          rozpatrywania reklamacji, ewentualnego dochodzenia roszczeń, kontroli
          jakości usług, marketingu usług własnych.
        </li>
        <li>
          Administrator przetwarza następujące kategorie odnośnych danych
          osobowych:
          <ol type="a">
            <li>dane dotyczące umowy; </li>
            <li>dane kontaktowe; </li>
            <li>dane dotyczące aktywności w Serwisie internetowym;</li>
            <li>dane dotyczące reklamacji skarg i wniosków; </li>
            <li>dane dotyczące usług marketingowych.</li>
          </ol>
        </li>
        <li>
          Odbiorcą danych osobowych będzie Administrator. Dane osobowe mogą
          zostać udostępnione:
          <ol type="a">
            <li>
              innym podmiotom przetwarzającym dane osobowe na zlecenie
              Administratora ochrony danych i zgodnie z poleceniami
              Administratora, takim jak: podmioty realizujące dostawy produktów
              Administratora; pomioty współpracujące z Administratora w zakresie
              przedmiotu jego działalności; dostawcy usług informatycznych z
              siedzibą w Polsce; podmioty zajmujące się archiwizacją
              dokumentacji; podmioty przetwarzające dane w celu windykacji
              należności; kancelarie prawne; agencje marketingowe; biura
              rachunkowe;
            </li>
            <li>
              innym podmiotom przetwarzającym dane osobowe w sytuacji, gdy
              obowiązek ich udostępnienia będzie wynikał z obowiązujących
              przepisów prawnych.
            </li>
          </ol>
        </li>
        <li>
          Dane osobowe będą przetwarzane przez okres niezbędny do realizacji
          celów określonych w pkt. 2, z tym że w przypadku realizacji:
          <ol type="a">
            <li>
              czynności administracyjnych związanych z przyjęciem i realizacją
              umowy – przez okres realizacji umowy,
            </li>
            <li>
              postanowień zawartej umowy – do czasu upływu okresów przedawnienia
              roszczeń;
            </li>
            <li>
              rozliczenia finansowego – przez 10 lat od początku roku
              następującego po roku obrotowym, w którym nastąpiła płatność
              związana ze zrealizowaną Umową lub w którym płatność ta została
              rozliczona lub przedawniona;
            </li>
            <li>
              ewentualnego dochodzenia roszczeń – do czasu upływu okresów
              przedawnienia roszczeń;
            </li>
            <li>
              kontroli jakości usług lub marketingu usług własnych – do czasu
              cofnięcia zgody.
            </li>
          </ol>
        </li>
        <li>
          Klientowi przysługuje prawo do żądania:{" "}
          <ol type="a">
            <li>
              dostępu do swoich danych; realizując to prawo Klient jest
              uprawniony do uzyskania od Administratora potwierdzenia, czy
              przetwarzane są jego dane osobowe; uzyskania ich kopii oraz do
              uzyskania informacji m.in. o: celach przetwarzania, kategoriach
              danych osobowych, kategoriach odbiorców, którym dane osobowe
              zostały lub zostaną ujawnione, planowanym okresie przechowywania
              danych osobowych, źródle ich pozyskania przez Administratora.{" "}
            </li>
            <li>
              sprostowania jego danych; realizując to prawo Klient jest
              uprawniony do żądania od Administratora sprostowania lub
              uzupełnienia dotyczących go danych osobowych, które są
              nieprawidłowe lub niekompletne.{" "}
            </li>
            <li>
              usunięcia jego danych; realizując to prawo Klient jest uprawniony
              do żądania od Administratora usunięcia dotyczących go danych
              osobowych, w przypadkach wskazanych w art. 17 Rozporządzenia, w
              szczególności w przypadku, gdy dane osobowe nie są już niezbędne
              do celów, w których zostały zebrane lub złożony został skuteczny
              sprzeciw wobec przetwarzania danych osobowych.{" "}
            </li>
            <li>
              ograniczenia przetwarzania jego danych; jeśli Klient ma
              zastrzeżenia co do prawidłowości jego danych; jeśli uważa, że
              Administrator nie powinien przetwarzać jego danych, ale
              jednocześnie nie chce, aby je usunął; jeśli takie dane osobowe nie
              są już potrzebne Administratorowi, natomiast Klient potrzebuje ich
              w związku z dochodzeniem roszczeń; jeśli Klient wyraził sprzeciw
              wobec przetwarzania jego danych, a obowiązkiem Administratora jest
              sprawdzenie, czy powinien je dalej przetwarzać.{" "}
            </li>
            <li>
              wniesienia sprzeciwu z przyczyn związanych ze szczególną sytuacją
              wobec przetwarzania danych osobowych na podstawie prawnie
              uzasadnionych interesów realizowanych przez Administratora, oraz
              do wniesienia sprzeciwu wobec przetwarzania danych osobowych na
              potrzeby marketingu bezpośredniego przez Administratora;
              realizując to prawo Klient jest uprawniony do wniesienia, z
              przyczyn związanych ze szczególną sytuacją, sprzeciwu wobec
              przetwarzania danych osobowych Klient na podstawie prawnie
              uzasadnionych interesów realizowanych przez Administratora. Jednak
              pomimo sprzeciwu, Administrator jest uprawniony w dalszym ciągu
              przetwarzać te dane osobowe, jeżeli wykaże istnienie ważnych,
              prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec
              interesów, praw i wolności osoby, której dane dotyczą, lub podstaw
              do ustalenia, dochodzenia lub obrony roszczeń.{" "}
            </li>
            <li>
              cofnięcia zgody w dowolnym momencie. Cofniecie zgody pozostaje bez
              wpływu na zgodność z prawem przetwarzania, którego dokonano na
              podstawie zgody przed jej cofnięciem.{" "}
            </li>
            <li>
              wniesienia skargi do Organu Nadzoru w zakresie danych osobowych –
              Prezesa Urzędu Ochrony Danych Osobowych.
            </li>
          </ol>
        </li>
        <li>
          Podanie danych osobowych jest obowiązkowe, jako że stanowi wymóg
          umowny i warunek zawarcia umowy. Niepodanie danych osobowych
          uniemożliwia zawarcie umowy.{" "}
        </li>
        <li>
          Podane dane osobowe nie będą przetwarzane w sposób zautomatyzowany.
        </li>
      </ol>
    </motion.div>
  );
}

export default PrivacyPolicy;
