import Styles from "./Navbar.module.css";
import { navbarItems } from "../../constans/NavbarItems";
import TextLink from "./TextLink";
import IconLink from "./IconLink";

import CancelIcon from "../../assets/icons/cancel-in-circle.svg";
import ShoppingBagIcon from "../../assets/icons/shoppingbag.svg";
import LoginIcon from "../../assets/icons/user.svg";
import HamburgerIcon from "../../assets/icons/hamburger.svg";
import { useRef, useState } from "react";
import CartInfo from "./CartInfo";
import UserInfo from "./UserInfo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import { Link } from "react-router-dom";
import UserProfileIcon from "./UserProfileIcon";
import { getItemsCountInCart } from "../../utils/CartHelpers";
import Button from "../Button";
import { logout } from "../../features/user/reducers/UserSlice";
import { clearCart } from "../../features/cart/reducers/CartSlice";
import React from "react";
import LanguageSwitchButton from "./LanguageSwitchButton";
import CurrencySwitchButton from "./CurrencySwitchButton";

interface NavbarProps {
  onInputChnage: (arg: boolean) => void;
}

function Navbar(props: NavbarProps) {
  const dispatch = useDispatch();
  const [isUserInfoHovered, setIsUserInfoHovered] = useState(false);
  const [isCartHovered, setIsCartHovered] = useState(false);

  const userCart = useSelector((state: RootState) => state.cartState.userCart);
  const userAccessToken = useSelector(
    (state: RootState) => state.userState.accessToken
  );
  const dialogRef = useRef<HTMLDialogElement>(null);

  const handleShowNavbar = () => {
    if (window.matchMedia("(max-width: 1380px)").matches) {
      if (dialogRef.current?.open) {
        dialogRef.current?.close();
        props.onInputChnage(false);
      } else if (dialogRef.current?.close) {
        dialogRef.current?.showModal();
        setTimeout(() => {
          props.onInputChnage(true);
        }, 300);
      }
    } else {
      props.onInputChnage(false);
    }
  };

  const handleMouseOverUserInfo = () => {
    setIsUserInfoHovered(true);
  };

  const handleMouseOutUserInfo = () => {
    setIsUserInfoHovered(false);
  };

  const handleMouseOverCartInfo = () => {
    setIsCartHovered(true);
  };

  const handleMouseOutCartInfo = () => {
    setIsCartHovered(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
  };

  const mobileMenu = () => {
    return (
      <dialog className={Styles.mobileMenuContainer} ref={dialogRef}>
        <img
          className={Styles.cancelIcon}
          src={CancelIcon}
          alt="Cancel Icon"
          onClick={handleShowNavbar}
        />
        <ul className={Styles.menuMobileList}>
          {navbarItems.map((item) => {
            return (
              <li className={Styles.textLink} key={item.id}>
                <TextLink
                  id={item.id}
                  title={item.title}
                  url={item.url}
                  submenu={item.submenu}
                  onClick={handleShowNavbar}
                />
              </li>
            );
          })}
        </ul>
        <hr className={Styles.mobileSectionDivider} />
        {userAccessToken !== null ? (
          <React.Fragment>
            <h3 className={Styles.title}>Cześć</h3>
            <Button
              className={`${Styles.button} ${Styles.marginButton}`}
              linkUrl="/user-order"
              text="Moje Zamówienia"
              onClick={handleShowNavbar}
            />
            <Button
              className={Styles.button}
              text="Zmień Hasło"
              linkUrl="/"
              onClick={handleShowNavbar}
            />
            <hr className={Styles.divider} />
            <Button
              className={Styles.button}
              text="Wyloguj"
              linkUrl=""
              onClick={handleLogout}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              className={Styles.loginBtn}
              linkUrl="/login"
              text="Zaloguj się"
              onClick={handleShowNavbar}
            />
            <p className={Styles.text}>Nie masz jeszcze konta?</p>
            <Button
              className={Styles.buttonCreateAccount}
              linkUrl="/register-user"
              text="Dołącz do nas!"
              onClick={handleShowNavbar}
            />
          </React.Fragment>
        )}
      </dialog>
    );
  };

  const desktopMenu = () => {
    return (
      <ul className={Styles.textLinkList}>
        {navbarItems.map((item) => {
          return (
            <li className={Styles.textLink} key={item.id}>
              <TextLink
                id={item.id}
                title={item.title}
                url={item.url}
                submenu={item.submenu}
                onClick={handleShowNavbar}
              />
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <nav className={Styles.nav}>
      {desktopMenu()}
      {mobileMenu()}
      <ul className={Styles.iconLinkList}>
        <li className={Styles.iconLink}>
          <div className={Styles.hamburger} onClick={handleShowNavbar}>
            <img src={HamburgerIcon} alt="Hamburger Icon" />
          </div>
        </li>
        <li
          className={`${Styles.iconLink} ${Styles.userIcon}`}
          onMouseOver={handleMouseOverUserInfo}
          onMouseOut={handleMouseOutUserInfo}
        >
          {userAccessToken === "" || userAccessToken === null ? (
            <IconLink iconUrl={LoginIcon} linkUrl="/login" />
          ) : (
            <UserProfileIcon />
          )}
          {isUserInfoHovered && <UserInfo visibile={true} />}
        </li>
        <li
          className={Styles.iconLink}
          onMouseOver={handleMouseOverCartInfo}
          onMouseOut={handleMouseOutCartInfo}
        >
          <IconLink iconUrl={ShoppingBagIcon} linkUrl="/cart" />
          <div className={Styles.cart}>
            <Link to="/cart">
              <div className={Styles.itemsCounter}>
                {userCart.cartItems === undefined
                  ? "0"
                  : getItemsCountInCart(userCart.cartItems)}
              </div>
            </Link>
          </div>
          {isCartHovered && <CartInfo visibile={true} />}
        </li>
        <li className={Styles.iconLink}>
          <LanguageSwitchButton />
        </li>
        <li className={Styles.iconLink}>
          <CurrencySwitchButton />
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
