const TRAINPORT_BACKEND_URL: string = `${process.env.REACT_APP_TRAINPORT_BACKEND_API_URL}`;

export async function fetchData(url: string, options?: RequestInit) {
  try {
    const response = fetch(TRAINPORT_BACKEND_URL + url, options);
    return response;
  } catch (error) {
    console.error(error);
    throw new Error("Somethin went wrong");
  }
}

export async function fetchExternalData(url: string, options?: RequestInit) {
  try {
    const response = fetch(url, options);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchPrivateData(
  url: string,
  token: string | null,
  options?: RequestInit
) {
  try {
    const headers = {
      Authorization: "Bearer " + token,
      ...options?.headers, // Merge existing headers if present in options
    };

    const response = await fetch(TRAINPORT_BACKEND_URL + url, {
      ...options,
      headers: headers,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
}

export function getThumbnailUrl(name: string) {
  return TRAINPORT_BACKEND_URL + "/thumbnail/" + name;
}
