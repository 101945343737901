import Styles from "./Order.module.css";
import Button from "../../components/Button";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

function Order() {
  const { t } = useTranslation();

  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <div className={Styles.container}>
        <h2 className={Styles.title}>{t(`orderPage.WELCOME`)}</h2>
        <p className={Styles.info}>{t(`orderPage.INFO`)}</p>
        <Button
          linkUrl="/"
          text={t(`orderPage.GO_TO_HOMEPAGE`)}
          className={Styles.button}
        />
      </div>
    </motion.div>
  );
}

export default Order;
