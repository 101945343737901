import Styles from "./CopyrightNotice.module.css";
import Blik from "../../assets/images/blik.png";
import { useTranslation } from "react-i18next";

function CopyrightNotice() {
  const { t } = useTranslation();
  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <div className={Styles.paymentMethodsContainer}>
          <p className={Styles.paymentMethodsText}>
            {t(`footer.PAYMENT_METHODS`)}:
          </p>
          <img
            className={`${Styles.paymentMethodImg} ${Styles.blik}`}
            width="70"
            height="auto"
            src={Blik}
            alt="Blik"
          />
          <img
            className={Styles.paymentMethodImg}
            width="100"
            height="auto"
            src="https://tpay.com/img/banners/tpay-partner-300x69.svg"
            alt="Raty"
          />
        </div>
        <p className={Styles.copyrightText}>
          &#169; Trainport. All right reserved. Designed by Indivisual.
        </p>
      </div>
    </div>
  );
}
export default CopyrightNotice;
