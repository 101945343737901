import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import pl from "./assets/locales/pl/translations.json";
import en from "./assets/locales/en/translations.json";

const resources = {
  en: { translation: en },
  pl: { translation: pl },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "pl"],
    detection: { order: ["path", "navigator"] },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources,
    debug: false,
    returnEmptyString: true,
  });

export default i18n;
