import { useTranslation } from "react-i18next";
import Styles from "./Contact.module.css";

function Contact() {
  const { t } = useTranslation();

  return (
    <div className={Styles.container}>
      <h4 className={Styles.title}>{t(`footer.CONTACT`)}</h4>
      <p className={Styles.address}>
        Zielona 77/15 <br />
        90-765 {t(`footer.LOCATION_LODZ`)} <br />
        kontakt@trainport.team
      </p>
    </div>
  );
}
export default Contact;
