import Styles from "./FeatureSection.module.css";

import Application from "../../assets/images/excerciseAtlas-application-section.png";
import AppleStore from "../../assets/images/apple-store.png";
import GooglePlay from "../../assets/images/google-play.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function FeatureSection() {
  const { t } = useTranslation();

  return (
    <div className={Styles.container}>
      <div className={Styles.overlay}>
        <hr className={Styles.sectionDivider} />
        <div className={Styles.contentContainer}>
          <img className={Styles.appImg} src={Application} alt="Application" />
          <div className={Styles.textContainer}>
            <h2 className={Styles.title}>
              {t(`applicationPage.featureSection.TITLE`)}
            </h2>
            <hr className={Styles.titleDivider} />
            <p className={Styles.desc}>
              {t(`applicationPage.featureSection.DESC`)}
            </p>
            <div className={Styles.shopContainer}>
              <p className={Styles.textAvailbility}>
                {t(`applicationPage.featureSection.INFO`)}{" "}
              </p>
              <Link
                className={Styles.link}
                to="https://apps.apple.com/pl/app/apple-store/id6471336833"
                target="_blank"
              >
                <img
                  className={Styles.storeLogo}
                  src={AppleStore}
                  alt="Apple Store"
                />
              </Link>
              <Link
                className={Styles.link}
                to="https://play.google.com/store/apps/details?id=com.trainport.TrainPortApp"
                target="_blank"
              >
                <img
                  className={Styles.storeLogo}
                  src={GooglePlay}
                  alt="GooglePlay"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureSection;
