import CopyrightNotice from "./CopyrightNotice";
import Sitemap from "./Sitemap";

import Styles from "./Footer.module.css";
import { motion } from "framer-motion";

function Footer() {
  return (
    <motion.footer
      className={Styles.footer}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <Sitemap />
      <CopyrightNotice />
    </motion.footer>
  );
}

export default Footer;
