import { fetchData } from "../utils/API";

const paths = {
  registerNewsletter: "/newsletter/register",
};

export async function registerUserInNewsletterAPI(emailAddress: string) {
  const response = await fetchData(paths.registerNewsletter, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      emailAddress: emailAddress,
      captchaResponseToken: "test",
    }),
  });

  return response;
}
