import Styles from "./ProductCard.module.css";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllProducts, getProductById } from "../../services/ProductAPI";
import { Product } from "../../models/product/Product.model";
import ProductBlock from "../../components/ProductBlock";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import "./ProductCard.css";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToCart,
  setUserCart,
} from "../../features/cart/reducers/CartSlice";
import { RootState } from "../../app/Store";
import { addProductToCartAPI } from "../../services/CartAPI";
import { AnimatePresence, motion } from "framer-motion";
import { ICart } from "../../models/cart/Cart.model";
import { ProductProperty } from "../../models/product/ProductProperty.model";
import i18n from "../../i18n";
import {
  getProductPreviousPrice,
  getProductPrice,
} from "../../utils/ProductHelpers";

const thumbnailAPI: string = `${process.env.REACT_APP_TRAINPORT_BACKEND_API_URL}/thumbnail/`;
const imageAPI: string = `${process.env.REACT_APP_TRAINPORT_BACKEND_API_URL}/image/`;

interface ProductImage {
  original: string;
  thumbnail: string;
}

interface IProductProperties {
  name: string;
  values: {
    id: string;
    value: string;
    available: boolean;
    daysToShip: string;
  }[];
}

interface ISelectedProperties {
  id: string;
  value: string | null;
  available: boolean;
  daysToShip: string;
}

const productOrderByProductType = [
  "INERCISE",
  "COURSE",
  "HANDLE",
  "INERCISE_ACCESSORY",
];

function ProductCard() {
  const [searchparams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [product, setProduct] = useState<Product>();
  const [products, setProducts] = useState<Product[]>([]);
  const [productsWithoutCurrent, setProductsWithoutCurrent] = useState<
    Product[]
  >([]);
  const [productImages, setProductImages] = useState<ProductImage[]>([]);
  const [productPropertyTypes, setProductPropertyTypes] = useState<string[]>(
    []
  );
  const [productProperties, setProductProperties] = useState<
    IProductProperties[]
  >([]);
  const [selectedColor, setSelectedColor] = useState<ISelectedProperties>();
  const [selectedSize, setSelectedSize] = useState<ISelectedProperties>();
  const [selectedInerciseSetColor, setSelectedInerciseSetColor] =
    useState<ISelectedProperties>();
  const [selectedSetBeltSize, setSelectedSetBeltSize] =
    useState<ISelectedProperties>();
  const [selectedInderdyskSize, setSelectedInerdyskSize] =
    useState<ISelectedProperties>();
  const [selectedGeneric, setSelectedGeneric] = useState<ISelectedProperties>();
  const [
    selectedInderdyskOneSizeInerciseSet,
    setSelectedInerdyskOneSizeInerciseSet,
  ] = useState<ISelectedProperties>();
  const [
    selectedInderdyskTwoSizeInerciseSet,
    setSelectedInerdyskTwoSizeInerciseSet,
  ] = useState<ISelectedProperties>();

  const userAccessToken = useSelector(
    (state: RootState) => state.userState.accessToken
  );

  const userCurrency = useSelector(
    (state: RootState) => state.currencyState.userCurrency
  );

  useEffect(() => {
    const productId = searchparams.get("id");
    getProductById(productId).then((item) => {
      setProduct(item);
    });
  }, [searchparams]);

  useEffect(() => {
    getAllProducts().then((item) => {
      const productsSortedByProductType = [...item].sort((a, b) => {
        var A = a.productType.name,
          B = b.productType.name;

        if (
          productOrderByProductType.indexOf(A) >
          productOrderByProductType.indexOf(B)
        ) {
          return 1;
        } else {
          return -1;
        }
      });
      setProducts(productsSortedByProductType);
    });
  }, [product]);

  useEffect(() => {
    const newProducts = products.filter((item) => item.name !== product?.name);
    setProductsWithoutCurrent(newProducts);
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    let images: Array<ProductImage> = [];
    product?.productImages.map((item) => {
      const original = imageAPI + item.image.name;
      const thumbnail = thumbnailAPI + item.thumbnail.name;

      const media: ProductImage = { original, thumbnail };
      return images.push(media);
    });

    setProductImages(images);
  }, [product]);

  useEffect(() => {
    let productPropertyTypes: string[] = new Array<string>();
    // eslint-disable-next-line
    product?.properties.map((productProperty) => {
      if (
        productPropertyTypes.indexOf(productProperty.propertyType.name) === -1
      ) {
        productPropertyTypes.push(productProperty.propertyType.name);
      }
    });
    setProductPropertyTypes(productPropertyTypes);
  }, [product]);

  useEffect(() => {
    let newProductProperties: IProductProperties[] =
      new Array<IProductProperties>();

    // eslint-disable-next-line
    productPropertyTypes.map((productProperty) => {
      const productsSpecificProperties = product?.properties.filter(
        (property) => property.propertyType.name === productProperty
      );

      const productPropertyValues = productsSpecificProperties?.map(
        (property) => {
          let prop = {
            id: property.id,
            value: property.value,
            available: property.available,
            daysToShip: property.daysToShip,
          };
          return prop;
        }
      );

      if (productPropertyValues !== undefined) {
        const properties: IProductProperties = {
          name: productProperty,
          values: productPropertyValues,
        };
        newProductProperties.push(properties);
      }
    });

    setProductProperties(newProductProperties);
    // eslint-disable-next-line
  }, [productPropertyTypes]);

  useEffect(() => {
    const colorProperty = productProperties.filter(
      (property) => property.name === "COLOR"
    );
    const sizeProperty = productProperties.filter(
      (property) => property.name === "SIZE"
    );
    const colorSetProperty = productProperties.filter(
      (property) => property.name === "COLOR_INERCISE_SET"
    );
    const sizeBeltProperty = productProperties.filter(
      (property) => property.name === "BELT_SIZE_INERCISE_SET"
    );
    const inerdyskSizeProperty = productProperties.filter(
      (property) => property.name === "INERDYSK_SIZE"
    );
    const inerdyskOneSizeInerciseSetProperty = productProperties.filter(
      (property) => property.name === "INERDYSK_ONE_SIZE_INERCISE_SET"
    );
    const inerdyskTwoSizeInerciseSetProperty = productProperties.filter(
      (property) => property.name === "INERDYSK_TWO_SIZE_INERCISE_SET"
    );
    const genericProperty = productProperties.filter(
      (property) => property.name === "GENERIC"
    );

    let colorDefault;
    if (selectedColor === undefined) {
      colorDefault = colorProperty[0]?.values.find(
        (property) => property.available
      );
      setSelectedColor(colorDefault);
    }

    if (selectedSize === undefined) {
      const sizeDefault = sizeProperty[0]?.values.find(
        (property) => property.available
      );
      if (sizeDefault !== undefined) {
        setSelectedSize(sizeDefault);
      }
    }

    let colorSetDefault;
    if (selectedInerciseSetColor === undefined) {
      colorSetDefault = colorSetProperty[0]?.values.find(
        (property) => property.available
      );
      setSelectedInerciseSetColor(colorSetDefault);
    }

    if (selectedSetBeltSize === undefined) {
      const sizeSetBeltDefault = sizeBeltProperty[0]?.values.find(
        (property) => property.available
      );
      if (sizeSetBeltDefault !== undefined) {
        setSelectedSetBeltSize(sizeSetBeltDefault);
      }
    }

    if (selectedInderdyskSize === undefined) {
      const inerdyskSizeDefault = inerdyskSizeProperty[0]?.values.find(
        (property) => property.available
      );
      if (inerdyskSizeDefault !== undefined) {
        setSelectedInerdyskSize(inerdyskSizeDefault);
      }
    }

    if (selectedInderdyskOneSizeInerciseSet === undefined) {
      const inerdyskSizeInerciseSetDefault =
        inerdyskOneSizeInerciseSetProperty[0]?.values.find(
          (property) => property.available
        );
      if (inerdyskSizeInerciseSetDefault !== undefined) {
        setSelectedInerdyskOneSizeInerciseSet(inerdyskSizeInerciseSetDefault);
      }
    }

    if (selectedInderdyskTwoSizeInerciseSet === undefined) {
      const inerdyskSizeInerciseSetDefault =
        inerdyskTwoSizeInerciseSetProperty[0]?.values.find(
          (property) => property.available
        );
      if (inerdyskSizeInerciseSetDefault !== undefined) {
        setSelectedInerdyskTwoSizeInerciseSet(inerdyskSizeInerciseSetDefault);
      }
    }

    if (selectedGeneric === undefined) {
      const genericDefault = genericProperty[0]?.values.find(
        (property) => property.available
      );
      if (genericProperty !== undefined) {
        setSelectedGeneric(genericDefault);
      }
    }
    // eslint-disable-next-line
  }, [productProperties]);

  const isProductAvailable = (): boolean => {
    if (
      selectedColor === undefined &&
      selectedInderdyskSize === undefined &&
      selectedSize === undefined &&
      selectedInderdyskOneSizeInerciseSet === undefined &&
      selectedInderdyskTwoSizeInerciseSet === undefined &&
      selectedSetBeltSize === undefined &&
      selectedInerciseSetColor === undefined &&
      selectedGeneric === undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleAddProductToCart = () => {
    if (product === undefined) {
      return;
    }
    let selectedPropperties: ProductProperty[] = [];
    const colorPropertyId = selectedColor?.id;
    const sizePropertyId = selectedSize?.id;
    const colorSetPropertyId = selectedInerciseSetColor?.id;
    const sizeSetBeltPropertyId = selectedSize?.id;
    const sizeInerdyskPropertyId = selectedSetBeltSize?.id;
    const sizeInerdyskOneInerciseSetPropertyId =
      selectedInderdyskOneSizeInerciseSet?.id;
    const sizeInerdyskTwoInerciseSetPropertyId =
      selectedInderdyskTwoSizeInerciseSet?.id;

    const colorProperty = product?.properties.filter(
      (property) => String(property.id) === String(colorPropertyId)
    );

    const sizeProperty = product?.properties.filter(
      (property) => String(property.id) === String(sizePropertyId)
    );

    const colorSetProperty = product?.properties.filter(
      (property) => String(property.id) === String(colorSetPropertyId)
    );

    const sizeSetBeltProperty = product?.properties.filter(
      (property) => String(property.id) === String(sizeSetBeltPropertyId)
    );

    const sizeInerdyskProperty = product?.properties.filter(
      (property) => String(property.id) === String(sizeInerdyskPropertyId)
    );

    const sizeInerdyskOneInerciseSetProperty = product?.properties.filter(
      (property) =>
        String(property.id) === String(sizeInerdyskOneInerciseSetPropertyId)
    );

    const sizeInerdyskTwoInerciseSetProperty = product?.properties.filter(
      (property) =>
        String(property.id) === String(sizeInerdyskTwoInerciseSetPropertyId)
    );

    if (colorProperty !== undefined && colorProperty.length > 0) {
      selectedPropperties.push(colorProperty[0]);
    }

    if (sizeProperty !== undefined && sizeProperty.length > 0) {
      selectedPropperties.push(sizeProperty[0]);
    }

    if (colorSetProperty !== undefined && colorSetProperty.length > 0) {
      selectedPropperties.push(colorSetProperty[0]);
    }

    if (sizeSetBeltProperty !== undefined && sizeSetBeltProperty.length > 0) {
      selectedPropperties.push(sizeSetBeltProperty[0]);
    }

    if (sizeInerdyskProperty !== undefined && sizeInerdyskProperty.length > 0) {
      selectedPropperties.push(sizeInerdyskProperty[0]);
    }

    if (
      sizeInerdyskOneInerciseSetProperty !== undefined &&
      sizeInerdyskOneInerciseSetProperty.length > 0
    ) {
      selectedPropperties.push(sizeInerdyskOneInerciseSetProperty[0]);
    }

    if (
      sizeInerdyskTwoInerciseSetProperty !== undefined &&
      sizeInerdyskTwoInerciseSetProperty.length > 0
    ) {
      selectedPropperties.push(sizeInerdyskTwoInerciseSetProperty[0]);
    }

    if (userAccessToken !== null && userAccessToken !== undefined) {
      if (product?.id !== undefined) {
        addProductToCartAPI(
          userAccessToken,
          product.id,
          1,
          selectedPropperties
        ).then((response) => {
          if (response?.ok) {
            response.json().then((item: ICart) => {
              dispatch(setUserCart(item));
            });
          } else {
            return;
          }
        });
      }
    } else {
      dispatch(
        addProductToCart({
          newProduct: product,
          productSelectedProperties: selectedPropperties,
          quantity: 1,
        })
      );
    }
  };

  const handleSelectColor = (id: string) => {
    let property;
    // eslint-disable-next-line
    productProperties.filter((productProperty) => {
      if (productProperty.name === "COLOR") {
        property = productProperty.values.filter(
          (value) => String(value.id) === String(id)
        );
      }
    });

    if (property !== undefined) {
      const newSelectedProperty: ISelectedProperties = property["0"];
      setSelectedColor(newSelectedProperty);
    }
  };

  const handleSelectSize = (id: string) => {
    let property;
    // eslint-disable-next-line
    productProperties.filter((productProperty) => {
      if (productProperty.name === "SIZE") {
        property = productProperty.values.filter(
          (value) => String(value.id) === String(id)
        );
      }
    });

    if (property !== undefined) {
      const newSelectedProperty: ISelectedProperties = property["0"];
      setSelectedSize(newSelectedProperty);
    }
  };

  const handleSelectSetColor = (id: string) => {
    let property;
    // eslint-disable-next-line
    productProperties.filter((productProperty) => {
      if (productProperty.name === "COLOR_INERCISE_SET") {
        property = productProperty.values.filter(
          (value) => String(value.id) === String(id)
        );
      }
    });

    if (property !== undefined) {
      const newSelectedProperty: ISelectedProperties = property["0"];
      setSelectedInerciseSetColor(newSelectedProperty);
    }
  };

  const handleSelectSetSize = (id: string) => {
    let property;
    // eslint-disable-next-line
    productProperties.filter((productProperty) => {
      if (productProperty.name === "BELT_SIZE_INERCISE_SET") {
        property = productProperty.values.filter(
          (value) => String(value.id) === String(id)
        );
      }
    });

    if (property !== undefined) {
      const newSelectedProperty: ISelectedProperties = property["0"];
      setSelectedSetBeltSize(newSelectedProperty);
    }
  };

  const handleSelectInerdyskSize = (id: string) => {
    let property;
    // eslint-disable-next-line
    productProperties.filter((productProperty) => {
      if (productProperty.name === "INERDYSK_SIZE") {
        property = productProperty.values.filter(
          (value) => String(value.id) === String(id)
        );
      }
    });

    if (property !== undefined) {
      const newSelectedProperty: ISelectedProperties = property["0"];
      setSelectedInerdyskSize(newSelectedProperty);
    }
  };

  const handleSelectInerdyskOneSizeInerciseSet = (id: string) => {
    let property;
    // eslint-disable-next-line
    productProperties.filter((productProperty) => {
      if (productProperty.name === "INERDYSK_ONE_SIZE_INERCISE_SET") {
        property = productProperty.values.filter(
          (value) => String(value.id) === String(id)
        );
      }
    });

    if (property !== undefined) {
      const newSelectedProperty: ISelectedProperties = property["0"];
      setSelectedInerdyskOneSizeInerciseSet(newSelectedProperty);
    }
  };

  const handleSelectInerdyskTwoSizeInerciseSet = (id: string) => {
    let property;
    // eslint-disable-next-line
    productProperties.filter((productProperty) => {
      if (productProperty.name === "INERDYSK_TWO_SIZE_INERCISE_SET") {
        property = productProperty.values.filter(
          (value) => String(value.id) === String(id)
        );
      }
    });

    if (property !== undefined) {
      const newSelectedProperty: ISelectedProperties = property["0"];
      setSelectedInerdyskTwoSizeInerciseSet(newSelectedProperty);
    }
  };

  const chooseDaysToShip = (): string => {
    const selectedDaysToShip: number[] = [];

    const colorDaysToShip = selectedColor?.daysToShip;
    if (colorDaysToShip !== undefined) {
      selectedDaysToShip.push(Number(colorDaysToShip));
    }

    const sizeDaysToShip = selectedSize?.daysToShip;
    if (sizeDaysToShip !== undefined) {
      selectedDaysToShip.push(Number(sizeDaysToShip));
    }

    const colorSetDaysToShip = selectedInerciseSetColor?.daysToShip;
    if (colorSetDaysToShip !== undefined) {
      selectedDaysToShip.push(Number(colorSetDaysToShip));
    }

    const sizeSetBeltDaysToShip = selectedSetBeltSize?.daysToShip;
    if (sizeSetBeltDaysToShip !== undefined) {
      selectedDaysToShip.push(Number(sizeSetBeltDaysToShip));
    }

    const inderdyskDaysToShip = selectedInderdyskSize?.daysToShip;
    if (inderdyskDaysToShip !== undefined) {
      selectedDaysToShip.push(Number(inderdyskDaysToShip));
    }

    const inderdyskOneInerciseSetDaysToShip =
      selectedInderdyskOneSizeInerciseSet?.daysToShip;
    if (inderdyskOneInerciseSetDaysToShip !== undefined) {
      selectedDaysToShip.push(Number(inderdyskOneInerciseSetDaysToShip));
    }

    const inderdyskTwoInerciseSetDaysToShip =
      selectedInderdyskTwoSizeInerciseSet?.daysToShip;
    if (inderdyskTwoInerciseSetDaysToShip !== undefined) {
      selectedDaysToShip.push(Number(inderdyskTwoInerciseSetDaysToShip));
    }

    const gnericDaysToShip = selectedGeneric?.daysToShip;
    if (gnericDaysToShip !== undefined) {
      selectedDaysToShip.push(Number(gnericDaysToShip));
    }

    const maxDaysToShip = Math.max(...selectedDaysToShip);
    return String(maxDaysToShip);
  };

  return (
    <AnimatePresence>
      <motion.div
        className={Styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.55 }}
      >
        <div className={Styles.productContainer}>
          <div>
            <ImageGallery items={productImages} />;
          </div>
          <div className={Styles.descContainer}>
            <h2 className={Styles.title}>
              {t(`products.${product?.name}.NAME`)}
            </h2>
            <h3 className={Styles.currentPrice}>
              {getProductPrice(product, userCurrency.name)} {userCurrency.name}
            </h3>
            {t(`products.${product?.name}.DESC`)
              .split("\n")
              .map((str, index) => {
                return (
                  <p key={index} className={Styles.desc}>
                    {str}
                  </p>
                );
              })}

            <div className={Styles.productPropertiesContainer}>
              {
                // eslint-disable-next-line
                productProperties.map((property, index) => {
                  if (property.name === "COLOR") {
                    return (
                      <div className={Styles.propertyContainers} key={index}>
                        <p className={Styles.propertyText}>
                          {t(`productPage.SELECT_COLOR`)}
                        </p>
                        {property.values.map((value, index) => {
                          return (
                            <div
                              key={index}
                              id={value.id}
                              className={`${Styles.propertyButton} ${
                                String(selectedColor?.id) === String(value.id)
                                  ? Styles.propertyButtonSelected
                                  : ""
                              } ${!value.available && Styles.disabled}`}
                              style={{
                                backgroundColor: `${value.value}`,
                              }}
                              onClick={(e) =>
                                value.available &&
                                handleSelectColor(e.currentTarget.id)
                              }
                              title={
                                value.available
                                  ? ""
                                  : t(`productPage.PRODUCT_UNAVAILABLE`)
                              }
                            ></div>
                          );
                        })}
                      </div>
                    );
                  } else if (property.name === "COLOR_INERCISE_SET") {
                    return (
                      <div className={Styles.propertyContainers} key={index}>
                        <p className={Styles.propertyText}>
                          {t(`productPage.SELECT_COLOR`)}
                        </p>
                        {property.values.map((value, index) => {
                          return (
                            <div
                              key={index}
                              id={value.id}
                              className={`${Styles.propertyButton} ${
                                String(selectedInerciseSetColor?.id) ===
                                String(value.id)
                                  ? Styles.propertyButtonSelected
                                  : ""
                              } ${!value.available && Styles.disabled}`}
                              style={{
                                backgroundColor: `${value.value}`,
                              }}
                              onClick={(e) =>
                                value.available &&
                                handleSelectSetColor(e.currentTarget.id)
                              }
                              title={
                                value.available
                                  ? ""
                                  : t(`productPage.PRODUCT_UNAVAILABLE`)
                              }
                            ></div>
                          );
                        })}
                      </div>
                    );
                  } else if (property.name === "SIZE") {
                    return (
                      <div className={Styles.propertyContainers} key={index}>
                        <p className={Styles.propertyText}>
                          {t(`productPage.SELECT_SIZE`)}
                        </p>
                        {property.values.map((value, index) => {
                          return (
                            <div
                              key={index}
                              id={value.id}
                              className={`${Styles.propertyButton} ${
                                Styles.sizePropertyButton
                              } ${
                                String(selectedSize?.id) === String(value.id)
                                  ? Styles.propertyButtonSelected
                                  : ""
                              } ${!value.available && Styles.disabled}`}
                              onClick={(e) =>
                                value.available &&
                                handleSelectSize(e.currentTarget.id)
                              }
                              title={
                                value.available
                                  ? ""
                                  : t(`productPage.PRODUCT_UNAVAILABLE`)
                              }
                            >
                              {value.value}
                            </div>
                          );
                        })}
                      </div>
                    );
                  } else if (property.name === "BELT_SIZE_INERCISE_SET") {
                    return (
                      <div className={Styles.propertyContainers} key={index}>
                        <p className={Styles.propertyText}>
                          {t(`productPage.SELECT_SIZE_BELT`)}
                        </p>
                        {property.values.map((value, index) => {
                          return (
                            <div
                              key={index}
                              id={value.id}
                              className={`${Styles.propertyButton} ${
                                Styles.sizePropertyButton
                              } ${
                                String(selectedSetBeltSize?.id) ===
                                String(value.id)
                                  ? Styles.propertyButtonSelected
                                  : ""
                              } ${!value.available && Styles.disabled}`}
                              onClick={(e) =>
                                value.available &&
                                handleSelectSetSize(e.currentTarget.id)
                              }
                              title={
                                value.available
                                  ? ""
                                  : t(`productPage.PRODUCT_UNAVAILABLE`)
                              }
                            >
                              {value.value}
                            </div>
                          );
                        })}
                      </div>
                    );
                  } else if (property.name === "INERDYSK_SIZE") {
                    return (
                      <div className={Styles.propertyContainers} key={index}>
                        <p className={Styles.propertyText}>
                          {t(`productPage.SELECT_INNERDYSK`)}
                        </p>
                        {property.values.map((value, index) => {
                          return (
                            <div
                              key={index}
                              id={value.id}
                              className={`${Styles.propertyButton} ${(() => {
                                switch (String(value.value)) {
                                  case "5":
                                    return Styles.sizeInerdysk5Button;
                                  case "10":
                                    return Styles.sizeInerdysk10Button;
                                  case "25":
                                    return Styles.sizeInerdysk25Button;
                                  case "50":
                                    return Styles.sizeInerdysk50Button;
                                  default:
                                    return Styles.sizePropertyButton;
                                }
                              })()}
                              ${
                                String(selectedInderdyskSize?.id) ===
                                String(value.id)
                                  ? Styles.propertyButtonSelected
                                  : ""
                              } ${!value.available && Styles.disabled}`}
                              onClick={(e) =>
                                value.available &&
                                handleSelectInerdyskSize(e.currentTarget.id)
                              }
                              title={
                                value.available
                                  ? ""
                                  : t(`productPage.PRODUCT_UNAVAILABLE`)
                              }
                            >
                              {value.value}
                            </div>
                          );
                        })}
                      </div>
                    );
                  } else if (
                    property.name === "INERDYSK_ONE_SIZE_INERCISE_SET"
                  ) {
                    return (
                      <div className={Styles.propertyContainers} key={index}>
                        <p className={Styles.propertyText}>
                          {t(`productPage.SELECT_INNERDYSK_1`)}
                        </p>
                        {property.values.map((value, index) => {
                          return (
                            <div
                              key={index}
                              id={value.id}
                              className={`${Styles.propertyButton} ${(() => {
                                switch (String(value.value)) {
                                  case "5":
                                    return Styles.sizeInerdysk5Button;
                                  case "10":
                                    return Styles.sizeInerdysk10Button;
                                  case "25":
                                    return Styles.sizeInerdysk25Button;
                                  case "50":
                                    return Styles.sizeInerdysk50Button;
                                  default:
                                    return Styles.sizePropertyButton;
                                }
                              })()}
                              ${
                                String(
                                  selectedInderdyskOneSizeInerciseSet?.id
                                ) === String(value.id)
                                  ? Styles.propertyButtonSelected
                                  : ""
                              } ${!value.available && Styles.disabled}`}
                              onClick={(e) =>
                                value.available &&
                                handleSelectInerdyskOneSizeInerciseSet(
                                  e.currentTarget.id
                                )
                              }
                              title={
                                value.available
                                  ? ""
                                  : t(`productPage.PRODUCT_UNAVAILABLE`)
                              }
                            >
                              {value.value}
                            </div>
                          );
                        })}
                      </div>
                    );
                  } else if (
                    property.name === "INERDYSK_TWO_SIZE_INERCISE_SET"
                  ) {
                    return (
                      <div className={Styles.propertyContainers} key={index}>
                        <p className={Styles.propertyText}>
                          {t(`productPage.SELECT_INNERDYSK_2`)}
                        </p>
                        {property.values.map((value, index) => {
                          return (
                            <div
                              key={index}
                              id={value.id}
                              className={`${Styles.propertyButton} ${(() => {
                                switch (String(value.value)) {
                                  case "5":
                                    return Styles.sizeInerdysk5Button;
                                  case "10":
                                    return Styles.sizeInerdysk10Button;
                                  case "25":
                                    return Styles.sizeInerdysk25Button;
                                  case "50":
                                    return Styles.sizeInerdysk50Button;
                                  default:
                                    return Styles.sizePropertyButton;
                                }
                              })()}
                              ${
                                String(
                                  selectedInderdyskTwoSizeInerciseSet?.id
                                ) === String(value.id)
                                  ? Styles.propertyButtonSelected
                                  : ""
                              } ${!value.available && Styles.disabled}`}
                              onClick={(e) =>
                                value.available &&
                                handleSelectInerdyskTwoSizeInerciseSet(
                                  e.currentTarget.id
                                )
                              }
                              title={
                                value.available
                                  ? ""
                                  : t(`productPage.PRODUCT_UNAVAILABLE`)
                              }
                            >
                              {value.value}
                            </div>
                          );
                        })}
                      </div>
                    );
                  } else if (property.name === "GENERIC") {
                    return (
                      <div className={Styles.propertyContainers} key={index}>
                        {property.values.map((value, index) => {
                          return (
                            <div
                              key={index}
                              id={value.id}
                              title={
                                value.available
                                  ? ""
                                  : t(`productPage.PRODUCT_UNAVAILABLE`)
                              }
                            >
                              {product?.productType.name === "COURSE"
                                ? ""
                                : value.value}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                })
              }
            </div>
            {product?.productType.name !== "COURSE" ? (
              <h3 className={`${Styles.propertyText} ${Styles.deliveryText}`}>
                {isProductAvailable()
                  ? `${t(
                      `productPage.DELIVERY_DATE`
                    )} ${chooseDaysToShip()} ${t(`productPage.DAYS`)}`
                  : t(`productPage.PRODUCT_UNAVAILABLE`)}
              </h3>
            ) : (
              ""
            )}
            <div
              className={Styles.paymentContainer}
              title={
                isProductAvailable() ? "" : t(`productPage.PRODUCT_UNAVAILABLE`)
              }
            >
              <Button
                text={t(`productPage.ADD_TO_CART`)}
                className={Styles.button}
                onClick={
                  isProductAvailable() ? handleAddProductToCart : () => {}
                }
                linkUrl={`/product?id=${product?.id}`}
                disabled={!isProductAvailable()}
              />
              {i18n.language === "pl" ? (
                <a
                  className={Styles.tpayInstallmentsLink}
                  href="https://tpay.com/jak-to-dziala"
                  title="Obsługujemy płatności internetowe przez system płatności online Tpay"
                >
                  <img
                    className={Styles.tpayInstallmentsImg}
                    src="https://tpay.com/img/banners/tpaycom_raty-1122x85.svg"
                    alt="Logo Tpay"
                  />
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={Styles.techDescContainer}>
            <table className={Styles.table}>
              <thead>
                <tr>
                  <th className={Styles.header} colSpan={2}>
                    {product?.productType.name === "COURSE"
                      ? t(`productPage.COURSE_DETAILS`)
                      : t(`productPage.SPECIFICATION`)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {product?.specificationItems.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{t(`productPage.${item.key}`)}</td>
                      <td>
                        {t(`productPage.${item.value}`, {
                          defaultValue: `${item.value}`,
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <h2 className={Styles.productTitle}>
            {t(`homePage.productsSection.TITLE`)}
          </h2>
          <div className={Styles.productsListContainer}>
            {productsWithoutCurrent.slice(0, 4).map((item) => {
              return (
                <ProductBlock
                  key={item.id}
                  id={item.id.toString()}
                  productName={item.name}
                  currentPrice={getProductPrice(item, userCurrency.name)}
                  previousPrice={getProductPreviousPrice(
                    item,
                    userCurrency.name
                  )}
                  thumbnail={thumbnailAPI + item.thumbnail.name}
                  buttonText={`${t(`productPage.SEE_PRODUCT`)}  ${t(
                    `products.${item.name}.NAME`
                  )}`}
                  currency={userCurrency.name}
                />
              );
            })}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default ProductCard;
