import Styles from "./UserDeleteAccount.module.css";
import Button from "../../components/Button";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import { deleteUserAPI } from "../../services/UserApi";
import { logout } from "../../features/user/reducers/UserSlice";
import { clearCart } from "../../features/cart/reducers/CartSlice";

function UserDeleteAccount() {
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.userState);

  useEffect(() => {
    if (user?.accessToken === undefined || user.accessToken === null) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  const handleDeleteUser = () => {
    if (
      user.accessToken === null ||
      user.user.id === null ||
      user.user.id === undefined
    )
      return;

    deleteUserAPI(user.accessToken, user.user.id.toString()).then(
      (response) => {
        if (response?.ok) {
          dispatch(logout());
          dispatch(clearCart());
          setErrorMessage("");
          navigate("/");
        } else {
          setErrorMessage(t(`userDeleteAccount.ERROR_TEXT`));
        }
      }
    );
  };

  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <div className={Styles.container}>
        <h2 className={Styles.title}>{t(`userDeleteAccount.TITLE`)}</h2>
        <p className={Styles.info}>{t(`userDeleteAccount.INFO`)}</p>
        <Button
          linkUrl="/"
          text={t(`userDeleteAccount.BTN_TEXT`)}
          className={Styles.button}
          onClick={handleDeleteUser}
        />
        {errorMessage && <p className={Styles.error}></p>}
      </div>
    </motion.div>
  );
}

export default UserDeleteAccount;
