export const navbarItems = [
  {
    id: 1,
    title: "HOME",
    url: "/",
    submenu: [],
  },
  {
    id: 2,
    title: "SHOP",
    url: "/shop",
    submenu: [],
  },
  // {
  //   id: 3,
  //   title: "O Nas",
  //   url: "/about",
  //   submenu: [],
  // },
  {
    id: 4,
    title: "INERCISE",
    url: "/inercise",
    submenu: [
      {
        id: 1,
        title: "DISCOVER_INERCISE",
        url: "/inercise",
      },
      {
        id: 2,
        title: "ATLAS_EXCERCISES",
        url: "/excercise-atlas",
      },
      {
        id: 3,
        title: "TRAINPORT_DOCK_APP",
        url: "/application",
      },
    ],
  },
  {
    id: 6,
    title: "PARTNERS",
    url: "/partners",
    submenu: [],
  },
  {
    id: 5,
    title: "BLOG",
    url: "/blog",
    submenu: [],
  },
  {
    id: 6,
    title: "CONTACT",
    url: "/contact",
    submenu: [],
  },
];

export default navbarItems;
