export const quickLinksItems = [
  // {
  //   id: 1,
  //   title: "O nas",
  //   url: "/about",
  // },
  {
    id: 2,
    title: "INERCISE",
    url: "/inercise",
  },
  {
    id: 3,
    title: "SHOP",
    url: "/shop",
  },
  {
    id: 4,
    title: "PRIVACY_POLICY",
    url: "/privacy-policy",
  },
  {
    id: 5,
    title: "TERMS_OF_USE",
    url: "/terms-of-sale",
  },
  {
    id: 6,
    title: "CONTACT_FORM",
    url: "/contact",
  },
];

export default quickLinksItems;
