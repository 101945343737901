import i18n from "i18next";
import { useEffect, useState } from "react";
import Styles from "./LanguageSwitchButton.module.css";

interface Language {
  language: string;
  icon: string;
  name: string;
}

const language = () => {
  const currentLanguage = i18n.language;
  let languageIconName: string = "";
  let name: string = "";
  if (currentLanguage === "pl") {
    languageIconName = "pl.svg";
    name = "Polski";
  } else if (currentLanguage === "en") {
    languageIconName = "gb.svg";
    name = "English";
  }
  return { language: currentLanguage, icon: languageIconName, name: name };
};

const availableLanguagesInI18n = (): Array<Language> => {
  const currentLanguage = i18n.language;
  let availableLanguages: Array<Language> = [];
  if (currentLanguage !== "pl") {
    availableLanguages.push({ language: "pl", icon: "pl.svg", name: "Polski" });
  }
  if (currentLanguage !== "en") {
    availableLanguages.push({
      language: "en",
      icon: "gb.svg",
      name: "English",
    });
  }
  return availableLanguages;
};

function LanguageSwitchButton() {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(language);
  const [availableLanguages, setAvailableLanguages] = useState<Language[]>(
    availableLanguagesInI18n
  );
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setCurrentLanguage(language);
  }, []);

  useEffect(() => {
    setAvailableLanguages(availableLanguagesInI18n);
  }, [currentLanguage]);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const onSwitchLanguage = () => {
    if (currentLanguage.language === "en") {
      i18n.changeLanguage("pl");
      setCurrentLanguage({ language: "pl", icon: "pl.svg", name: "Polski" });
    }

    if (currentLanguage.language === "pl") {
      i18n.changeLanguage("en");
      setCurrentLanguage({ language: "en", icon: "gb.svg", name: "English" });
    }

    if (window.matchMedia("(max-width: 1380px)")) {
      setIsHovered(!isHovered);
    }
  };

  return (
    <div
      className={Styles.container}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <img
        className={Styles.flag}
        src={require(`../../assets/icons/${currentLanguage.icon}`)}
        alt={currentLanguage?.language}
      />
      {isHovered && (
        <div className={Styles.languageDropdownContainer}>
          <div className={Styles.additionalLanguages}>
            {availableLanguages.map((language) => {
              return (
                <div
                  className={Styles.additionalLanguage}
                  onClick={onSwitchLanguage}
                >
                  <img
                    className={Styles.flagAdditionalLanguages}
                    src={require(`../../assets/icons/${language.icon}`)}
                    alt=""
                  />
                  <p className={Styles.text}>{language.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default LanguageSwitchButton;
