import { MutableRefObject, useEffect, useRef, useState } from 'react';

const useElementOnScreen = (): [
  MutableRefObject<HTMLInputElement | null>,
  boolean
] => {
  const containerRef = useRef<HTMLInputElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setIsVisible(entries[0].isIntersecting);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
  }, [containerRef]);

  return [containerRef, isVisible];
};

export default useElementOnScreen;
