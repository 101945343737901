import { useSelector } from "react-redux";
import Styles from "./UserOrder.module.css";
import { RootState } from "../../app/Store";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Order } from "../../models/order/Order.model";
import { getUserOderAPI } from "../../services/UserApi";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const thumbnailAPI: string = `${process.env.REACT_APP_TRAINPORT_BACKEND_API_URL}/thumbnail/`;

function UserOrder() {
  const [order, setOrder] = useState<Order[]>([]);
  const { t } = useTranslation();
  const userAccessToken = useSelector(
    (state: RootState) => state.userState.accessToken
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userAccessToken === undefined ||
      userAccessToken === null ||
      userAccessToken === ""
    ) {
      navigate("/login");
    }
  });

  useEffect(() => {
    if (
      userAccessToken === undefined ||
      userAccessToken === null ||
      userAccessToken === ""
    ) {
      return;
    }
    getUserOderAPI(userAccessToken).then((item) => {
      if (item !== undefined) {
        setOrder(item);
        return;
      } else {
        return;
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <hr className={Styles.divider} />
      <h2 className={Styles.title}>
        {order === undefined || order.length <= 0
          ? t(`userOrderPage.NO_ORDER_WELCOME`)
          : t(`userOrderPage.WELCOME`)}
      </h2>

      {order.map((item) => {
        const jsx = (
          <div className={Styles.orderContainer}>
            <div className={Styles.infoContainer}>
              <div>
                <p className={Styles.orderTitle}>
                  {t(`userOrderPage.ORDER_NUMBER`)} {item.orderNumber}
                </p>
                <p className={Styles.orderStatusText}>
                  {t(`userOrderPage.ORDER_STATUS`)}{" "}
                  {t(`userOrderPage.${item.status?.name}`)}
                </p>
              </div>
              <div className={Styles.propertiesContainer}>
                <p className={`${Styles.text} ${Styles.addressTitle}`}>
                  {t(`userOrderPage.SHIPPMENT_ADDRESS`)}
                </p>
                <p className={Styles.text}>
                  {item.shippingAddress?.addressLine_1}
                  {`, ` + item.shippingAddress?.postcode}
                  {`, ` + item.shippingAddress?.city}
                </p>
                <p className={Styles.text}>
                  {item.shippingAddress?.country}
                  {`, ` + item.shippingAddress?.state}
                </p>
                <p className={Styles.text}>
                  {t(`userOrderPage.PHONE_NUMBER`)}{" "}
                  {` ` + item.shippingAddress?.phoneNumber}
                </p>
                {item.invoiceAddress && (
                  <span>
                    <p className={`${Styles.text} ${Styles.addressTitle}`}>
                      {t(`userOrderPage.INVOICE_DATA`)}
                    </p>
                    <p className={Styles.text}>
                      {item.invoiceAddress?.companyName}
                    </p>
                    <p className={Styles.text}>
                      {t(`userOrderPage.COMPANY_IDENTIFICATION_NUMBER`)}{" "}
                      {item.invoiceAddress?.companyIdentificationNumber}
                    </p>
                    <p className={Styles.text}>
                      {item.invoiceAddress?.addressLine_1}
                      {item.invoiceAddress?.addressLine_2}
                      {`, ` + item.invoiceAddress?.postcode}
                      {`, ` + item.invoiceAddress?.city}
                    </p>
                    <p className={Styles.text}>
                      {item.invoiceAddress?.country}
                      {`, ` + item.invoiceAddress?.state}
                    </p>
                    <p className={Styles.text}>
                      {t(`userOrderPage.PHONE_NUMBER`)}{" "}
                      {` ` + item.invoiceAddress?.phoneNumber}
                    </p>
                  </span>
                )}
              </div>
              <span className={Styles.propertiesContainer}>
                <p
                  className={`${Styles.addressTitle} ${Styles.text} ${Styles.propertyTypeValue}`}
                >
                  {t(`userOrderPage.VALUE`)}
                </p>
                &#160;
                <p className={`${Styles.text} ${Styles.propertyTypeValue}`}>
                  {item.originalPrice} {item.currency?.name}
                </p>
              </span>
            </div>
            <hr />

            {item.orderItems.map((orderItem) => {
              return (
                <React.Fragment>
                  <div className={Styles.productOrderContainer}>
                    <p
                      className={`${Styles.addressTitle} ${Styles.text} ${Styles.marginRight}`}
                    >
                      {t(`products.${orderItem.product.name}.NAME`)}
                    </p>
                    <p className={`${Styles.text} ${Styles.textEnd}`}>
                      {orderItem.quantity} x
                    </p>
                    <img
                      className={Styles.productThumbnail}
                      src={thumbnailAPI + orderItem.product.thumbnail.name}
                      alt={orderItem.product.thumbnail.name}
                    />
                    <div className={Styles.propertiesContainer}>
                      <p className={`${Styles.text} ${Styles.addressTitle}`}>
                        {orderItem.selectedProperties.map((property) => {
                          return (
                            <div>
                              <p className={Styles.propertyTypeValue}>
                                {t(`cartItem.${property.propertyType.name}`)}
                              </p>
                              <p className={Styles.propertyValue}>
                                {` ` +
                                  t(`productPage.${property.value}`, {
                                    defaultValue: `${property.value}`,
                                  })}
                              </p>
                            </div>
                          );
                        })}
                      </p>
                    </div>
                  </div>

                  <hr />
                </React.Fragment>
              );
            })}
          </div>
        );
        return jsx;
      })}
    </motion.div>
  );
}

export default UserOrder;
