const inerciseFeatures = [
  {
    id: 1,
    title: "GENERAL_ADVANTAGES_TITLE",
    text: "GENERAL_ADVANTAGES_TEXT",
    image: "inercise_advantages_section_1.jpg",
  },
  {
    id: 2,
    title: "INFINITE_POSSIBILITIES_TITLE",
    text: "INFINITE_POSSIBILITIES_TEXT",
    image: "inercise_advantages_section_2.jpg",
  },
  {
    id: 3,
    title: "TRAINING_EVERYWHERE_TITLE",
    text: "TRAINING_EVERYWHERE_TEXT",
    image: "inercise_advantages_section_3.jpg",
  },
  {
    id: 4,
    title: "INJURY_PREVENTIONS_TITLE",
    text: "INJURY_PREVENTIONS_TEXT",
    image: "inercise_advantages_section_4.jpg",
  },
];

export default inerciseFeatures;
