import { ITransaction } from "../models/tpay/Transaction.model";
import { fetchData, fetchPrivateData } from "../utils/API";

const paths = {
  beginTransaction: "/tpay/beginTransaction/",
  paymentMethods: "/tpay/payment-methods",
};

export async function beginTransactionAPI(
  userAccessToken: string | null,
  transaction: ITransaction
) {
  const response = await fetchPrivateData(
    paths.beginTransaction,
    userAccessToken,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAccessToken,
      },
      body: JSON.stringify(transaction),
    }
  );
  return response;
}

export async function getPaymentMethodsAPI() {
  const response = await fetchData(paths.paymentMethods);
  const data = await response?.json();
  return data;
}
