import { fetchData } from "../utils/API";

const paths = {
  contact: "/contact",
};

export async function postContact(emailAddress: string, message: string) {
  const response = await fetchData(paths.contact, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      emailAddress: emailAddress,
      name: "null",
      message: message,
    }),
  });

  if (response === undefined || !response.ok) {
    throw new Error("Couldn't fetch data from:" + paths.contact);
  }

  return response;
}
