import QuickLinksLink from "./QuickLinksLink";
import { quickLinksItems } from "../../constans/QuickLinksItems";

import Styles from "./QuickLinks.module.css";

function QuickLinks() {
  return (
    <div className={Styles.container}>
      <h4 className={Styles.title}>Quick Links</h4>
      <ul className={Styles.linksList}>
        {quickLinksItems.map((item) => {
          return (
            <QuickLinksLink title={item.title} url={item.url} key={item.id} />
          );
        })}
      </ul>
    </div>
  );
}

export default QuickLinks;
