import { useEffect } from "react";

import Contact from "./Contact";
import NewsletterSignUp from "./NewsletterSignUp";
import SocialMedia from "./SocialMedia";
import QuickLinks from "./QuickLinks";

import Styles from "./Sitemap.module.css";
import useElementOnScreen from "../../hooks/UseElementOnScreen";

function Sitemap() {
  const [containerRef, isVisible] = useElementOnScreen();

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.opacityFirsta}`)
        .forEach((el) => {
          el.classList.add(`${Styles.opacityFirstAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.opacitySecond}`)
        .forEach((el) => {
          el.classList.add(`${Styles.opacitySecondAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.opacityThird}`)
        .forEach((el) => {
          el.classList.add(`${Styles.opacityThirdAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.opacityFourth}`)
        .forEach((el) => {
          el.classList.add(`${Styles.opacityFourthAnimation}`);
        });
    }
  }, [isVisible, containerRef]);

  return (
    <div className={Styles.container}>
      <div className={Styles.content} ref={containerRef}>
        <div className={Styles.opacityFirsta}>
          <SocialMedia />
        </div>
        <div className={Styles.opacitySecond}>
          <QuickLinks />
        </div>
        <div className={Styles.opacityThird}>
          <Contact />
        </div>
        <div className={Styles.opacityFourth}>
          <NewsletterSignUp />
        </div>
      </div>
    </div>
  );
}
export default Sitemap;
