import { motion } from "framer-motion";
import Styles from "./QrCode.module.css";
import { useParams } from "react-router-dom";
import { getDeviceManualBySerialNumber } from "../../services/DeviceAPI";
import Button from "../../components/Button";
import { downloadFile } from "../../utils/FileDownloadHelper";

const getManualAPI: string = `${process.env.REACT_APP_TRAINPORT_BACKEND_API_URL}/device-manual/`;

function QrCode() {
  const { serialNumber } = useParams();

  const handleDownload = () => {
    if (serialNumber) {
      try {
        getDeviceManualBySerialNumber(serialNumber).then((data) => {
          const fileURL = window.URL.createObjectURL(data);
          downloadFile(fileURL, `Instrukcja obsługi Inercise.pdf`);
        });
      } finally {
        downloadFile(
          `${getManualAPI}manual_inercise.pdf`,
          `Instrukcja obsługi Inercise.pdf`
        );
      }
    } else {
      downloadFile(
        `${getManualAPI}manual_inercise.pdf`,
        `Instrukcja obsługi Inercise.pdf`
      );
    }
  };

  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <h2 className={Styles.title}>Materiały Trainport</h2>
      {serialNumber && (
        <p className={Styles.serialNumber}>
          Numer seryjny Twojego Inercise: {serialNumber}
        </p>
      )}
      <Button
        text="Pobierz instrukcję Inercise"
        linkUrl=""
        onClick={handleDownload}
      />
    </motion.div>
  );
}
export default QrCode;
