import * as React from "react";
import Navbar from "./Navbar";

import Styles from "./Header.module.css";
import Logo from "../../assets/images/trainport-logo.png";
import useElementOnScreen from "../../hooks/UseElementOnScreen";
import { Link } from "react-router-dom";

interface HeaderProps {
  onInputChnage: (arg: boolean) => void;
}

function Header(props: HeaderProps) {
  const [containerRef, isVisible] = useElementOnScreen();

  React.useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`img.${Styles.logo}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeAnimation}`);
        });
    }
  }, [isVisible, containerRef]);

  return (
    <header className={Styles.header}>
      <div className={Styles.container} ref={containerRef}>
        <div className={Styles.imgContainer}>
          <Link to="/">
            <img className={Styles.logo} src={Logo} alt="Trainport Logo" />
          </Link>
        </div>
        <Navbar onInputChnage={props.onInputChnage} />
      </div>
    </header>
  );
}

export default Header;
