import { useEffect, useState } from "react";
import Styles from "./LoginForm.module.css";
import { getCurrentUser, loginAPI } from "../../../services/UserApi";
import { login, setUser } from "../reducers/UserSlice";
import { useDispatch } from "react-redux";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";

interface LoginFormProps {
  isResetPasswordRequested: (arg: boolean) => void;
  isUserAuthenticated: (arg: boolean) => void;
}

function LoginForm(props: LoginFormProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (userAuthenticated) {
      props.isUserAuthenticated(true);
    }
  });

  const handleLogin = async () => {
    let accessToken: string | null;
    if (isEmailCorrect()) {
      loginAPI(email, password)
        .then((response) => {
          if (response?.ok) {
            setUserAuthenticated(true);
            accessToken = response.headers.get("access_token");
            dispatch(login({ email, accessToken }));
          } else if (response?.status === 403 || response?.status === 404) {
            setErrorMessage(t(`loginPage.WRONG_EMAIL_OR_PASSWORD`));
          } else if (response?.status === 409) {
            setErrorMessage(t(`loginPage.INACTIVE_ACCOUNT`));
          } else {
            setErrorMessage(t(`loginPage.SOMETHING_WENT_WRONG`));
          }
        })
        .then(() => {
          if (accessToken !== null || accessToken !== undefined) {
            getCurrentUser(accessToken).then((data) => {
              dispatch(setUser(data));
            });
          }
        });
    } else {
      setErrorMessage(t(`loginPage.FILL_OUT_FIELDS`));
    }
  };

  const isEmailCorrect = () => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    var isEmailCorrect: RegExpMatchArray | null = email.match(validRegex);
    return isEmailCorrect;
  };

  return (
    <div className={Styles.container}>
      <h2 className={Styles.title}>{t(`loginPage.LOGIN_WELCOME`)}</h2>
      <form className={Styles.form}>
        <label className={Styles.info} htmlFor="">
          {t(`loginPage.ENTER_EMAIL_ADDRESS`)}{" "}
        </label>
        <input
          className={Styles.input}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <label className={Styles.info} htmlFor="">
          {t(`loginPage.ENTER_PASSWORD`)}
        </label>
        <input
          className={Styles.input}
          type="password"
          name="pass"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className={`${Styles.input} ${Styles.button}`}
          type="button"
          value={t(`loginPage.BUTTON_LOGIN`)}
          onClick={handleLogin}
        />
        <input
          className={Styles.link}
          type="button"
          value={t(`loginPage.FORGET_PASSWORD`)}
          onClick={(e) => props.isResetPasswordRequested(true)}
        />

        {errorMessage && (
          <label className={Styles.error}> {errorMessage} </label>
        )}
      </form>
      <p className={Styles.text}>{t(`loginPage.NO_ACCOUNT_QUESTION`)}</p>
      <Button text={t(`loginPage.JOIN_US`)} linkUrl="/register-user" />
    </div>
  );
}

export default LoginForm;
