import { Link, createSearchParams } from "react-router-dom";
import Styles from "./ArticleBlock.module.css";
import { Article } from "../../models/blog/Article.model";
import { getTextInCurrentLanguage } from "../../utils/BlogHelpers";

const thumbnailAPI: string = `${process.env.REACT_APP_TRAINPORT_BACKEND_API_URL}/thumbnail/`;

interface ArticleBlockProps {
  article: Article;
  // eslint-disable-next-line react/require-default-props
  classStyle?: string;
}

function ArticleBlock(props: ArticleBlockProps) {
  const naviagateArticle = {
    pathname: "/article",
    search: createSearchParams({
      id: props.article.id,
    }).toString(),
  };

  return (
    <div className={Styles.container}>
      <Link to={naviagateArticle}>
        <img
          className={Styles.image}
          width="100%"
          height="350"
          src={`${thumbnailAPI}${props.article.thumbnail?.name}`}
          alt=""
        />
      </Link>
      <div className={Styles.titleContainer}>
        <h5 className={`${Styles.subtitle} ${props.classStyle}`}>
          {props.article.datePublished?.slice(0, 10)}
        </h5>
        <h4 className={`${Styles.title} ${props.classStyle}`}>
          {getTextInCurrentLanguage(props.article.titleDict)}
        </h4>
      </div>
    </div>
  );
}
export default ArticleBlock;
