import { IInvoiceAddress } from "../models/address/InvoiceAddress.model";
import { IShipmentAddress } from "../models/address/ShipmentAddress.model";
import { UserAddress } from "../models/user/UserAddress.model";
import { fetchPrivateData } from "../utils/API";

const paths = {
  addUserAddress: "/address",
  getUserAddress: "/address",
  patchUserAddress: "/address",
};

export async function addUserAddressAPI(
  userAccessToken: string | null,
  shippingAddress: IShipmentAddress,
  invoiceAddress: IInvoiceAddress | null
) {
  let options;
  if (invoiceAddress !== undefined) {
    options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAccessToken,
      },
      body: JSON.stringify({ shippingAddress, invoiceAddress }),
    };
  } else {
    options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAccessToken,
      },
      body: JSON.stringify({ shippingAddress }),
    };
  }

  const response = await fetchPrivateData(
    paths.addUserAddress,
    userAccessToken,
    options
  );

  return response;
}

export async function getUserAddressAPI(userAccessToken: string | null) {
  const response = await fetchPrivateData(
    paths.addUserAddress,
    userAccessToken,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAccessToken,
      },
    }
  );

  return response;
}

export async function patchUserAddressAPI(
  userAccessToken: string | null,
  userAddress: UserAddress | undefined
) {
  const response = await fetchPrivateData(
    paths.addUserAddress,
    userAccessToken,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAccessToken,
      },
      body: JSON.stringify(userAddress),
    }
  );

  return response;
}
