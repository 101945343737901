export const excercises = [
  {
    id: 1,
    name: "Inercise Deadlift",
    muscle: "INERCISE_DEADLIFT_MUSCLE",
    steps: [
      "INERCISE_DEADLIFT_STEP1",
      "INERCISE_DEADLIFT_STEP2",
      "INERCISE_DEADLIFT_STEP3",
      "INERCISE_DEADLIFT_STEP4",
      "INERCISE_DEADLIFT_STEP5",
    ],
    videoName: "excercise-pull.mp4",
  },
  // {
  //   id: 2,
  //   name: "Inercise Pull",
  //   muscle: "Mięśnie pośladkowe, czworogłowy i dwugłowy uda",
  //   steps: [
  //     "Stań w pozycji wyprostnej poprzecznie do długiej osi platformy, nogi w rozstawieniu trochę większym niż na szerokość bioder, kolana skierowane nieznacznie na zewnątrz.",
  //     "Dostosuj długość linki w tej pozycji, która jest Twoją pozycją końcową.",
  //     "Nakręć koło, uginając nogi w stawach biodrowych i kolanowych, schodząc dopozycji przysiadu. (w tej fazie wykonujesz wdech)",
  //     "Wykonaj dynamiczny ruch wyprostu (wydech)  i powtórz ćwiczenie ponownie.",
  //     "Pamiętaj, nie pochylaj się zbyt mocno w przód oraz nie wypychaj bioder w tył.Staraj się zachować pozycję wyprostowaną. Niech kończyny górne pomogą Cibalansować ciałem. Oddychaj regularnie.",
  //   ],
  //   videoName: "excercise-pull.mp4",
  // },
];

export default excercises;
