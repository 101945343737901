import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/Store";
import { ICurrency } from "../../../models/cart/Currency.model";

const initialState = {
  userCurrency: {
    id: 1,
    name: "PLN",
  } as ICurrency,
};

export const userCurrencySlice = createSlice({
  name: "userCurrency",
  initialState,
  reducers: {
    setUserCurrency: (state, action: PayloadAction<ICurrency>) => {
      state.userCurrency = action.payload;
    },
  },
});

export const { setUserCurrency } = userCurrencySlice.actions;
export const selectUserCurrency = (state: RootState) => state.currencyState;
export default userCurrencySlice.reducer;
