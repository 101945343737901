import ArticleBlock from "../Home/ArticleBlock";

import Styles from "./FeatureSection.module.css";
import useElementOnScreen from "../../hooks/UseElementOnScreen";
import { useEffect, useState } from "react";
import { getAllPublishedArticlesByDate } from "../../services/ArticleAPI";
import { Article } from "../../models/blog/Article.model";
import { addArticalesTranslations } from "../../utils/BlogHelpers";

function FeatureSection() {
  const [containerRef, isVisible] = useElementOnScreen();
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeftDelay}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimationDelay}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeOpacity}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeOpacityAnimation}`);
        });
    }
  }, [isVisible, containerRef]);

  useEffect(() => {
    getAllPublishedArticlesByDate(true).then((item) => {
      addArticalesTranslations(item);
      setArticles(item);
    });
  }, []);

  return (
    <div className={Styles.container} ref={containerRef}>
      <div className={`${Styles.articlesContainer} ${Styles.fadeOpacity}`}>
        {articles.map((item) => {
          return (
            <ArticleBlock
              key={item.id}
              article={item}
              classStyle={Styles.articleBlock}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FeatureSection;
