import { motion } from "framer-motion";
import Styles from "./TermsOfSale.module.css";

function TermsOfSale() {
  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <hr className={Styles.sectionDivider} />
      <h2 className={Styles.title}>Regulamin Trainport</h2>
      <hr className={Styles.titleDivider} />
      <p className={Styles.desc}>
        Korzystanie z Serwisu (w tym jego przeglądanie, rejestracja i logowanie
        się, korzystanie z usług oferowanych za jego pośrednictwem przez
        Trainport) podlega zasadom określonym w niniejszym Regulaminie. Zapoznaj
        się więc uważnie z jego treścią, którą prezentujemy poniżej.
      </p>
      <br />
      <h3 className={Styles.subtitle}>Postanowienia wstępne</h3>
      <br />
      <p className={Styles.desc}>
        Wyrazy użyte z dużej litery mają znaczenie nadane im w regulaminie
        Serwisu internetowego. Dane osobowe są przetwarzane przez Administratora
        zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679
        z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
        przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
        danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
        ochronie danych) (dalej: Rozporządzenie).
      </p>
      <br />
      <br />
      <ol className={Styles.desc}>
        <li>
          Regulamin określa rodzaje i zakres świadczenia usług drogą
          elektroniczną, zasady zawierania umów za pomocą Serwisu internetowego
          oraz poczty e-mail, a także przez telefon, zasady wykonywania tych
          umów, prawa i obowiązki Klienta i Trainport oraz kwestie odstąpienia
          od umowy i postępowania reklamacyjnego, a także warunki świadczenia
          innych usług w ramach Serwisu oraz zasady dostępu i korzystania z
          zasobów Serwisu.
        </li>
        <li>
          Serwis jest dostępny na stronie internetowej: www.trainport.team.
          Warunkiem uzyskania dostępu do funkcjonalności Serwisu jest
          skorzystanie z urządzenia komunikującego się z Internetem i
          wyposażonego w standardową przeglądarkę stron internetowych. Za dostęp
          do aplikacji z urządzeń mobilnych operator telekomunikacyjny może
          naliczyć dodatkową opłatę.
        </li>
        <li>
          Właścicielem serwisu internetowego pod adresem www.trainport.team jest
          TRAINPORT Spółka z ograniczoną odpowiedzialnością z siedzibą w Łodzi
          (kod pocztowy: 90-765) przy ul. Zielonej 77/15, wpisana do Rejestru
          Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd
          Rejonowy dla Łodzi-Śródmieścia w Łodzi XX Wydział Gospodarczy
          Krajowego Rejestru Sądowego pod numerem: 0000965737, NIP: 7272857698,
          o kapitale zakładowym w wysokości 10.000 zł, tel. +48 733 840 083,
          e-mail: kontakt@trainport.team
        </li>
        <li>
          Trainport jest przedsiębiorcą w rozumieniu ustawy z dnia 6 marca 2018
          r. Prawo przedsiębiorców.
        </li>
        <li>
          Trainport jest osobą oferującą towary i usługi w ramach Serwisu
          internetowego i jest dostawcą internetowej platformy handlowej (Serwis
          internetowy), stąd w dalszej części Regulaminu nie dokonuje się
          rozdziału obowiązków związanych z umową zawieraną z Ogłoszeniodawcą
          pomiędzy osobę trzecią oferującą towary w ramach Serwisu internetowego
          a dostawcę internetowej platformy handlowej (Serwis internetowy).
        </li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>
        Wymagania techniczne korzystania z serwisu internetowego
      </h3>
      <br />
      <p className={Styles.desc}>
        Korzystanie z Serwisu internetowego wiąże się z następującymi
        wymaganiami technicznymi po stronie Ogłoszeniodawcy:
      </p>
      <br />
      <ol className={Styles.desc}>
        <li>Komputer lub urządzenie mobilne z dostępem do Internetu</li>
        <li>Dostęp do poczty elektronicznej</li>
        <li>
          Przeglądarka internetowa Internet Explore, Firefox, Chrome, Opera,
          Safari
        </li>
        <li>Wyłączenie w przeglądarce internetowej Cookies oraz javascript</li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>Definicje</h3>
      <br />
      <p className={Styles.desc}>
        Użyte w Regulaminie terminy oznaczają odpowiednio:
      </p>
      <br />
      <ol className={Styles.desc}>
        <li>Regulamin – niniejszy regulamin Serwisu internetowego</li>
        <li>
          Sprzedawca lub Trainport – TRAINPORT Spółka z ograniczoną
          odpowiedzialnością z siedzibą w Łodzi (kod pocztowy: 90-765) przy ul.
          Zielonej 77/15, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru
          Sądowego prowadzonego przez Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi
          XX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem:
          0000965737, NIP: 7272857698, o kapitale zakładowym w wysokości 10.000
          zł,
        </li>
        <li>
          Klient – osoba fizyczna (osoba pełnoletnia, posiadająca pełną zdolność
          do czynności prawnych), osoba prawna albo jednostka organizacyjna
          nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność
          prawną, która korzysta z produktów lub usług Trainport
        </li>
        <li>Produkt - obiekt oferowany w sklepie</li>
        <li>
          Serwis internetowy lub Serwis – serwis internetowy Trainport dostępny
          pod adresem internetowym www.trainport.team wraz jego subdomenami
        </li>
        <li>
          Formularz internetowy – formularz dostępny w Serwisie internetowym
          umożliwiającym dokonanie rejestracji
        </li>
        <li>
          Formularz zamówienia – formularz dostępny w Serwisie internetowym
          umożliwiającym dokonanie zamówienia
        </li>
        <li>
          Umowa  – umowa sprzedaży lub świadczenia usług w rozumieniu ustawy z
          dnia 23 kwietnia 1964 r. Kodek cywilny Produktu, zawierana pomiędzy
          Klientem, a Trainport z wykorzystaniem środków porozumiewania na
          odległość
        </li>
        <li>
          Cennik – cennik Produktów / Usług dostępny na stronie Serwisu
          internetowego
        </li>
        <li>
          Zamówienie – oświadczenie woli Klienta składane za pomocą Serwisu
          internetowego (Formularza zamówienia), poczty elektronicznej lub
          telefonicznie i zmierzające bezpośrednio do zawarcia Umowy
        </li>
        <li>
          Podmiot zewnętrzny – firmy współpracujące ze Sprzedawcą w celu
          realizacji warunków Umowy zawartej pomiędzy Sprzedawcą a Klientem.
          Firmy te przetwarzają dane osobowe jedynie w celach wynikających z
          Umowy.
        </li>
        <li>
          Dzień roboczy – dzień przypadający od poniedziałku do piątku, inny
          aniżeli ustawowo dzień wolny od pracy.
        </li>
        <li>
          Konsument — Ogłoszeniodawca będący konsumentem w rozumieniu art. 22 1
          Ustawy z dnia 23 kwietnia 1964 r. Kodeks cywilny tj. będący osobą
          fizyczną, dokonującą w Serwisie czynności prawnej niezwiązanej
          bezpośrednio z jej działalnością gospodarczą lub zawodową.
        </li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>Zasady zamawiania Produktu</h3>
      <br />
      <ol className={Styles.desc}>
        <li>
          W celu skorzystania z usług oferowanych w ramach Serwisu
          internetowego, należy dokonać rejestracji. Rejestracja odbywa się
          poprzez prawidłowe wypełnienie i przesłanie Trainport Formularza
          rejestracji. W trakcie rejestracji Klient podaje adres poczty
          elektronicznej (e-mail) służący do kontaktu z Trainport oraz ustala
          hasło umożliwiające dostęp do konta Klienta, w przypadku osób
          fizycznych Klient potwierdza swoją pełnoletność oraz pełną zdolność
          do czynności prawnych. Klient może posiadać tylko jedno konto i może
          korzystać tylko z własnego konta. Podane przez Klienta dane muszą być
          prawdziwe i aktualne. Klient zobowiązany jest do aktualizacji swoich
          danych każdorazowo w przypadku zmiany, poprzez Panel Klienta po
          zalogowaniu się na stronie Trainport, lub wysyłając aktualizację
          danych na adres mail kontakt@trainport.team. Odpowiedzialność za
          ewentualne negatywne następstwa braku aktualizacji danych ponosi
          Klient.
        </li>
        <li>
          Klient zobowiązany jest chronić poufność informacji umożliwiających
          dostęp do jego konta w Serwisie internetowym, w szczególności poprzez
          nieujawnienie tych informacji osobom trzecim. Klient ponosi wyłączną
          odpowiedzialność za szkody spowodowane udostępnieniem osobom trzecim
          informacji umożliwiającym zalogowanie się do jego konta w Serwisie
          internetowym lub pozostawieniem zalogowanym do swego konta w Serwisie
          internetowym, mimo że nie będzie z niego korzystał w danym momencie.
        </li>
        <li>
          Klient zobowiązuje się do korzystania z Serwisu internetowego zgodnie
          z obowiązującymi przepisami prawa i zasadami współżycia społecznego
          oraz do przestrzegania niniejszego Regulaminu.
        </li>
        <li>
          Trainport umożliwia złożenie zamówienia za pośrednictwem Serwisu
          internetowego Trainport (Formularz zamówień), poczty elektronicznej na
          adres kontakt@trainport.team. lub telefonicznie pod numerem +48 733
          840 083. Zamówienia za pośrednictwem Serwisu internetowego lub poczty
          elektronicznej można składać przez 7 dni w tygodniu, 24 godziny na
          dobę. Zamówienia telefoniczne przyjmowane są pod numerem telefonu +48
          733 840 083, w dni robocze w godzinach od 9:00 do 17:00.
        </li>
        <p className={Styles.desc}>
          Proces zamówienia przez serwis internetowy zaczyna się z chwilą
          rozpoczęcia wypełnienia przez Klienta Formularza zamówienia
          znajdującego się w zakładce „Zamów”. Klient wybiera:
        </p>
        <li>
          <ul>
            <li>Rodzaju i ilości Produktów oraz podaje:</li>
            <li>
              dane niezbędne do realizacji zamówienia, tj. dane personalne orz
              adres dostawy Trainport nie ponosi odpowiedzialności za szkody
              powstałe w przypadku przekazania przez Klienta niepełnych lub
              nieprawidłowych, nieprawdziwych informacji. Po wyborze powyższych,
              Klient potwierdza zapoznanie się z treścią regulaminu oraz wyraża
              zgodę na przetwarzanie jego danych osobowych oraz na zawarcie
              Umowy poprzez potwierdzenie zamówienia oraz zobowiązuje się do
              zapłaty wskazanej w zamówieniu kwoty.Trainport zastrzega sobie
              prawo do dodatkowej weryfikacji danych Klienta, np. w drodze
              kontaktu telefonicznego.
            </li>
          </ul>
        </li>
        <p className={Styles.desc}>
          Proces zamówienia przez telefon polega na ustaleniu w drodze
          telefonicznej
        </p>
        <li>
          <ul>
            <li>Rodzaju i ilości Produktów oraz podaje:</li>
            <li>
              dane niezbędne do realizacji zamówienia, tj. dane personalne oraz
              adres dostawy Trainport nie ponosi odpowiedzialności za szkody
              powstałe w przypadku przekazania przez Klienta niepełnych lub
              nieprawidłowych, nieprawdziwych informacji. Po wyborze powyższych,
              Klient potwierdza zapoznanie się z treścią regulaminu oraz wyraża
              zgodę na przetwarzanie jego danych osobowych oraz na zawarcie
              Umowy poprzez potwierdzenie zamówienia oraz zobowiązuje się do
              zapłaty wskazanej w zamówieniu kwoty.
            </li>
          </ul>
        </li>
        <li>
          Aktualna oferta znajduje się na stronie www.trainport.team/shop .
        </li>
        <li>
          W przypadku gdy Klient chce otrzymać fakturę VAT winien zgłosić taką
          chęć w momencie składania zamówienia informując o tym Trainport przy
          składaniu zamówienia drogą mailową lub telefoniczną lub zaznaczyć taką
          opcję przy składaniu zamówienia przez serwis internetowy. Tym samym
          Klient wyraża zgodę na otrzymywanie faktury VAT drogą elektroniczna na
          adres e-mail podany w zamówieniu.
        </li>
        <li>
          W każdym przypadku informowania o obniżeniu ceny towaru lub usługi
          przy składaniu zamówienia obok informacji o obniżonej cenie uwidacznia
          się również informację o najniższej cenie tego towaru lub tej usługi,
          która obowiązywała w okresie 30 dni przed wprowadzeniem obniżki.
          Jeżeli dany towar lub dana usługa są oferowane do sprzedaży w okresie
          krótszym niż 30 dni, przy składaniu zamówienia obok informacji o
          obniżonej cenie uwidacznia się również informację o najniższej cenie
          tego towaru lub tej usługi, która obowiązywała w okresie od dnia
          rozpoczęcia oferowania tego towaru lub tej usługi do sprzedaży do dnia
          wprowadzenia obniżki.
        </li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>Realizacja zamówienia</h3>
      <br />
      <ol className={Styles.desc}>
        <li>
          W przypadku zamówień złożonych i opłaconych do godziny 11:00 przed
          południem, realizacja zamówienia nastąpi od drugiego dnia roboczego
          następującego po dacie złożenia zamówienia, a w innych wypadkach
          realizacja zamówienia nastąpi do trzeciego dnia roboczego.
        </li>
        <li>
          Identyfikacja Klienta odbywa się za pomocą spersonalizowanego numeru
          Klienta, który umieszczany jest na etykiecie adresowej na torbie.
        </li>
        <li>
          Po otrzymaniu zamówienia Trainport wysyła Klientowi drogą
          elektroniczną oświadczenie o przyjęciu Zamówienia, które stanowi jego
          potwierdzenie albo w przeciągu 12 godzin od chwili otrzymania
          zamówienia przekazuje zastrzeżenia co do treści Zamówienia. Zamówienie
          zostaje potwierdzone, jeżeli zawiera dane pozwalające na identyfikację
          zamówienia, tj. imię, nazwisko, adres, zamówiony Produkt, a Klient
          udzielił wszystkich wymaganych zgód.
        </li>
        <li>
          Umowa zostaje zawarta z chwilą złożenia Klientowi oświadczenia o
          przyjęciu Zamówienia.
        </li>
        <li>
          Trainport zastrzega sobie prawo do wstrzymania lub odmowy realizacji
          zamówienia w przypadku:
          <ul>
            <li>
              złożenia Zamówienia w sposób nieprawidłowy, uniemożliwiający jego
              realizację,
            </li>
            <li>
              bezskuteczności uzgodnienia z Klientem miejsca i terminu dostawy
              Produktu,
            </li>
            <li>braku dokonania płatności za zakupiony produkt.v</li>
            <li>
              rażącego naruszenia przez Klienta postanowień niniejszego
              Regulaminu.
            </li>
          </ul>
        </li>
        <li>
          Możliwość dostawy pod adres wskazany przez Klienta w trakcie składania
          Zamówienia potwierdzana jest przez Trainport podczas przyjęcia
          Zamówienia do realizacji. Klient jest o tym informowany o braku
          możliwości realizacji dostawy pod podany adres.
        </li>
        <li>Dostawa Produktów jest płatna.</li>
        <li>
          W przypadku gdy zamówienie nie mogło być zrealizowane w pierwszym
          terminie z przyczyn niezależnych od Trainport, Trainport ustali wraz z
          Klientem nowy termin dostawy.
        </li>
        <li>
          Trainport nie ponosi odpowiedzialności za niedostarczenie Produktu w
          przypadku braku możliwości lub znacznego utrudnienia dokonania dostawy
          Produktu z przyczyn niezależnych od Trainport, w tym na skutek
          wystąpienia siły wyższej.
        </li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>Metody płatności</h3>
      <br />
      <ol className={Styles.desc}>
        <li>
          Możliwe są następując formy dokonania płatności:
          <ul>
            <li>
              przelew internetowy – drogą online przez dostawcę płatności
              internetowych
            </li>
            <li>
              bezpośrednio na rachunek bankowy Trainport nr 93 1140 2004 0000
              3902 8234 6721 .
            </li>
          </ul>
        </li>
        <li>
          Wpłatę za zamówiony Produkt należy dokonać w pełnej kwocie w terminie
          3 dni od dnia dokonania potwierdzenia zamówienia, lecz nie później niż
          na 2 dni robocze poprzedzające pierwszy dzień realizacji zamówienia.
          Brak wpłaty we wskazanym terminie skutkuje anulowaniem zamówienia.
        </li>
        <li>
          W przypadku płatności przelewem tradycyjnym Klient zobowiązany jest
          przekazać Trainport potwierdzenie wykonania przelewu w terminie 3 dni
          od dnia złożenia zamówienia, lecz nie później niż do 2 dni roboczych
          poprzedzające pierwszy dzień realizacji zamówienia.
        </li>
        <li>
          W przypadku akcji promocyjnej zamówienia muszą zostać opłacone w ciągu
          24h od momentu złożenia zamówienia. Brak wpłaty we wskazanym terminie
          skutkuje anulowaniem zamówienia.
        </li>
        <li>
          Za dzień płatności uznaje się dzień zaksięgowania wpłaty na koncie
          Trainport albo dzień przesłania potwierdzenia przelewu elektronicznego
          w formie pliku PDF pocztą elektroniczną na adres
          kontatk@trainport.team .
        </li>
        <li>
          Jeżeli wpłata dokonana przez konsumenta na rachunku bankowym Trainport
          będzie zaksięgowana po godzinie 14.00, paragon fiskalny zostanie
          wystawiony z tego tytułu następnego dnia roboczego.
        </li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>Konkursy, regulaminy lub rabaty</h3>
      <br />
      <ol className={Styles.desc}>
        <li>
          Trainport może organizować konkursy, promocje oraz może udzielać
          rabatów na Produkty lub Usługi, przy czym za każdym razem będzie to
          realizowane w oparciu o odrębne regulaminy.
        </li>
        <li>
          Jeżeli inaczej w regulaminie, o którym mowa w ust. 1, nie zastrzeżono
          promocje, rabaty lub kody rabatowe nie łączą się ze sobą.
        </li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>Prawo do odstąpienia od umowy</h3>
      <br />
      <ol className={Styles.desc}>
        <li>
          Konsument może odstąpić od umowy sprzedaży Produktów bez podania
          jakiejkolwiek przyczyny, składając stosowne oświadczenie w terminie 14
          dni kalendarzowych. Do zachowania terminu wystarczy wysłanie
          oświadczenia przed jego upływem.
        </li>
        <li>
          Czas na zwrot liczony jest od momentu wydania Produktu (tj. od dnia od
          objęcia rzeczy w posiadanie przez Konsumenta lub wskazaną przez niego
          osobę trzecią inną niż przewoźnik).
        </li>
        <li>
          Konsument zobowiązany jest poinformować Sprzedawcę o swojej decyzji o
          odstąpieniu od Umowy drogą elektroniczną (poprzez e- mail) lub w
          formie pisemnej na adres Sprzedawcy.
        </li>
        <li>
          Konsument może skorzystać z załączonego wzoru formularza odstąpienia
          od Umowy.
        </li>
        <li>
          W momencie odstąpienia od umowy Sprzedawca zobowiązany jest
          niezwłocznie zwrócić wszystkie otrzymane od Konsumenta płatności, w
          tym koszty dostarczenia Towaru (z wyjątkiem dodatkowych kosztów
          wynikających z wybranego przez Konsumenta sposobu dostarczenia innego
          niż najtańszy zwykły sposób dostarczenia oferowany przez Sprzedawcę).
        </li>
        <li>
          Konsument zobowiązany jest zwrócić Produkt niezwłocznie, a na pewno
          nie później niż 14 dni od dnia, w którym odstąpił od Umowy w stanie w
          jakim Produkt dostarczono Klientowi
        </li>
        <li>
          Sprzedawca może wstrzymać się ze zwrotem płatności, jaką poniósł
          Konsument za Produkt do momentu jego otrzymania.
        </li>
        <li>
          Zwrot środków dokonany jest w ten sam sposób, w jaki Konsument dokonał
          płatności, na wyraźne życzenie Konsumenta sposób ten może zostać
          zmieniony.
        </li>
        <li>
          Koszty zwrotu Produktu pokrywane są w całości przez Konsumenta-
          zgodnie ze stawkami wybranego przewoźnika.
        </li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>Zablokowanie lub usunięcie konta</h3>
      <br />
      <ol className={Styles.desc}>
        <li>
          Trainport może zablokować konto Klienta w Serwisie internetowym w
          przypadku, gdy Klient pomimo upomnienia narusza postanowienia
          Regulaminu.
        </li>
        <li>
          Zablokowanie przez Trainport konta Klienta w Serwisie internetowym,
          zgodnie z Regulaminem, spowoduje czasową utratę przez Klienta
          możliwość korzystania z Serwisu. Przy czym, zablokowanie przez
          Trainport konta Klienta w Serwisie internetowym, zgodnie z
          Regulaminem, nie powoduje przerwania realizacji bieżącego zamówienia.
        </li>
        <li>
          Klient może w każdym czasie wrócić się za pośrednictwem poczty
          elektronicznej z prośbą o usunięcie własnego konta w Serwisie
          internetowego. Trainport zobowiązuje się do usunięcia konta w ciągu
          dwóch dni roboczych od zarejestrowania prośby.
        </li>
        <li>
          Trainport może także usunąć konto Klienta z serwisu internetowego w
          przypadku, gdy Klient:
          <ul>
            <li>pomimo upomnienia, rażąco narusza Regulamin;</li>
            <li>
              Klient nie korzystał z Serwisu internetowego w okresie ostatnich 3
              trzech lat, z tym jednak zastrzeżeniem, iż nie posiada w danym
              momencie ważnego zamówienia.
            </li>
          </ul>
        </li>
        <li>
          Usunięcie konta Klienta z Serwisu internetowego powoduje bezpowrotną
          utratę wszelkich informacji zgromadzonych tam przez Klienta.
        </li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>Postępowanie reklamacyjne</h3>
      <br />
      <ol className={Styles.desc}>
        <li>
          Klientowi przysługuje Prawo do złożenia reklamacji zakupionego
          Produktu jeżeli okaże się on niezgodny bądź będzie posiadał wady.
        </li>
        <li>
          Klient składa reklamację drogą e- mailową, na
          adres kontakt@trainport.team.
        </li>
        <li>
          Zaleca się, aby w reklamacji zawarte były takie informacje, jak: dane
          Klienta składającego reklamację, opis wady, w jakich okolicznościach
          wystąpiła bądź mogła wystąpić oraz żądanie Klienta związane z wadą
          Produktu.
        </li>
        <li>
          Konsument zobowiązany jest niezwłocznie odesłać reklamowany Produkt,
          na koszt Sprzedawcy. W tym celu należy skontaktować się (e-mailowo
          bądź telefonicznie) ze Sprzedawcą.
        </li>
        <li>
          Sprzedawca odniesie się do żądania reklamacyjnego niezwłocznie- nie
          później niż w terminie 14 dni kalendarzowych od dnia otrzymania
          reklamacji.
        </li>
        <li>
          W sprawach nieuregulowanych powyżej mają zastosowanie: przepisy
          rozdziału 5a ustawy z dnia 30 maja 2014 r. o prawach konsumenta w
          przypadku Klienta będącego konsumentem lub osobą fizyczną zawierającą
          umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z
          treści tej umowy wynika, że nie ma ona dla tej osoby charakteru
          zawodowego, wynikającego w szczególności z przedmiotu wykonywanej
          przez nią działalności gospodarczej, udostępnionego na podstawie
          przepisów o Centralnej Ewidencji i Informacji o Działalności
          Gospodarczej, a w przypadku innych osób przepisy księgi trzeciej
          tytułu XI działu II ustawy z dnia 23 kwietnia 1964 r. Kodeksu
          cywilnego.
        </li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>Ochrona danych osobowych</h3>
      <br />
      <ol className={Styles.desc}>
        <li>Administratorem danych osobowych jest Trainport.</li>
        <li>
          Szczegółowe informacje dotyczące przetwarzania danych osobowych, w tym
          celów, zakresu, podstaw i zasad ich przetwarzania wskazane są w
          Polityce Prywatności dostępnej na stronie serwisu internetowego.
        </li>
      </ol>
      <br />
      <h3 className={Styles.subtitle}>Prawa autorskie</h3>
      <br />
      <ol className={Styles.desc}>
        <li>
          Serwis internetowy i jego poszczególne elementy podlegają ochronie na
          mocy ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach
          pokrewnych.
        </li>
        <li>
          Klient nie jest uprawniony do kopiowania Serwisu internetowego, z
          wyjątkiem przypadków, w których zachowanie takie jest dopuszczalne na
          mocy przepisów bezwzględnie obowiązujących.
        </li>
      </ol>

      <br />
      <h3 className={Styles.subtitle}>Postanowienia końcowe</h3>
      <br />
      <ol className={Styles.desc}>
        <li>
          Trainport jest uprawniony do dokonywania zmian w Regulaminie, w tym
          zmian związanych z wystąpieniem: zmiany przepisów prawa; zmiany
          sposobu realizacji usługi lub płatności; dodania nowych usług
          elektronicznych – w zakresie, w jakim te zmiany wpływają na realizację
          postanowień niniejszego Regulaminu.
        </li>
        <li>
          Zmiany w Regulaminie są wiążące dla Klienta, jeżeli został on
          prawidłowo powiadomiony o dokonanych zmianach i nie wypowiedział umowy
          w terminie 14 dni kalendarzowych od dnia powiadomienia.
        </li>
        <li>
          W celu rozwoju Serwisu Trainport ma prawo wprowadzenia nowych usług i
          funkcjonalności, które mogą być poprzedzone testami produktowymi, bez
          uszczerbku dla nabytych praw Użytkowników.
        </li>
        <li>
          Regulamin dostępny jest na stronie internetowej Serwisu, a także w
          stosunku do Ogłoszeniodawców przesyłany drogą poczty elektronicznej na
          adres e-mail Ogłoszeniodawcy, w przypadkach wymaganych przez
          odpowiednie przepisy prawne.
        </li>
        <li>
          Prawem właściwym dla umowy zawartej pomiędzy Ogłoszeniodawcą a
          Administratorem, której przedmiotem są usługi świadczone w ramach
          Serwisu na warunkach określonych w Regulaminie, jest prawo polskie.
        </li>
        <li>
          Wszelkie spory związane z usługami świadczonymi w ramach Serwisu będą
          rozstrzygane przez właściwe polskie sądy powszechne.
        </li>
        <li>
          Konsumenci mają możliwość skorzystania z pozasądowego sposobu
          rozpatrywania reklamacji i dochodzenia roszczeń przed Stałym
          Polubownym Sądem Konsumenckim przy Wojewódzkim Inspektorze Inspekcji
          Handlowej w Poznaniu. Informacje o sposobie dostępu do ww. trybu i
          procedur rozstrzygania sporów, znajdują się pod następującym adresem:
          uokik.gov.pl, w zakładce „Rozstrzyganie sporów konsumenckich”.
          Konsumenci mają również możliwość skorzystania z unijnej platformy
          internetowej ODR, dostępnej pod adresem internetowym:
          http://ec.europa.eu/consumers/odr/
        </li>
        <li>
          Jeżeli którekolwiek postanowienie Regulaminu zostanie uznane
          prawomocnym orzeczeniem sądu za nieważne, pozostałe postanowienia
          pozostają w mocy.
        </li>
        <li>
          W sprawach nieuregulowanych niniejszym regulaminem mają zastosowanie
          powszechnie obowiązujące przepisy prawa polskiego, w szczególności:
          ustawy z dnia 23 kwietnia 1964 r. Kodeksu cywilnego; ustawy z dnia 18
          lipca 2002 r. o świadczeniu usług drogą elektroniczną; ustawy z dnia
          30 maja 2014 r. o prawach konsumenta.
        </li>
      </ol>
    </motion.div>
  );
}

export default TermsOfSale;
