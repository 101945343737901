import { Currency } from "../models/cart/Currency.model";
import { Product } from "../models/product/Product.model";

export function getProductPrice(
  product: Product | undefined,
  currency: string
) {
  if (currency === Currency.PLN) return product?.currentPrice;
  else if (currency === Currency.EUR) return product?.currentPriceEur;
  else return "ERROR";
}

export function getProductPreviousPrice(
  product: Product | undefined,
  currency: string
) {
  if (currency === Currency.PLN) return product?.previousPrice;
  else if (currency === Currency.EUR) return product?.previousPriceEur;
  else return "ERROR";
}
