import { ICart } from "../models/cart/Cart.model";
import { ICartItem } from "../models/cart/CartItem.model";
import { ProductProperty } from "../models/product/ProductProperty.model";
import { fetchPrivateData } from "../utils/API";

const paths = {
  userCart: "/cart",
  addProduct: "/cart/add-product/",
  removeProduct: "/cart/remove-cart-item/",
  mergeCart: "/cart/merge",
  patchCart: "/cart/cart-item/",
  applyDiscount: "/cart/apply-discount/",
  selectPartner: "/cart/select-referrer/",
  shipmentPrice: "/cart/shipment/",
};

export async function getUserCartAPI(token: string | null) {
  const response = await fetchPrivateData(paths.userCart, token);
  const userCart: ICart = await response?.json();
  return userCart;
}

export async function selectReferrerAPI(
  token: string | null,
  partnerId: number | undefined
) {
  const response = await fetchPrivateData(
    paths.selectPartner + partnerId,
    token,
    {
      method: "POST",
    }
  );

  return response;
}

export async function addProductToCartAPI(
  token: string | null,
  productId: string,
  quantity: number,
  productProperty: ProductProperty[]
) {
  const response = await fetchPrivateData(
    paths.addProduct + productId + "?quantity=" + quantity,
    token,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(productProperty),
    }
  );

  return response;
}

export async function removeProductFromCartAPI(
  token: string | null,
  cartItemId: number
) {
  const response = await fetchPrivateData(
    paths.removeProduct + cartItemId,
    token,
    {
      method: "POST",
    }
  );

  return response;
}

export async function mergeCartAPI(
  token: string | null,
  cartItems: ICartItem[]
) {
  const response = await fetchPrivateData(paths.mergeCart, token, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ cartItems: cartItems }),
  });

  const newCartItems: ICart = await response?.json();
  return newCartItems;
}

export async function patchCartAPI(token: string | null, cartItem: ICartItem) {
  const response = await fetchPrivateData(paths.patchCart, token, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      id: cartItem.id,
      quantity: cartItem.quantity,
      selectedProperties: cartItem.selectedProperties,
    }),
  });
  return response;
}

export async function applyDiscountAPI(
  token: string | null,
  discountCode: String
) {
  const response = await fetchPrivateData(
    paths.applyDiscount + discountCode,
    token,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  return response;
}

export async function getShipmentPriceAPI(
  token: string | null,
  region: String
) {
  const response = await fetchPrivateData(paths.shipmentPrice + region, token, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  return response;
}
