import { Link } from "react-router-dom";
import Styles from "./IconLink.module.css";

interface IconLinkProps {
  iconUrl: string;
  linkUrl: string;
}

function IconLink(props: IconLinkProps) {
  return (
    <Link className={Styles.link} to={props.linkUrl} reloadDocument={true}>
      <img className={Styles.icon} src={props.iconUrl} alt="Search" />
    </Link>
  );
}

export default IconLink;
