import { t } from "i18next";
import { ICartItem } from "../models/cart/CartItem.model";

export function getProductsInCartPricePLN(cartItems: ICartItem[]): number {
  const productsPrice = cartItems?.map((cartItem) => {
    const price = Number(cartItem.product.currentPrice);
    const quantity = cartItem.quantity;
    return price * quantity;
  });
  return productsPrice?.reduce((sum, prevSum) => sum + prevSum, 0);
}

export function getProductsInCartPriceEuro(cartItems: ICartItem[]): number {
  const productsPrice = cartItems?.map((cartItem) => {
    const price = Number(cartItem.product.currentPriceEur);
    const quantity = cartItem.quantity;
    return price * quantity;
  });
  return productsPrice?.reduce((sum, prevSum) => sum + prevSum, 0);
}

export function getItemsCountInCart(cartItems: ICartItem[]): number {
  if (cartItems === null) {
    return 0;
  } else if (cartItems.length <= 0) {
    return 0;
  }

  const itemsCount = cartItems.map((cartItem, index) => {
    return cartItem.quantity;
  });

  return itemsCount.reduce((sum, prevSum) => sum + prevSum, 0);
}

export function isPaymentMethodValid(
  paymentMethod: String | undefined
): String | null {
  return paymentMethod === undefined ||
    paymentMethod === null ||
    paymentMethod === "" ||
    paymentMethod === "999"
    ? t(`deliveryAndPaymentAndPage.SELECT_PAYMENT_METHOD`)
    : null;
}
