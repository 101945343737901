import { Thumbnail } from "../thumbnail/Thumbnail.model";
import { ActivityLevelType } from "./ActivityLevelType.model";
import { GenderType } from "./GenderType.model";
import { GoalType } from "./GoalType.model";
import { PrivilegeType } from "./PrivilegeType.model";
import { RoleType } from "./RoleType.model";
import { UserAddress } from "./UserAddress.model";

export interface User {
  id?: number | null;
  emailAddress?: string | null;
  password?: string | null;
  name?: string | null;
  surname?: string | null;
  roleType?: RoleType | null;
  privileges?: PrivilegeType[] | null;
  thumbnail?: Thumbnail | null;
  gender?: GenderType | null;
  height?: number | null;
  weight?: number | null;
  birthDate?: Date | null;
  activityLevel?: ActivityLevelType | null;
  goals?: GoalType[] | null;
  userAddress?: UserAddress | null;
}

export const defaultUser = {
  id: null,
  emailAddress: "",
  password: "",
  name: "",
  surname: "",
  roleType: null,
  privileges: null,
  thumbnail: null,
  gender: null,
  height: null,
  weight: null,
  birthDate: null,
  activityLevel: null,
  goals: null,
  userAddress: null,
};
