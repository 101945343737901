import { IInvoiceAddress } from "../address/InvoiceAddress.model";
import {
  initialShipmentAddress,
  IShipmentAddress,
} from "../address/ShipmentAddress.model";

export interface UserAddress {
  id: number | undefined;
  invoiceAddress: IInvoiceAddress | null;
  shippingAddress: IShipmentAddress | undefined;
}

export const initialUserAddress: UserAddress = {
  id: undefined,
  invoiceAddress: null,
  shippingAddress: initialShipmentAddress,
};
