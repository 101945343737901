import { Link } from "react-router-dom";
import Styles from "./Dropdown.module.css";
import { useTranslation } from "react-i18next";

interface DropdownProps {
  visibile: boolean;
  submenu?: { id: number; title: string; url: string }[];
  onClick?: () => any;
}

function Dropdown({ visibile, submenu, onClick }: DropdownProps) {
  const { t } = useTranslation();
  return (
    <ul
      className={`${Styles.list} {${visibile} ? ${Styles.dropdownExpand} : ${Styles.dropdownHide}}`}
    >
      {submenu?.map((item) => {
        return (
          <li className={Styles.element} key={item.id} onClick={onClick}>
            <Link className={Styles.link} to={item.url}>
              {t(`navbar.${item.title}`)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export default Dropdown;
