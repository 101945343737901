import { useState } from "react";
import { isMobile } from "../../utils/ScreenHelper";
import Styles from "./FeatureBlock.module.css";
import { useTranslation } from "react-i18next";

interface FeatureBlockProps {
  image: string;
  title: string;
  text: string;
  backgroundImage: string;
}
function FeatureBlock({
  image,
  title,
  text,
  backgroundImage,
}: FeatureBlockProps) {
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const imgBg = require(`../../assets/images/${backgroundImage}`);
  // eslint-disable-next-line global-require
  const boxStyle = {
    transition: isHover ? "150ms" : "100ms",
    backgroundColor: isHover ? "" : "var(--main-opacity-color)",
    backgroundImage: isHover ? `url(${imgBg})` : "",
    boxShadow: isHover ? "rgba(64, 200, 174, 0.5) 0px 0px 26px 0px" : "",
  };

  return (
    <div
      className={Styles.container}
      style={boxStyle}
      onMouseEnter={isMobile() ? () => {} : handleMouseEnter}
      onMouseLeave={isMobile() ? () => {} : handleMouseLeave}
    >
      <img src={require(`../../assets/images/${image}`)} alt="Icon" />
      <h5 className={Styles.title}>{t(title)}</h5>
      <p className={Styles.text}>{text}</p>
    </div>
  );
}
export default FeatureBlock;
