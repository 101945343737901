import { useState } from "react";
import { registerUserInNewsletterAPI } from "../../services/NewsletterAPI";
import Styles from "./NewsletterSignUp.module.css";
import Button from "../Button";
import { useTranslation } from "react-i18next";

function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

function NewsletterSignUp() {
  const [emailAddress, setEmailAddress] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [sucessMessage, setSuccessMessage] = useState("");
  const { t } = useTranslation();

  const handleOnSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!isValidEmail(emailAddress) || undefined) {
      setErrorEmail(t(`footer.newsletter.INVALID_EMAIL`));
      setSuccessMessage("");
    } else {
      const response = registerUserInNewsletterAPI(emailAddress);
      response.then((item) => {
        if (item?.ok) {
          setSuccessMessage(t(`footer.newsletter.EMAIL_ADDED`));
          setErrorEmail("");
        } else if (item?.status === 400) {
          setSuccessMessage("");
          setErrorEmail(t(`footer.newsletter.EMAIL_REGISTERED`));
        }
      });
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <h4 className={Styles.question}>{t(`footer.newsletter.SUBTITLE`)}</h4>
        <h3 className={Styles.action}> {t(`footer.newsletter.TITLE`)}</h3>
        <div className={Styles.inputEmail}>
          <form onSubmit={handleOnSubmit} action="">
            <input
              className={Styles.input}
              placeholder={t(`footer.newsletter.BUTTON_PLACEHOLDER`)}
              onChange={(event) => setEmailAddress(event.target.value)}
            />
            <Button
              className={Styles.button}
              text={t(`footer.newsletter.BUTTON_TEXT`)}
              linkUrl={""}
              onClick={handleOnSubmit}
            />
          </form>
        </div>
        {errorEmail && <h3 className={Styles.error}>{errorEmail}</h3>}
        {sucessMessage && <h3 className={Styles.success}>{sucessMessage}</h3>}
      </div>
    </div>
  );
}
export default NewsletterSignUp;
