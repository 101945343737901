import { formatDate } from "../../utils/DateHelpers";
import Styles from "./TextSection.module.css";

interface TextSectionProps {
  author: String;
  datePublished: string;
  text: string | undefined | null;
  title: string;
}

function TextSection({ author, datePublished, text, title }: TextSectionProps) {
  return (
    <div className={Styles.container}>
      <div className={Styles.titleContainer}>
        <h3 className={Styles.title}>{title}</h3>
        <p className={Styles.articleInfo}>
          {author} &#x2022; {formatDate(datePublished)}
        </p>
      </div>
      {text !== undefined &&
        text?.split("\\n").map((str, index) => {
          return (
            <p key={index} className={Styles.text}>
              {str.length < 75 ? <strong>{str}</strong> : str}
              <br />
            </p>
          );
        })}
    </div>
  );
}

export default TextSection;
