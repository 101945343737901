import { useTranslation } from "react-i18next";
import Styles from "./InstructionSection.module.css";

import { useState } from "react";

interface StepBlockProps {
  name: string;
  title: string;
  step: string;
  imageUrl: string;
}

function StepBlock(props: StepBlockProps) {
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const stepBlock = (
    <div className={Styles.step}>
      <img
        className={Styles.stepImage}
        src={require(`../../assets/images/${props.imageUrl}`)}
        alt={props.name}
      />
      <h4 className={Styles.stepTitle}>{props.title}</h4>{" "}
      <p className={Styles.stepText}>{props.step}</p>{" "}
    </div>
  );

  const stepBlockHover = (
    <div className={`${Styles.step} ${Styles.stepHover}`}>
      <img
        className={`${Styles.stepImage} ${Styles.stepImageHover}`}
        src={require(`../../assets/images/${props.imageUrl}`)}
        alt={props.name}
      />
      <h4 className={Styles.stepTitle}>{props.title}</h4>{" "}
      <p className={Styles.stepText}>{props.step}</p>{" "}
    </div>
  );

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={Styles.width}
    >
      {!hover ? stepBlock : stepBlockHover}
    </div>
  );
}

function InstructionSection() {
  const { t } = useTranslation();

  return (
    <div className={Styles.container}>
      <hr className={Styles.sectionDivider} />
      <h2 className={Styles.title}>
        {t(`applicationPage.instructionSection.TITLE`)}
      </h2>
      <div className={Styles.stepContainer}>
        <StepBlock
          name="Mobile device"
          title={t(
            `applicationPage.instructionSection.STEP_BLOCK_MOBILE_DEVICE_TITLE`
          )}
          step={t(
            `applicationPage.instructionSection.STEP_BLOCK_MOBILE_DEVICE_TEXT`
          )}
          imageUrl="home_intro_section_mobile.png"
        />
        <StepBlock
          name="Innerdisk"
          title={t(
            `applicationPage.instructionSection.STEP_BLOCK_INNERDISK_TITLE`
          )}
          step={t(
            `applicationPage.instructionSection.STEP_BLOCK_INNERDISK_TEXT`
          )}
          imageUrl="home_intro_section_disk.png"
        />
        <StepBlock
          name="Download"
          title={t(
            `applicationPage.instructionSection.STEP_BLOCK_DOWNLOAD_TITLE`
          )}
          step={t(
            `applicationPage.instructionSection.STEP_BLOCK_DOWNLOAD_TEXT`
          )}
          imageUrl="application_instruction_section_download.png"
        />
        <StepBlock
          name="Training"
          title={t(
            `applicationPage.instructionSection.STEP_BLOCK_TRAINING_TITLE`
          )}
          step={t(
            `applicationPage.instructionSection.STEP_BLOCK_TRAINING_TEXT`
          )}
          imageUrl="home_intro_section_training.png"
        />
      </div>
    </div>
  );
}

export default InstructionSection;
