import { useState } from "react";

import Styles from "./ResetPassword.module.css";
import { resetPasswordEmailAPI } from "../../../services/UserApi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmitHandler = () => {
    if (isEmailCorrect()) {
      resetPasswordEmailAPI(email);
    }
    navigate("/");
  };

  const isEmailCorrect = () => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    var isEmailCorrect: RegExpMatchArray | null = email.match(validRegex);
    return isEmailCorrect;
  };

  return (
    <div className={Styles.container}>
      <h2 className={Styles.title}>
        {t(`resetPasswordPage.RESET_PASSWORD_WELCOME`)}
      </h2>
      <form className={Styles.form}>
        <label className={Styles.info} htmlFor="">
          {t(`resetPasswordPage.RESET_PASSWORD_INSTRUCTION`)}
        </label>
        <input
          className={Styles.input}
          type="email"
          placeholder={t(`resetPasswordPage.EMAIL`)}
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* {errorMessage && (
          <label className={Styles.error}> {errorMessage} </label>
        )} */}
        <input
          className={Styles.button}
          type="button"
          name="submit"
          id="submit"
          value={t(`resetPasswordPage.SUBMIT`)}
          onClick={onSubmitHandler}
        />
      </form>
    </div>
  );
}

export default ResetPassword;
