import Styles from "./CartInfo.module.css";
import EmptyCartIcon from "../../assets/icons/empty-cart.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import Button from "../Button";
import closeIcon from "../../assets/icons/close.svg";
import { removeProductfromCart } from "../../features/cart/reducers/CartSlice";
import { removeProductFromCartAPI } from "../../services/CartAPI";
import { useTranslation } from "react-i18next";
import { ICartItem } from "../../models/cart/CartItem.model";
import CartItemProperties from "../CartItemProperties";
import { Currency } from "../../models/cart/Currency.model";

interface CartInfoProps {
  visibile: boolean;
}

function CartInfo(props: CartInfoProps) {
  const [isVisible, setIsVisible] = useState(props.visibile);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const cartItems: ICartItem[] = useSelector(
    (state: RootState) => state.cartState.userCart.cartItems
  );

  const userCart = useSelector((state: RootState) => state.cartState.userCart);

  const userCurrency = useSelector(
    (state: RootState) => state.currencyState.userCurrency
  );

  const userAccessToken = useSelector(
    (state: RootState) => state.userState.accessToken
  );

  const handleMouseOverUserInfo = () => {
    setIsVisible(true);
  };

  const handleMouseOutUserInfo = () => {
    setIsVisible(false);
  };

  const handleRemoveProductFromCart = (cartItemId: number) => {
    dispatch(removeProductfromCart(cartItemId));
    if (userAccessToken !== null) {
      removeProductFromCartAPI(userAccessToken, cartItemId);
    }
  };

  const emptyCart = (
    <div className={Styles.emptyCartContainer}>
      <img className={Styles.icon} src={EmptyCartIcon} alt="Empty Cart" />
      <p className={Styles.text}>{t(`cartInfo.EMPTY_CART`)}</p>
    </div>
  );

  const renderCartPrice = () => {
    return (
      <div className={Styles.priceContainer}>
        <h3 className={Styles.priceText}>{t(`cartInfo.CART_VALUE`)}</h3>
        <h3 className={Styles.priceText}>
          {userCart.discountedPrice === null
            ? userCurrency.name === Currency.PLN
              ? userCart.originalPrice
              : userCart.originalPriceEur
            : userCurrency.name === Currency.PLN
            ? userCart.discountedPrice
            : userCart.discountedPriceEur}{" "}
          {userCurrency.name}
        </h3>
      </div>
    );
  };

  const renderCartItems = (
    <div>
      {cartItems !== undefined &&
        cartItems !== null &&
        cartItems.map((item) => {
          return (
            <div className={Styles.productContainer} key={item.id}>
              <h4 className={`${Styles.text} ${Styles.quantity}`}>
                {item.quantity}x
              </h4>
              <CartItemProperties
                cartItem={item}
                productNameStyle={Styles.itemName}
                unitPriceStyle={Styles.itemUnitPrice}
                propertyStyle={Styles.itemPropertyyy}
                userCurrency={userCurrency}
              />
              <img
                className={Styles.closeImage}
                src={closeIcon}
                alt="Close"
                onClick={(e) => handleRemoveProductFromCart(item.id)}
              />
            </div>
          );
        })}
      <hr className={Styles.divider} />
      {renderCartPrice()}
      <Button
        text={t(`cartInfo.GO_TO_SUMMARY`)}
        className={Styles.button}
        linkUrl="/cart"
      />
    </div>
  );

  return (
    <div
      className={`${Styles.container} {${isVisible} ? ${Styles.dropdownExpand} : ${Styles.dropdownHide}}`}
      onMouseOver={handleMouseOverUserInfo}
      onMouseOut={handleMouseOutUserInfo}
    >
      {cartItems === undefined || cartItems === null || cartItems.length <= 0
        ? emptyCart
        : renderCartItems}
    </div>
  );
}

export default CartInfo;
