import { useDispatch, useSelector } from "react-redux";
import Styles from "./UserInfo.module.css";
import { RootState } from "../../app/Store";
import { logout } from "../../features/user/reducers/UserSlice";
import { clearCart } from "../../features/cart/reducers/CartSlice";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface UserInfoProps {
  visibile: boolean;
}

function UserInfo(props: UserInfoProps) {
  const userAuth = useSelector((state: RootState) => state.userState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
  };

  return (
    <div
      className={`${Styles.container} {${props.visibile} ? ${Styles.dropdownExpand} : ${Styles.dropdownHide}}`}
    >
      {userAuth.email !== null && userAuth.email.length > 0 ? (
        <div>
          <h3 className={Styles.title}>{t(`userInfo.HELLO`)}</h3>
          <Button
            className={`${Styles.button} ${Styles.marginButton}`}
            text={t(`userInfo.BUTTON_ORDER`)}
            linkUrl="/user-order"
          />
          <Button
            className={Styles.button}
            text={t(`userInfo.BUTTON_CHANGE_PASSWORD`)}
            linkUrl="/reset-password"
          />
          <hr className={Styles.divider} />
          <Button
            className={Styles.button}
            text={t(`userInfo.BUTTON_LOGOUT`)}
            linkUrl=""
            onClick={handleLogout}
          />
        </div>
      ) : (
        <div>
          <Button
            className={Styles.loginBtn}
            linkUrl="/login"
            text={t(`userInfo.BUTTON_LOGIN`)}
          />
          <p className={Styles.text}>{t(`userInfo.QUESTION`)}</p>
          <Link className={Styles.linkCreateAccount} to="/register-user">
            {t(`userInfo.JOIN_US`)}
          </Link>
        </div>
      )}
    </div>
  );
}

export default UserInfo;
