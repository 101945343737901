import Styles from "./ApplicationSection.module.css";

import Application from "../../assets/images/excerciseAtlas-application-section.png";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
function ApplicationSection() {
  const { t } = useTranslation();

  return (
    <div className={Styles.container}>
      <div className={Styles.overlay}>
        <hr className={Styles.sectionDivider} />
        <div className={Styles.contentContainer}>
          <img className={Styles.appImg} src={Application} alt="Application" />
          <div className={Styles.textContainer}>
            <h2 className={Styles.title}>
              {t(`applicationPage.featureSection.TITLE`)}
            </h2>
            <hr className={Styles.titleDivider} />
            <p className={Styles.desc}>
              {t(`applicationPage.featureSection.DESC`)}
            </p>
            <Button
              text={t(`applicationPage.featureSection.BUTTON_TEXT`)}
              className={Styles.button}
              linkUrl="/application"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationSection;
