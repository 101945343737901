import SocialMediaLink from "./SocialMediaLink";
import { socialMediaItems } from "../../constans/SocialMediaItems";

import Styles from "./SocialMedia.module.css";

import Logo from "../../assets/images/trainport_logo_white.png";
import { useTranslation } from "react-i18next";

function SocialMedia() {
  const { t } = useTranslation();
  return (
    <div className={Styles.container}>
      <img
        className={Styles.img}
        width="80%"
        height="auto"
        src={Logo}
        alt="Trainport logo"
      />
      <p className={Styles.desc}>{t(`footer.DESC`)}</p>
      <ul className={Styles.scoialMediaIconsList}>
        {socialMediaItems.map((item) => {
          return (
            <SocialMediaLink
              title={item.title}
              icon={item.icon}
              key={item.id}
              url={item.url}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default SocialMedia;
