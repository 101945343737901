/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import Dropdown from "./Dropdown";

import ArrowDown from "../../assets/icons/small-arrow-down.png";
import Styles from "./TextLink.module.css";
import { NavLink } from "react-router-dom";

import MinusIcon from "../../assets/icons/minus-solid.svg";
import PlusIcon from "../../assets/icons/plus-solid.svg";
import { useTranslation } from "react-i18next";

interface TextLinkProps {
  id: number;
  title: string;
  url: string;
  // eslint-disable-next-line react/require-default-props
  style?: React.CSSProperties;
  // eslint-disable-next-line react/require-default-props
  submenu?: { id: number; title: string; url: string }[];
  onClick?: () => any;
}

function TextLink({ title, url, style, submenu, onClick }: TextLinkProps) {
  const [dropdown, setDropdown] = useState(false);
  const { t } = useTranslation();

  const handleOnClick = () => {
    if (window.matchMedia("(max-width: 1380px)")) {
      setDropdown(!dropdown);
    }
  };

  const handleOnMouseEnter = () => {
    if (window.matchMedia("(min-width: 1380px)").matches === true) {
      setDropdown(!dropdown);
    }
  };

  const handleOnMouseLeave = () => {
    if (window.matchMedia("(min-width: 1380px)").matches === true) {
      setDropdown(!dropdown);
    }
  };

  return (
    <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      {(submenu !== undefined && submenu?.length) === 0 ? (
        <div onClick={onClick}>
          <NavLink
            to={url}
            className={(navData) =>
              navData.isActive ? Styles.textLinkActive : Styles.textLink
            }
            style={style}
          >
            {t(`navbar.${title}`)}
          </NavLink>
        </div>
      ) : (
        <div className={Styles.linkDropdown} onClick={handleOnClick}>
          <div className={Styles.linkElement}>
            <div>
              <NavLink
                to={url}
                className={(navData) =>
                  navData.isActive ? Styles.textLinkActive : Styles.textLink
                }
                style={style}
              >
                {t(`navbar.${title}`)}
                <img
                  className={Styles.arrowDown}
                  src={ArrowDown}
                  alt="Arrow Down"
                />
              </NavLink>
            </div>
            <img
              className={Styles.symbol}
              onClick={handleOnClick}
              src={dropdown ? MinusIcon : PlusIcon}
              alt={dropdown ? "Minus" : "Plus"}
            />
          </div>
          {dropdown && (
            <Dropdown visibile={dropdown} submenu={submenu} onClick={onClick} />
          )}
        </div>
      )}
    </div>
  );
}

export default TextLink;
