import Styles from "./HeroSection.module.css";

interface HeroSectionProps {
  image: string;
}
function HeroSection({ image }: HeroSectionProps) {
  return (
    <div className={Styles.container}>
      <img className={Styles.heroImage} src={image} alt="Article" />
    </div>
  );
}

export default HeroSection;
