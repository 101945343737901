import { useState } from "react";

import Styles from "./ProductBlock.module.css";
import { Link, createSearchParams } from "react-router-dom";
import Button from "./Button";
import { isMobile } from "../utils/ScreenHelper";
import { useTranslation } from "react-i18next";

interface ProductBlockProps {
  id: string;
  productName: string;
  currentPrice: string | undefined;
  previousPrice?: string | null;
  thumbnail: string;
  buttonText: string;
  currency: string;
}

function ProductBlock(props: ProductBlockProps) {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const naviagateProduct = {
    pathname: "/product",
    search: createSearchParams({
      id: props.id,
    }).toString(),
  };

  return (
    <div
      className={Styles.container}
      onMouseEnter={isMobile() ? () => {} : handleMouseEnter}
      onMouseLeave={isMobile() ? () => {} : handleMouseLeave}
    >
      <Link to={naviagateProduct}>
        <img
          className={Styles.image}
          src={props.thumbnail}
          width="100%"
          height="auto"
          alt={t(`products.${props.productName}.NAME`)}
        />
      </Link>
      <div className={Styles.infoContainer}>
        {!hover && (
          <h4 className={Styles.productName}>
            {" "}
            {t(`products.${props.productName}.NAME`)}
          </h4>
        )}
        {hover && (
          <h4 className={Styles.productNameHover}>
            {t(`products.${props.productName}.NAME`)}
          </h4>
        )}
        {!hover && (
          <h3 className={Styles.currentPrice}>
            {props.currentPrice} {props.currency}
          </h3>
        )}
        {hover && (
          <Button
            text={props.buttonText}
            className={Styles.button}
            linkUrl={`/product?id=${props.id}`}
          />
        )}
      </div>
    </div>
  );
}
export default ProductBlock;
