import Button from "../../components/Button";

import Styles from "./BenefitsSection.module.css";

import Innerdisk from "../../assets/images/home_benefits_section_innerdisk.png";
import useElementOnScreen from "../../hooks/UseElementOnScreen";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function BenefitsSection() {
  const [containerRef, isVisible] = useElementOnScreen();
  const { t } = useTranslation();

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeftSectionDivider}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimation}`);
        });
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeUp}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeUpAnimation}`);
        });
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeft}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimation}`);
        });
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeftDelay}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimationDelay}`);
        });
    }
  }, [isVisible, containerRef]);
  return (
    <section className={Styles.container} ref={containerRef}>
      <div className={Styles.fadeInLeftSectionDivider}>
        <hr className={Styles.sectionDivider} />
      </div>
      <div className={Styles.overlay} />
      <div className={Styles.content}>
        <div className={Styles.firstElement}>
          <div className={Styles.fadeInLeft}>
            <h3 className={Styles.question}>
              {t(`homePage.benefitSection.SUBTITLE`)}
            </h3>
          </div>
          <div className={Styles.fadeInLeftDelay}>
            <h2 className={Styles.slogan}>
              {t(`homePage.benefitSection.title.PART_1`)}
              <br className={Styles.break} />
              {t(`homePage.benefitSection.title.PART_2`)}
            </h2>
          </div>
          <div className={Styles.fadeInLeft}>
            <hr className={Styles.titlesDivider} />
          </div>
          <div className={Styles.fadeInLeftDelay}>
            <p className={Styles.desc}>
              <strong>{t(`homePage.benefitSection.DESC`)}</strong>
            </p>
          </div>
          <div className={Styles.fadeInLeft}>
            <hr className={Styles.endParagprahDivider} />
          </div>
          <div
            className={`${Styles.buttonContainer} ${Styles.fadeInLeftDelay}`}
          >
            <Button
              text={t(`homePage.benefitSection.BUTTON_TEXT`)}
              linkUrl="/inercise"
            />
          </div>
        </div>
        <div className={Styles.secondElement}>
          <div className={Styles.fadeUp}>
            <img className={Styles.innerdisk} src={Innerdisk} alt="Innerdisk" />
          </div>
        </div>
      </div>
    </section>
  );
}
export default BenefitsSection;
