import React, { useEffect, useState } from "react";

import ArticleBlock from "./ArticleBlock";
import { Article } from "../../models/blog/Article.model";
import "../../services/ArticleAPI";

import Styles from "./BlogSection.module.css";
import useElementOnScreen from "../../hooks/UseElementOnScreen";
import { getAllPublishedArticlesByDate } from "../../services/ArticleAPI";
import { addArticalesTranslations } from "../../utils/BlogHelpers";

function BlogSection() {
  const [articles, setArticles] = useState<Article[]>([]);
  const [containerRef, isVisible] = useElementOnScreen();

  useEffect(() => {
    getAllPublishedArticlesByDate(true).then((item) => {
      setArticles(item);
    });
  }, []);

  useEffect(() => {
    addArticalesTranslations(articles);
  }, [articles]);

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.firstElement}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimationDelay}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.secondElement}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeOpacityAnimation}`);
        });
    }
  }, [isVisible, containerRef]);

  return (
    <section className={Styles.container}>
      <hr className={Styles.sectionDivider} />
      <div className={Styles.content} ref={containerRef}>
        <div className={Styles.firstElement}>
          <h2 className={Styles.blog}>Blog</h2>
          <hr className={Styles.titleDivider} />
        </div>
        <div className={Styles.secondElement}>
          {articles.slice(0, 3).map((item) => {
            return <ArticleBlock key={item.id} article={item} />;
          })}
        </div>
      </div>
    </section>
  );
}
export default BlogSection;
