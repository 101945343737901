import { useEffect } from "react";
import First from "../assets/images/blog_crossfading_section_first.jpg";
import Second from "../assets/images/marta_leg_push_in_bear_postion_color.jpg";

import Styles from "./CrossFadingSection.module.css";
import useElementOnScreen from "../hooks/UseElementOnScreen";

interface CrossFadingProps {
  subtitle: String;
  title: String;
}

function CrossFading({ subtitle, title }: CrossFadingProps) {
  const [containerRef, isVisible] = useElementOnScreen();

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeft}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimation}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeftDelay}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimationDelay}`);
        });
    }
  }, [isVisible, containerRef]);

  return (
    <div className={Styles.container} ref={containerRef}>
      <img
        className={`${Styles.crossFadingImg} ${Styles.firstImageDelay}`}
        src={First}
        alt="Rowing"
      />
      <img
        className={`${Styles.crossFadingImg} ${Styles.secondImageDelay}`}
        src={Second}
        alt="Leg press"
      />
      <div className={Styles.fadeInLeftDelay}>
        <hr className={Styles.firstDivider} />
      </div>
      <div className={`${Styles.titleContainer} ${Styles.fadeInLeft}`}>
        <h2 className={Styles.motto}>{subtitle}</h2>
        <h1 className={Styles.pageTitle}>{title}</h1>
      </div>
      <div className={Styles.fadeInLeftDelay}>
        <hr className={Styles.secondDivider} />
      </div>
    </div>
  );
}

export default CrossFading;
