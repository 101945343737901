import { useTranslation } from "react-i18next";
import Styles from "./QuickLinksLink.module.css";
import { Link } from "react-router-dom";

interface QuickLink {
  title: string;
  url: string;
}

function QuickLinksLink({ title, url }: QuickLink) {
  const { t } = useTranslation();
  return (
    <Link className={Styles.link} to={url}>
      <li className={Styles.listItem}>{t(`footer.quickLinks.${title}`)}</li>
    </Link>
  );
}

export default QuickLinksLink;
