import { useEffect, useState } from "react";
import Styles from "./SelectPaymentMethod.module.css";
import { PaymentMethod } from "../../models/tpay/PaymentMethod.model";
import { getPaymentMethodsAPI } from "../../services/TPayAPI";
import { useTranslation } from "react-i18next";

interface SelectPaymentMethodProps {
  selectedPaymentMethod: (arg: string) => void;
}

function SelectPaymentMethod(props: SelectPaymentMethodProps) {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod>({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const { t } = useTranslation();

  // eslint-disable-next-line
  useEffect(() => {
    getPaymentMethodsAPI().then((data) => {
      setPaymentMethods(data);
    });
  }, []);

  const onCheckedPaymentMethod = (id: string) => {
    props.selectedPaymentMethod(id);
    setSelectedPaymentMethod(id);
  };

  const paymentMethodBlock = (name: string, img: string, index: string) => {
    return (
      <div
        className={`${Styles.paymentMethod} ${
          selectedPaymentMethod === index ? Styles.paymentMethodChecked : ""
        }`}
        key={index}
        id={index}
        onClick={(e) => onCheckedPaymentMethod(e.currentTarget.id)}
      >
        <img className={Styles.paymentImage} src={img} alt="" />
        <h4 className={Styles.paymentMethodText}>{name}</h4>
      </div>
    );
  };

  return (
    <div className={Styles.container}>
      <h3 className={Styles.subtitle}>
        {t(`deliveryAndPaymentAndPage.PAYMENT_FORM`)}
      </h3>
      <div className={Styles.methodsContainer}>
        {Object.keys(paymentMethods)?.map((key) => {
          return paymentMethodBlock(
            paymentMethods[key].name,
            paymentMethods[key].img,
            paymentMethods[key].id
          );
        })}
      </div>
    </div>
  );
}

export default SelectPaymentMethod;
