import { useTranslation } from "react-i18next";
import Styles from "./UserInformationSection.module.css";
import { Link } from "react-router-dom";

interface UserInformationSectionProps {
  title: string;
  info: string;
  link: string;
}

function UserInformationSection(props: UserInformationSectionProps) {
  const { t } = useTranslation();

  return (
    <div className={Styles.container}>
      <h2 className={Styles.title}>{props.title}</h2>
      <p className={Styles.info}>{props.info}</p>
      <Link className={Styles.button} to={props.link}>
        {t(`userInformationSection.GO_TO_HOMEPAGE`)}
      </Link>
    </div>
  );
}
export default UserInformationSection;
