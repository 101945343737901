import Styles from "./Blog.module.css";

import CrossFadingSection from "../../components/CrossFadingSection";
import FeatureSection from "./FeatureSection";
import { motion } from "framer-motion";

function Blog() {
  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      <CrossFadingSection title="Blog" subtitle="Let's TEAM together!" />
      <FeatureSection />
    </motion.div>
  );
}

export default Blog;
