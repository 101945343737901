import { motion } from "framer-motion";
import Styles from "./Partners.module.css";
import { useTranslation } from "react-i18next";
import PartnerDesc from "./PartnerDesc";

function Partners() {
  const { t } = useTranslation();
  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <div className={Styles.descContainer}>
        <h2 className={Styles.title}>{t(`partners.TITLE`)}</h2>
        <p className={Styles.desc}>{t(`partners.DESC`)}</p>
        <hr className={Styles.sectionDivider} />
      </div>
      <PartnerDesc />
    </motion.div>
  );
}

export default Partners;
