import { useEffect, useState } from "react";
import CurrencyExchangeIcon from "../../assets/icons/currency-exchange.svg";

import Styles from "./CurrencySwitchButton.module.css";
import { Currency, ICurrency } from "../../models/cart/Currency.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import { setUserCurrency } from "../../features/cart/reducers/CurrencySlice";
import { getCurrenciesAPI } from "../../services/CurrencyAPI";

function CurrencySwitchButton() {
  const [isHovered, setIsHovered] = useState(false);
  const [currencies, setCurrencies] = useState<ICurrency[]>();

  const dispatch = useDispatch();

  const userCurrency = useSelector(
    (state: RootState) => state.currencyState.userCurrency
  );

  useEffect(() => {
    getCurrenciesAPI().then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setCurrencies(data);
        });
      }
    });
  }, []);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const onSwitchCurrency = () => {
    if (currencies === undefined) return;
    switch (userCurrency.name) {
      case Currency.EUR:
        const userNewCurrency: ICurrency[] = currencies.filter(
          (currency) => currency.name === Currency.PLN
        );
        dispatch(setUserCurrency(userNewCurrency[0]));
        break;
      case Currency.PLN:
        const userNewCurrencyEUR: ICurrency[] = currencies.filter(
          (currency) => currency.name === Currency.EUR
        );
        dispatch(setUserCurrency(userNewCurrencyEUR[0]));
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={Styles.container}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={Styles.currencyContainer}>
        <img
          className={Styles.currencyIcon}
          src={CurrencyExchangeIcon}
          alt="Currency Exchange"
        />
        <p className={Styles.currencyInfo}>{userCurrency?.name}</p>
      </div>
      {isHovered && (
        <div className={Styles.curencciesDropdownContainer}>
          <div className={Styles.currencies}>
            {Object.keys(Currency).map((currency) => {
              return (
                <p
                  key={currency}
                  className={Styles.currencyLabel}
                  onClick={onSwitchCurrency}
                >
                  {currency}
                </p>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default CurrencySwitchButton;
