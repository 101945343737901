import React from "react";
import ExcerciseBlock from "./ExcerciseBlock";

import Styles from "./ExcerciseSection.module.css";

interface ExcerciseSectionProps {
  id: number;
  title: string;
  musclesDesc: string;
  steps: string[];
  videoName: string;
}

function ExcerciseSection(props: ExcerciseSectionProps) {
  return (
    <React.Fragment>
      {props.id % 2 === 0 || window.matchMedia("(max-width: 767px)").matches ? (
        <section
          className={Styles.container}
          style={{
            backgroundImage: `url(${require(`../../assets/images/excerciseAtlas-bg-2.jpg`)})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: `cover`,
            backgroundAttachment: `fixed`,
          }}
        >
          <div
            className={Styles.overlay}
            style={{
              backgroundImage: `linear-gradient(90deg, #ffffff 50%, #ffffff00 100%)`,
            }}
          >
            <div className={Styles.contentContainer}>
              <video className={Styles.video} autoPlay loop muted playsInline>
                <source
                  src={require(`../../assets/videos/${props.videoName}`)}
                  type="video/mp4"
                />
              </video>
              <ExcerciseBlock
                key={props.id}
                title={props.title}
                musclesDesc={props.musclesDesc}
                steps={props.steps}
              />
            </div>
          </div>
        </section>
      ) : (
        <section
          className={Styles.container}
          style={{
            backgroundImage: `url(${require(`../../assets/images/excerciseAtlas-bg-1.jpg`)})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: `cover`,
            backgroundAttachment: `fixed`,
          }}
        >
          <div
            className={Styles.overlay}
            style={{
              backgroundImage: `linear-gradient(-90deg, #ffffff 50%, #ffffff00 100%)`,
            }}
          >
            <div className={Styles.contentContainer}>
              <ExcerciseBlock
                key={props.id}
                title={props.title}
                musclesDesc={props.musclesDesc}
                steps={props.steps}
              />
              <video className={Styles.video} loop autoPlay muted playsInline>
                <source
                  src={require(`../../assets/videos/${props.videoName}`)}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default ExcerciseSection;
