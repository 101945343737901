import { useEffect, useState } from "react";
import Button from "../../components/Button";
import ProductBlock from "../../components/ProductBlock";

import Styles from "./ProductsSection.module.css";
import { getAllProducts, getProductType } from "../../services/ProductAPI";
import { Product } from "../../models/product/Product.model";
import { ProductType } from "../../models/product/ProductType.model";
import { useTranslation } from "react-i18next";
import Slider from "@mui/material/Slider";
import useElementOnScreen from "../../hooks/UseElementOnScreen";
import { useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import {
  getProductPreviousPrice,
  getProductPrice,
} from "../../utils/ProductHelpers";
import { Currency } from "../../models/cart/Currency.model";

const thumbnailAPI: string = `${process.env.REACT_APP_TRAINPORT_BACKEND_API_URL}/thumbnail/`;

function valuetext(value: number) {
  return `${value} PLN`;
}

const productOrderByProductType = [
  "INERCISE",
  "COURSE",
  "HANDLE",
  "INERCISE_ACCESSORY",
];

function ProductsSection() {
  const { t } = useTranslation();
  const [containerRef, isVisible] = useElementOnScreen();
  const [products, setProducts] = useState<Product[]>([]);
  const [productsAvailable, setproductsAvailable] = useState<Product[]>([]);
  const [productType, setProductType] = useState<ProductType[]>([]);
  const [value, setValue] = useState<number[]>([]);
  const [productsAvailableByType, setproductsAvailableByType] = useState<
    Product[]
  >([]);
  const [productsAvailableByPrice, setproductsAvailableByPrice] = useState<
    Product[]
  >([]);

  const [productTypeState, setProductTypeState] = useState<
    { id: number; isPressed: boolean }[]
  >([]);

  const userCurrency = useSelector(
    (state: RootState) => state.currencyState.userCurrency
  );

  const marks = [
    {
      value: value[0],
      label: `${value[0]} ${userCurrency.name}`,
    },
    {
      value: value[1],
      label: `${value[1]} ${userCurrency.name}`,
    },
  ];

  useEffect(() => {
    getAllProducts().then((item) => {
      const productsSortedByProductType = [...item].sort((a, b) => {
        var A = a.productType.name,
          B = b.productType.name;

        if (
          productOrderByProductType.indexOf(A) >
          productOrderByProductType.indexOf(B)
        ) {
          return 1;
        } else {
          return -1;
        }
      });
      setProducts(productsSortedByProductType);
      setproductsAvailable(productsSortedByProductType);
      setproductsAvailableByType(productsSortedByProductType);
      setproductsAvailableByPrice(productsSortedByProductType);
    });

    getProductType().then((item) => {
      setProductType(item);
    });
  }, []);

  useEffect(() => {
    const minPrice = Math.min(
      ...products.map((item) =>
        Number(
          userCurrency.name === Currency.PLN
            ? item.currentPrice
            : item.currentPriceEur
        )
      )
    );
    const maxPrice = Math.max(
      ...products.map((item) =>
        Number(
          userCurrency.name === Currency.PLN
            ? item.currentPrice
            : item.currentPriceEur
        )
      )
    );
    setValue([minPrice, maxPrice]);
    // eslint-disable-next-line
  }, [userCurrency]);

  useEffect(() => {
    const minPrice = Math.min(
      ...products.map((item) =>
        Number(
          userCurrency.name === Currency.PLN
            ? item.currentPrice
            : item.currentPriceEur
        )
      )
    );
    const maxPrice = Math.max(
      ...products.map((item) =>
        Number(
          userCurrency.name === Currency.PLN
            ? item.currentPrice
            : item.currentPriceEur
        )
      )
    );
    setValue([minPrice, maxPrice]);
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    var currentAvailableProduct: Product[] = [];
    productsAvailableByType.map((item) => {
      productsAvailableByPrice.map((product) => {
        if (item.id === product.id) {
          currentAvailableProduct.push(product);
        }
        return currentAvailableProduct;
      });
      return currentAvailableProduct;
    });
    setproductsAvailable(currentAvailableProduct);
  }, [productsAvailableByType, productsAvailableByPrice]);

  useEffect(() => {
    const buttonTypeState: { id: number; isPressed: boolean }[] = [];
    productType.map((item) => {
      buttonTypeState.push({ id: item.id, isPressed: false });
      return buttonTypeState;
    });

    setProductTypeState(buttonTypeState);
  }, [productType]);

  useEffect(() => {}, [productTypeState]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    var productsAvaiable: Product[] = [];
    var newPriceFilteredValues: number[] = newValue as number[];
    products.map((item) => {
      if (
        Number(
          userCurrency.name === Currency.PLN
            ? item.currentPrice
            : item.currentPriceEur
        ) >= newPriceFilteredValues[0] &&
        Number(
          userCurrency.name === Currency.PLN
            ? item.currentPrice
            : item.currentPriceEur
        ) <= newPriceFilteredValues[1]
      ) {
        productsAvaiable.push(item);
      }
      return productsAvaiable;
    });
    setproductsAvailableByPrice(productsAvaiable);
  };

  const handleTypeFilterChange = (key: number) => {
    var currentState: { id: number; isPressed: boolean }[] = productTypeState;
    currentState.map((item) => {
      if (item.id === key) {
        item.isPressed = !item.isPressed;
      }
      return currentState;
    });
    setProductTypeState(currentState);
    var pressedButtons: { id: number; isPressed: boolean }[] = [];
    pressedButtons = productTypeState.filter((item) => item.isPressed === true);
    var currentAvailableProduct: Product[] = [];
    if (pressedButtons.length === 0) {
      setproductsAvailableByType(products);
    } else {
      pressedButtons.map((item) => {
        products.map((product) => {
          if (item.id === Number(product.productType.id)) {
            currentAvailableProduct.push(product);
          }
          return products;
        });
        return pressedButtons;
      });
      setproductsAvailableByType(currentAvailableProduct);
    }
  };

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeIn}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInAnimation}`);
        });
    }
  }, [isVisible, containerRef]);

  return (
    <section className={Styles.container}>
      <hr className={Styles.sectionDivider} />
      <div className={Styles.buttonContainer}>
        {productType?.map((item, key) => {
          return (
            <div onClick={() => handleTypeFilterChange(key + 1)} key={item.id}>
              <Button
                text={t(`shopPage.${item.name}`)}
                isCheckable={true}
                linkUrl=""
              />
            </div>
          );
        })}
      </div>
      <hr className={Styles.buttonsDivider} />
      <div className={Styles.shoppingContainer} ref={containerRef}>
        <div className={Styles.filterContainer}>
          <h3 className={Styles.title}>{t(`shopPage.FILTER_TEXT`)}</h3>
          <hr className={Styles.filterDivider} />
          <Slider
            sx={{
              color: "#77c8d2",
              fontSize: "20px",
              "& .css-nnid7-MuiSlider-valueLabel": {
                fontSize: "1.2rem",
                fontFamily: "Montserrat",
                backgroundColor: "#FFF",
                color: "#000",
                fontWeight: "700",
              },
              "& .css-1eoe787-MuiSlider-markLabel": {
                fontSize: "1.2rem",
                fontFamily: "Montserrat",
                fontWeight: "800",
              },
              "& .css-3besu": {
                fontSize: "1.2rem",
                fontFamily: "Montserrat",
                backgroundColor: "#FFF",
                color: "#000",
                fontWeight: "700",
              },
              "& .css-sxo8tj": {
                fontSize: "1.2rem",
                fontFamily: "Montserrat",
                fontWeight: "800",
              },
            }}
            value={value}
            onChange={handleSliderChange}
            getAriaValueText={valuetext}
            min={Math.min(
              ...products.map((item) =>
                Number(
                  userCurrency.name === Currency.PLN
                    ? item.currentPrice
                    : item.currentPriceEur
                )
              )
            )}
            max={Math.max(
              ...products.map((item) =>
                Number(
                  userCurrency.name === Currency.PLN
                    ? item.currentPrice
                    : item.currentPriceEur
                )
              )
            )}
            marks={marks}
            valueLabelDisplay="on"
          />
        </div>
        <div className={`${Styles.productBlock} ${Styles.fadeIn}`}>
          {productsAvailable.length > 0 ? (
            productsAvailable.map((item) => {
              return (
                <ProductBlock
                  key={item.id}
                  id={item.id.toString()}
                  productName={item.name}
                  currentPrice={getProductPrice(item, userCurrency.name)}
                  previousPrice={getProductPreviousPrice(
                    item,
                    userCurrency.name
                  )}
                  thumbnail={thumbnailAPI + item.thumbnail.name}
                  buttonText={`${t(`shopPage.BUTTON_TEXT`)} ${t(
                    `products.${item.name}.NAME`
                  )}`}
                  currency={userCurrency.name}
                />
              );
            })
          ) : (
            <h3 className={Styles.noProductsText}>
              {t(`shopPage.NO_PRODUCTS_TO_DISPLAY`)}
            </h3>
          )}
        </div>
      </div>
    </section>
  );
}

export default ProductsSection;
