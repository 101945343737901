export interface IInvoiceAddress {
  id: undefined;
  companyName: string;
  companyIdentificationNumber: string;
  country: string;
  state: string;
  city: string;
  addressLine_1: string;
  addressLine_2: string | undefined;
  postcode: string;
  phoneNumber: string;
}

export const initialInvoiceAddress = {
  id: undefined,
  companyName: "",
  companyIdentificationNumber: "",
  country: "",
  state: "",
  city: "",
  addressLine_1: "",
  addressLine_2: "",
  postcode: "",
  phoneNumber: "",
};
