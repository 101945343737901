import { useEffect, useState } from "react";
import useElementOnScreen from "../../hooks/UseElementOnScreen";
import Styles from "./Contact.module.css";
import Facebook from "../../assets/icons/facebook-circle-white.svg";
import Instagram from "../../assets/icons/instagram-circle-white.svg";
import { postContact } from "../../services/ContactAPI";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

function Contact() {
  const [containerRef, isVisible] = useElementOnScreen();
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [sucessMessage, setSuccessMessage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (isVisible && containerRef.current) {
      containerRef.current
        .querySelectorAll(`div.${Styles.fadeInLeftDelay}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeInLeftAnimationDelay}`);
        });

      containerRef.current
        .querySelectorAll(`div.${Styles.fadeUp}`)
        .forEach((el) => {
          el.classList.add(`${Styles.fadeUpAnimation}`);
        });
    }
  }, [isVisible, containerRef]);

  const handleOnSubmit = (event: React.SyntheticEvent) => {
    setErrorMessage("");
    setSuccessMessage("");
    let isEmailValid: boolean = false;
    let isMessageValid: boolean = false;
    event.preventDefault();
    if (!isValidEmail(emailAddress) || undefined) {
      setErrorEmail("Podany adres email jest niepoprawny");
      setSuccessMessage("");
    } else {
      isEmailValid = true;
      setErrorEmail("");
    }
    if (message === "" || undefined) {
      setErrorMessage("Nie można wysłać pustej wiadomości");
      setSuccessMessage("");
    } else {
      isMessageValid = true;
      setErrorMessage("");
    }

    if (isEmailValid === true && isMessageValid === true) {
      const response = postContact(emailAddress, message);
      response.then((item) => {
        if (item.ok) {
          setSuccessMessage("Wiadomość została wysłana");
        }
      });
    }
  };

  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <div className={Styles.overlay}></div>
      <div className={Styles.content} ref={containerRef}>
        <div className={Styles.fadeInLeftDelay}>
          <h2 className={Styles.title}>{t(`contactPage.TITLE`)}</h2>
          <hr className={Styles.titleDivider} />
          <div className={Styles.socialIconsContainer}>
            <a
              className={Styles.socialMediaLink}
              href="https://www.facebook.com/trainport.team"
            >
              <img className={Styles.smIcon} src={Facebook} alt="Facebook" />
            </a>
            <a
              className={Styles.socialMediaLink}
              href="https://www.instagram.com/trainport_team/"
            >
              <img className={Styles.smIcon} src={Instagram} alt="Instagram" />
            </a>
            <hr className={Styles.socialMediaDivider}></hr>
          </div>
          <hr className={Styles.socialMediaIconsDivider} />
        </div>
        <div className={Styles.fadeUp}>
          <form
            className={Styles.commentForm}
            onSubmit={handleOnSubmit}
            action=""
          >
            <textarea
              className={`${Styles.textForm} ${Styles.email}`}
              placeholder={t(`contactPage.SUBJECT_PLACEHOLDER`)}
              name="comment"
              onChange={(event) => setEmailAddress(event.target.value)}
            />
            {errorEmail && <h3 className={Styles.error}>{errorEmail}</h3>}
            <textarea
              className={`${Styles.textForm} ${Styles.message}`}
              placeholder={t(`contactPage.MESSAGE_PLACEHOLDER`)}
              name="comment"
              onChange={(event) => setMessage(event.target.value)}
            />
            {errorMessage && <h3 className={Styles.error}>{errorMessage}</h3>}
            <div className={Styles.submitContainer}>
              {sucessMessage && (
                <h3 className={Styles.success}>{sucessMessage}</h3>
              )}
              <input
                className={Styles.submitBtn}
                type="submit"
                value={t(`contactPage.BUTTON_TEXT`)}
              />
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
}

export default Contact;
